import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes';
import { AuthContextProvider } from './Context/AuthContext';
import { CartContextProvider } from './Context/CartContext';
import { CityContextProvider } from './Context/CityContext';
import { UserContextProvider } from './Context/UserContext';
import { AgeVerification } from "./components/AgeVerification";
// import { InfoPopUp } from "./components/InfoPopUp";
import { WhatsAppButton } from "./components/WhatsAppButton";


function App() {
  const [visitCount, setVisitCount] = useState(0);
  const location = window.location.pathname; // Access current URL

  function countVisits() {
    let visitCount = localStorage.getItem('visitCount');
    visitCount = visitCount ? parseInt(visitCount, 10) + 1 : 1;
    localStorage.setItem('visitCount', visitCount);
    return visitCount;
  }

  useEffect(() => {
    const currentURL = window.location.pathname;
    if (currentURL === '/produtos' || currentURL === '/') {
      const count = countVisits();
      setVisitCount(count);
    }
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <AuthContextProvider>
        <UserContextProvider>
          <CityContextProvider>
            <CartContextProvider>
              {location !== '/link_boozen' && <AgeVerification />}
              {location !== '/link_boozen' && <WhatsAppButton />}
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </CartContextProvider>
          </CityContextProvider>
        </UserContextProvider>
      </AuthContextProvider>
    </ChakraProvider>
  );
}

export default App;
