import {
  Box,
  Text,
  Button,
  useDisclosure,
  Divider,
  Avatar,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  SimpleGrid,
  Heading,
  useMediaQuery
} from "@chakra-ui/react";
import { useEffect, useState, useReducer } from "react";
import { useParams } from "react-router-dom";
import { Footer } from "../../../components/Footer";
import { api } from "../../../services/api";
import { theme } from "../../../styles/theme";
import { useAuth } from "../../../Hooks/useAuth";
import Navbar from "../../../components/Navbar";
import { Form } from '../Form';
import swal  from 'sweetalert';
import { FaPen, FaAngleUp, FaAngleDown, FaClock, FaCheck, FaTruck, FaCreditCard, FaMoneyBill, FaTrashAlt, FaTimes, FaCcVisa, FaCcMastercard  } from "react-icons/fa";
import { calculateDeliveryPeriod } from "../../../services/deliveryPeriodCalculation";

export function Profile() {
  const { user } = useAuth();
  const [profile, setProfile] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      full_name: "",
      address_street: "",
      address_number: "",
      address_complement: "",
      address_neighborhood: "",
      address_postal_code: "",
      city_id: "",
      state_id: "",
      phone_number: "",
    }
  );
  const { id } = useParams()
  const [orders, setOrders] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [userCredits, setUserCredits] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [mapedCitiesForState, setMapedCitiesForState] = useState([]);
  const [userPhoto, setUserPhoto] = useState(null);
  const [creditHistorySection, setCreditHistorySection] = useState(false);
  const [userDocument, setUserDocument] = useState("");
  const [intervalId, setIntervalId] = useState(null);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [savedCards, setSavedCards] = useState([]);


  const currentDate = new Date();

  const isMobile = useMediaQuery("(max-width: 767px)")[0]; // Adjust the breakpoint as needed

  const gridTemplateColumns = isMobile
  ? "repeat(1, 1fr)"
  : "repeat(3, 1fr)";

  const handleLocality = (e) => {
    setMapedCitiesForState(cities.filter(element => element.state_id === Number(e.target.value)))
  }


  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfile({ [name]: value });

    if (name === "photo") {
      const file = e.target.files[0];
      setProfile((prevState) => ({ ...prevState, photo: file }));
    } else {
      setProfile((prevState) => ({ ...prevState, [name]: value }));
    }
  }

  async function fetchUser() {
    const response = await api.get(`/api/v1/users/${id}`)
    setProfile(response.data.user)
    setUserDocument(response.data.user.document_number)
    setUserPhoneNumber(response.data.user.phone_number)

    setStates(response.data.states)
    setCities(response.data.cities)
    setUserCredits(response.data.credits)
    setOrders(response.data.order_total_info)
  }

  const handlePayment = (e, paymentId) => {
    window.location = `/pagamentos/${paymentId}`
  }


  // useEffect(() => {
  //   // Fetch saved cards
  //   api.get('/api/v1/payments/saved_cards').then((response) => {
  //     setSavedCards(response.data.saved_cards);
  //   }).catch(() => {
  //     setSavedCards([]);
  //   });
  // }, []);

  useEffect(() => {
    fetchUser();
    const urlParams = new URLSearchParams(window.location.search);
    const missingData = urlParams.get("missing_data");
    if (missingData === "true") {
      swal({
        title: "Quase lá!",
        text: "Preencha seu Nome, Documento e Celular para efetuar a compra",
        icon: "info",
      });
    }
  }, [])

  useEffect(() => {
    fetchUser();
    const urlParams = new URLSearchParams(window.location.search);
    const paymentSuccess = urlParams.get("payment_success");

    if (paymentSuccess === "true") {
      swal({
        title: "Ótimo",
        text: "Seu pagamento foi confirmado, você ja pode ver os detalhes do seu pedido no histórico.",
        icon: "success",
      });
    }

  }, [])



  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUserPhoto(file);
  };

  const toggleCreditsHistory = (e) => {
    e.preventDefault()
    setCreditHistorySection(!creditHistorySection)
  }

  const handleDocumentDisplay = (number) => {
    let cleanedString = number;
    if (cleanedString.length === 11) {
      var formattedNumber = cleanedString.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (cleanedString.length === 14) {
      var formattedNumber = cleanedString.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    } else if (cleanedString.length === 8) {
      var formattedNumber = cleanedString.replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
    } else {
      var formattedNumber = "";
    }
    return formattedNumber
  }

  function formatPhoneNumber(phoneNumber) {
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.length === 11) {
      return cleaned.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
    } else {
      return phoneNumber;
    }
  }

  const handleEditUser = async (e) => {
    e.preventDefault();
    let cleanedDocumentNumber =  userDocument.replace(/[.-\s]/g, '');
    let cleanedPhoneNumber =  userPhoneNumber.replace(/\D/g, '');
    const formData = new FormData();
    formData.append("user[full_name]", profile.full_name);
    formData.append("user[document_number]", cleanedDocumentNumber);
    formData.append("user[phone_number]", cleanedPhoneNumber);
    formData.append("user[address_street]", profile.address_street);
    formData.append("user[address_number]", profile.address_number);
    formData.append("user[address_complement]", profile.address_complement);
    formData.append("user[address_neighborhood]", profile.address_neighborhood);
    formData.append("user[address_postal_code]", profile.address_postal_code);
    if (profile.city_id !== null) {
      formData.append("user[city_id]", profile.city_id);
    }
    if (profile.state_id !== null) {
      formData.append("user[state_id]", profile.state_id);
    }
    if (userPhoto) {
      formData.append("user[photo]", userPhoto);
    }

    const response = await api.put(`/api/v1/users/${id}`, formData, {
      headers: {
        'Content-Type': "multipart/form-data",
      },
    });
    if (response.data.message === "Usuário atualizado com sucesso") {
      swal({
        title: "Ohhh Yeahhh",
        text: "Dados atualizados com sucesso!",
        icon: "success",
      });
      onClose()
      window.location.href = `/carrinho`;
    } else {
      swal({
        title: "Ohhh Noo",
        text: "Algo deu errado!",
        icon: "error",
      });
    }
    localStorage.setItem("userPhoto", response.data.user_photo)
  };

  const handleRemovePaymentMethod = async (cardId) => {
    try {
      const response = await api.delete(`/api/v1/payments/remove_saved_card`, {
        data: { card_id: cardId },
      });

      if (response.data.message === "Card removed successfully") {
        // Update UI to reflect removal
        swal({
          title: "Card Removed",
          text: "Your card was removed successfully.",
          icon: "success",
        });
      } else {
        swal({
          title: "Error",
          text: "Failed to remove card.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error('Error:', error);
      swal({
        title: "Error",
        text: "An unexpected error occurred.",
        icon: "error",
      });
    }
  };

  function formatDateDifference(createdDate) {
    const createdDateObject = new Date(createdDate);
    const currentDateObject = new Date();
    const monthNames = [
      "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
      "Jul", "Ago", "Set", "Out", "Nov", "Dez"
    ];
    const monthCreated = monthNames[createdDateObject.getUTCMonth()];
    const yearCreated = createdDateObject.getUTCFullYear().toString().substring(2);
    const monthCurrent = monthNames[currentDateObject.getUTCMonth()];
    const yearCurrent = currentDateObject.getUTCFullYear().toString().substring(2);
    const formattedCreatedDate = `${monthCreated} ${yearCreated}`;
    const formattedCurrentDate = `${monthCurrent} ${yearCurrent}`;
    return (
      <Text>{formattedCreatedDate} - {formattedCurrentDate}</Text>
    )
  }

  function getStatusAndDateRangeCollect(expirationDate, status) {
    const expirationDateBatch = new Date(expirationDate);
    const expirationPlus3Days = new Date(expirationDateBatch);
    expirationPlus3Days.setDate(expirationPlus3Days.getDate() + 3);
    const expirationPlus15Days = new Date(expirationDateBatch);
    expirationPlus15Days.setDate(expirationPlus15Days.getDate() + 15);
    if (currentDate >= expirationPlus3Days && currentDate <= expirationPlus15Days) {
      return (
        <Box display='flex' justifyContent='start' gap={2} alignItems='center'>
          <FaCheck color='#6495ed'></FaCheck>
          <Text fontWeight='500' color='#6495ed'>Coleta disponível</Text>
        </Box>
      );
    } else if (status === "pending") {
      return (
        <Box display='flex' justifyContent='start' gap={2} alignItems='center'>
          <FaClock color='red'></FaClock>
          <Text fontWeight='500' color='red'>Aguardando disponibilidade</Text>
        </Box>
      )
    } else if (status === "collected") {
      return (
        <Box display='flex' justifyContent='start' gap={2} alignItems='center'>
          <FaCheck color='green'></FaCheck>
          <Text fontWeight='500' color='green'>Coletado</Text>
        </Box>
      )
    }
  }

  function getStatusAndDateRangeDelivery(status) {
    if (status === 'pending') {
      return (
        <Box display='flex' justifyContent='start' gap={2} alignItems='center'>
          <FaClock color='red'></FaClock>
          <Text fontWeight='500' color='red'>Aguardando disponibilidade</Text>
        </Box>
      );
    } else if (status === 'out_for_delivery') {
      return (
        <Box display='flex' justifyContent='start' gap={2} alignItems='center'>
          <FaTruck color='#6495ed'></FaTruck>
          <Text fontWeight='500' color='#6495ed'>Saiu para entrega</Text>
        </Box>
      )
    } else if (status === 'delivered') {
      return (
        <Box display='flex' justifyContent='start' gap={2} alignItems='center'>
          <FaCheck color='green'></FaCheck>
          <Text fontWeight='500' color='green'>Entregue</Text>
        </Box>
      )
    } else if (status === 'cancelled') {
      return (
        <Box display='flex' justifyContent='start' gap={2} alignItems='center'>
          <FaTimes color='red'></FaTimes>
          <Text fontWeight='500' color='red'>Cancelado</Text>
        </Box>
      )
    }
  }

  async function removePayment(e, payment) {
    e.preventDefault()
    if (window.confirm("Tem certeza de que deseja remover pedido?")) {
      try {
        const response = await api.delete(`/api/v1/payments/${payment}`);
        if (response.data.success) {
          swal({
            title: "Ok",
            text: "Pedido removido",
            icon: "success",
          });
          fetchUser()
        } else {
          swal({
            title: "Opss",
            text: "Algo deu errado!!",
            icon: "error",
          });
        }
      } catch (error) {
        console.error('Error removing payment:', error);
        return false;
      }
    }
  }

  const getCardIcon = (brand) => {
    switch (brand) {
      case 'Visa':
        return <FaCcVisa size="1.3rem"/>;
      case 'Master Card':
        return <FaCcMastercard size="1.3rem"/>;
      default:
        return <FaCreditCard size="1.3rem"/>;
    }
  };


  return (<>
    <Box
      background={theme.colors.white500}
      minHeight={"120vh"}
    >
      <Navbar/>
      <Box
        padding={{base: '2%', md: '30px 50px 50px 50px'}}
        background={theme.colors.orange}
        borderRadius='15px'
        boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px;"}
      >
        <Text margin={{base: '', md: '1% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '36px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Perfil</Text>
        <Form
          isOpen={isOpen}
          onClose={onClose}
          theme={theme}
          profile={profile}
          user={user}
          handleProfileChange={handleProfileChange}
          handleEditUser={handleEditUser}
          states={states}
          cities={cities}
          handleLocality={handleLocality}
          mapedCitiesForState={mapedCitiesForState}
          handleFileChange={handleFileChange}
          userPhoto={userPhoto}
          setUserDocument={setUserDocument}
          userDocument={userDocument}
          setUserPhoneNumber={setUserPhoneNumber}
          userPhoneNumber={userPhoneNumber}
        />
        <Box
          display={{base: 'block', md: 'flex'}}
          justifyContent={"space-between"}
          width={{base: '100%', md: '100%'}}
          gap={{base: '10', md: ''}}
          margin={{base: 'auto', md: 'auto'}}
        >
          <Box
            width={{base: '90%', md: '70%'}}
            margin={{base: 'auto', md: '2% 0 4% 0'}}
          >
            <Box
              background={theme.colors.white500}
              border='1px solid #D44120'
              borderRadius="15px"
              boxShadow={"rgba(0, 0, 0, 0.25) 0px 5px 15px;"}
              marginBottom='5px'
            >
              <Text
                color={theme.colors.primary}
                fontWeight="bold"
                fontSize="22px"
                padding="10px 30px"
                textAlign={"center"}
                margin={"0"}
              >
                Pedidos em Andamento - {orders.filter(e => e.payment.status === "pending").length}
              </Text>
            </Box>
            {orders.length < 1 && (
              <Text
                textAlign={"center"}
                fontSize={"25px"}
                fontWeight={"bolder"}
              >
                Nenhum pedido em andamento.
              </Text>
            )}
            <Box
              display={window.screen.width < 768 ? "block" : "flex"}
              flexWrap={"wrap"}
              justifyContent={"space-between"}
            >
              <Box
                display={{base: 'block', md: 'flex'}}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
                width={{base: '100%', md: '100%'}}
                background='transparent'
                borderRadius='0px 0px 15px 15px'
                marginBottom='1px'
              >
                <SimpleGrid spacing={4} templateColumns={gridTemplateColumns} width='100%'>
                  {orders
                    .filter(e => e.payment.status === "pending")
                    .sort((a, b) => new Date(b.payment.created_at) - new Date(a.payment.created_at))
                    .map((openBatchOrder) => (
                      <Card
                        key={openBatchOrder.payment.id}
                        background={theme.colors.white500}
                        boxShadow={"rgba(0, 0, 0, 0.25) 0px 5px 15px;"}
                        id='debug'
                      >
                        <Button
                          position="absolute"
                          top="10px"
                          right="-5px"
                          onClick={(e) => removePayment(e, openBatchOrder.payment.id)}
                          colorScheme="red"
                          size="sm"
                          background='transparent'
                          color='red'
                          fontSize='lg'
                          _hover={{
                            background: 'transparent',
                            color: 'red'
                          }}
                        >
                          <FaTrashAlt></FaTrashAlt>
                        </Button>
                        <CardHeader margin='0px 0px -30px 0px'>
                          <Heading size='md'> Pedido #{openBatchOrder.payment.id}</Heading>
                          {openBatchOrder.payment.payment_method === "credit_card" && (
                            <Box display='flex' gap={2} alignItems='center'>
                              <FaCreditCard></FaCreditCard>
                              <Text>Cartão de crédito</Text>
                            </Box>
                          )}
                          {openBatchOrder.payment.payment_method === "pix" && (
                            <Box display='flex' gap={2} alignItems='center'>
                              <FaMoneyBill></FaMoneyBill>
                              <Text>Pix</Text>
                            </Box>
                          )}
                        </CardHeader>
                        <CardBody>
                          {
                            openBatchOrder.batch_details.map((batch_order, index) => (
                              <Box key={index}>
                                <Box
                                  display={"flex"}
                                  justifyContent='space-between'
                                  margin={"10px 0"}
                                  gap={"20%"}
                                >
                                  <Text>
                                    {batch_order.item.quantity} {batch_order.batch.unity_of_measurement}{batch_order.item.quantity > 1 ? <span>s</span> : <span> </span>}
                                  </Text>
                                  <Text>
                                    {batch_order.product.name.length > 8
                                      ? <span>{batch_order.product.name.slice(0, 5)}...</span>
                                      : <span>{batch_order.product.name}</span>}
                                  </Text>
                                  <Text>
                                    {batch_order.item.amount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                  </Text>
                                </Box>
                                <Divider orientation='horizontal' borderColor={theme.colors.primary} borderBottomWidth='2px' margin='10px auto'></Divider>
                              </Box>
                            ))
                          }
                          <Text textAlign='end' fontWeight='500' marginTop='10px'>
                            Total: {openBatchOrder.batch_orders.reduce((n, { amount }) => n + amount, 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                          </Text>
                        </CardBody>
                        <CardFooter margin='-30px 0px 0px 0px'>
                        <Button
                          background={theme.colors.primaryGreen}
                          color='white'
                          borderColor={theme.colors.primaryGreen}
                          _hover={{
                            background: theme.colors.green500,
                            borderColor: theme.colors.green500,
                          }}
                          onClick={(e) => handlePayment(e, openBatchOrder.payment.id)}
                        >
                          PAGAR
                        </Button>
                        </CardFooter>
                      </Card>
                    ))
                  }
                </SimpleGrid>
              </Box>
            </Box>
            <Box
              background={theme.colors.white500}
              borderRadius='15px 15px 0px 0px'
              cursor={"pointer"}
              boxShadow={"rgba(0, 0, 0, 0.25) 0px 5px 15px;"}
            >
              <Text
                color={theme.colors.primary}
                fontWeight="bold"
                fontSize="22px"
                padding="10px 30px"
                textAlign={"center"}
                margin={"30px 0 0px 0"}
              >
                Histórico de Pedidos - {orders.filter(e => e.payment.status === "paid").length}
              </Text>
            </Box>
            <Box
              display={{base: 'block', md: 'flex'}}
              justifyContent={"space-between"}
              flexWrap={"wrap"}
              width={{base: '100%', md: '100%'}}
              background='rgba(245,245,245, .7)'
              borderRadius='0px 0px 15px 15px'
              boxShadow={"rgba(0, 0, 0, 0.25) 0px 5px 15px;"}
            >
              <Accordion allowMultiple defaultIndex={[0]} width='100%'>
                {orders
                  .filter(e => e.payment.status === "paid" || e.payment.status === "cancelled")
                  .sort((a, b) => new Date(b.payment.created_at) - new Date(a.payment.created_at))
                  .map((paidBatchOrder, index, filteredOrders) => (
                    <Box key={paidBatchOrder.payment.id}>
                      <AccordionItem borderColor={theme.colors.primary} bg={theme.colors.white500} borderRadius={index === filteredOrders.length - 1 ? '0px 0px 15px 15px' : '0px'}>
                        <h2>
                          <AccordionButton fontWeight='700' color={theme.colors.primary} height='50px'>
                            <Box as="span" flex='1' textAlign='left'>
                              Pedido #{paidBatchOrder.payment.id}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={5} bg={theme.colors.white500}>
                          {paidBatchOrder.batch_details.map((batch_order, index) => (
                            <Box key={batch_order.item.id}>
                              <Box
                                display={"flex"}
                                justifyContent='space-between'
                                margin={"10px 0"}
                                gap={"20%"}
                              >
                                <Text
                                  fontWeight={"500"}
                                >
                                  Produto:
                                </Text>
                                <Text
                                  fontWeight={"500"}
                                >
                                  {isMobile && batch_order.product.name.length > 15
                                    ? <span>{batch_order.product.name.slice(0, 15)}...</span>
                                    : <span>{batch_order.product.name}</span>
                                  }
                                </Text>
                              </Box>
                              <Box
                                display={"flex"}
                                justifyContent='space-between'
                                margin={"10px 0"}
                                gap={"20%"}
                              >
                                <Text
                                  fontWeight={"500"}
                                >
                                  Quantidade:
                                </Text>
                                <Text
                                  fontWeight={"500"}
                                >
                                  {batch_order.item.quantity} {batch_order.batch.unity_of_measurement}{batch_order.item.quantity > 1 ? <span>s</span> : <span></span>}
                                </Text>
                              </Box>
                              <Box
                                display={"flex"}
                                justifyContent='space-between'
                                margin={"10px 0"}
                                gap={"20%"}
                              >
                                <Text
                                  fontWeight={"500"}
                                >
                                  Valor:
                                </Text>
                                <Text
                                  fontWeight={"500"}
                                >
                                  {batch_order.item.amount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                </Text>
                              </Box>
                              {paidBatchOrder.payment.observations && (
                                <Box
                                  display={"flex"}
                                  justifyContent='space-between'
                                  margin={"10px 0"}
                                  gap={"20%"}
                                >
                                  <Text
                                    fontWeight={"500"}
                                  >
                                    Observações:
                                  </Text>
                                  <Text
                                    fontWeight={"400"}
                                    textAlign='end'
                                  >
                                    {paidBatchOrder.payment.observations}
                                  </Text>
                                </Box>
                              )}
                              <Box>
                                {(batch_order.item.delivery_method === "delivery" || batch_order.item.delivery_method === "delivery-express") && (<>
                                  <Text fontWeight='500' marginTop='1rem'>Entrega em: </Text>
                                  <Text>{batch_order.item.delivery_address}</Text>
                                  <Box display={{base: 'block', md: 'flex'}} justifyContent='start' gap={5}>
                                    {calculateDeliveryPeriod(batch_order.batch.id, batch_order.product, batch_order.item.delivery_method, batch_order.item)}
                                    {getStatusAndDateRangeDelivery(batch_order.item.delivery_status)}
                                  </Box>
                                </>)}
                                {batch_order.item.delivery_method === "collect" && (<>
                                  <Text fontWeight='500' marginTop='1rem'>Coleta em: </Text>
                                  <Text>{batch_order.collection_address.address_street}, nº {batch_order.collection_address.address_number}, {batch_order.collection_address.address_complement} - {batch_order.collection_address.address_neighborhood}</Text>
                                  <Text>{cities.find(city => city.id === batch_order.collection_address.city_id).name} - {states.find(state => state.id === batch_order.collection_address.state_id).acronym} - CEP: {batch_order.collection_address.address_postal_code}</Text>
                                  <Box display={{base: 'block', md: 'flex'}} justifyContent='start' gap={5}>
                                    {calculateDeliveryPeriod(batch_order.batch.id, batch_order.product, batch_order.item.delivery_method, batch_order.item)}
                                    {getStatusAndDateRangeCollect(batch_order.batch.expiration_date, batch_order.item.delivery_status)}
                                  </Box>
                                </>)}
                              </Box>
                              <Divider orientation='horizontal' borderColor={theme.colors.primary} borderBottomWidth='2px' margin='10px auto'></Divider>
                            </Box>
                          ))}
                          {paidBatchOrder.payment.payment_method === "credit_card" && (<>
                            <Box display='flex' gap={2} alignItems='center' justifyContent='end'>
                              <FaCreditCard></FaCreditCard>
                              <Text>Cartão de crédito</Text>
                            </Box>
                            <Text textAlign='end'>{paidBatchOrder.payment.number_of_installments} x {(paidBatchOrder.batch_orders.reduce((n, { amount }) => n + amount, 0) / paidBatchOrder.payment.number_of_installments).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Text>
                          </>)}
                          {paidBatchOrder.payment.payment_method === "pix" && (
                            <Box display='flex' gap={2} alignItems='center' justifyContent='end'>
                              <FaMoneyBill></FaMoneyBill>
                              <Text>Pix</Text>
                            </Box>
                          )}
                          <Text
                            fontSize={"18px"}
                            fontWeight={"500"}
                            textAlign='end'
                            margin='1rem 0rem'
                          >
                            Total: {(paidBatchOrder.payment.price_in_cents / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                          </Text>
                        </AccordionPanel>
                      </AccordionItem>
                    </Box>
                  ))
                }
              </Accordion>
            </Box>
          </Box>
          <Box
            width={{base: '90%', md: '30%'}}
            margin={{base: '30px auto 0px auto', md: '2% 0 4% 0'}}
          >
            <Box
              boxShadow={"rgba(0, 0, 0, 0.25) 0px 5px 15px;"}
              background={theme.colors.white500}
              borderRadius="15px 15px 0px 0px"
              borderBottom='1px solid #D44120'
            >
              <Text
                fontSize={"24px"}
                color={theme.colors.primary}
                fontWeight="bold"
                padding="10px 30px"
              >
                Minha carteira
              </Text>
            </Box>
            <Box
              background={theme.colors.white500}
              height={{base: 'auto', md: "auto"}}
              padding={"20px"}
              borderRadius="0 0 15px 15px"
              marginBottom='30px'
              boxShadow={"rgba(0, 0, 0, 0.25) 0px 5px 15px;"}
            >
              <Box display='flex' justifyContent='space-between'>
                <Stat>
                  <StatLabel>Créditos disponíveis</StatLabel>
                  <StatNumber>{userCredits.length > 0 && <span>{(userCredits.reduce((n, { amount }) => n + amount, 0)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span> || <span>R$0,00</span>}</StatNumber>
                  <StatHelpText>{formatDateDifference(profile.created_at)}</StatHelpText>
                </Stat>
                <Button
                  onClick={(e) => toggleCreditsHistory(e)}
                  background="transparent"
                  color={theme.colors.primary}
                  borderColor={theme.colors.primary}
                  padding='0px'
                  alignItems='start'
                  display='flex'
                  gap={2}
                  _hover={{
                    color: "#216a10",
                    bg: "transparent",

                  }}
                >
                  Extrato de Créditos
                  {!creditHistorySection ?
                    <span><FaAngleDown></FaAngleDown></span>
                  :
                    <span><FaAngleUp></FaAngleUp></span>
                  }
                </Button>
              </Box>
              {creditHistorySection && (<>
                <TableContainer>
                  <Table variant='simple' color='black'>
                    <Thead>
                      <Tr width='100%'>
                        <Th textAlign='center' borderColor={theme.colors.primary}>Pedido</Th>
                        <Th textAlign='center' borderColor={theme.colors.primary}>Data</Th>
                        <Th textAlign='center' borderColor={theme.colors.primary}>Valor</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {userCredits.length > 0 && (
                        <>
                          {userCredits.map((credit) => (
                            <Tr width='100%' key={credit.id}>
                              <Td display="flex" justifyContent='center'  borderColor={theme.colors.primary}>
                                <Stat flex='0 1 1%'>
                                  <StatHelpText>
                                    {credit.amount > 0 ?  <StatArrow type='increase' /> : <StatArrow type='decrease' />}
                                  </StatHelpText>
                                </Stat>
                                #{credit.payment_id}
                              </Td>
                              <Td textAlign='center' marginBottom='-1px' borderColor={theme.colors.primary}>
                                {new Date(credit.created_at).toLocaleDateString('pt-BR')}
                              </Td>
                              <Td textAlign='center' borderColor={theme.colors.primary}>
                                {credit.amount.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}
                              </Td>
                            </Tr>
                          ))}
                        </>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
                <Box display='flex' justifyContent='space-between' margin='10px 40px 0px 40px'>
                  <Text fontWeight='bolder' color={theme.colors.primary}>Total</Text>
                  <Text fontWeight='bolder' color={theme.colors.primary}>{(userCredits.reduce((n, { amount }) => n + amount, 0)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Text>
                </Box>
              </>)}
              {savedCards.length > 0 && (
                <>
                  <Divider orientation='horizontal' my="15px" display={{base: "block", md: "block"}} borderColor={theme.colors.primary}/>
                  <Text fontWeight="bold">
                    Meus Cartões
                  </Text>
                  <Box>
                  {savedCards.map((card) => (
                    <Box key={card.id} display="flex" justifyContent="space-between" alignItems="center">
                      <Box display="flex" gap="5px">
                        {getCardIcon(card.data.brand)}
                        <Text fontSize="14px">{card.data.display_number}</Text>
                        <Text fontSize="14px">{card.data.holder_name}</Text>
                        <Text fontSize="14px">{card.data.month}/{card.data.year}</Text>
                      </Box>
                      <Button
                        background="transparent"
                        border="none"
                        color="red"
                        onClick={() => handleRemovePaymentMethod(card.id)}
                        _hover={{
                          color: "orange"
                        }}
                      >
                        <FaTrashAlt />
                      </Button>
                    </Box>
                  ))}
                  </Box>
                </>
              )}
            </Box>
            <Box
              borderRadius="15px 15px 0px 0px"
              borderBottom='1px solid #D44120'
              background={theme.colors.white500}
            >
              <Text
                fontSize={"24px"}
                color={theme.colors.primary}
                fontWeight="bold"
                padding="10px 30px"
              >
                Minhas Infos
              </Text>
            </Box>
            <Flex flexDirection={{base: 'column', md: 'column'}} alignItems="center" width='100%'>
              <Box
                background={theme.colors.white500}
                height={{ base: 'auto', md: "auto" }}
                padding="10px 0px"
                borderRadius="0 0 15px 15px"
                marginBottom={{ base: '0px', md: '0px' }}
                width='100%'
              >
                <Flex alignItems='center' justifyContent="center" flexDirection={{ base: 'column', md: 'column' }}>
                  <Box position='relative' display='inline-block'>
                    <Avatar
                      size={{ base: 'xl', md: 'xl' }}
                      display='block'
                      max-width='100%'
                      src={localStorage.getItem('userPhoto') !== "" ? localStorage.getItem('userPhoto') : 'https://img.freepik.com/free-icon/user_318-159711.jpg'}
                    />
                    <Button position='absolute' width='30px' bottom='-5px' right='-5px' padding='0px' borderRadius='50%' size='sm'
                      onClick={onOpen}
                      background="rgba(245,245,245, .6)"
                      color={theme.colors.primary}
                      borderColor={theme.colors.primary}
                      _hover={{
                        color: "#216a10",
                      }}
                    >
                      <FaPen></FaPen>
                    </Button>
                  </Box>
                  <Box width='100%' padding='20px 10px'>
                    <Flex alignItems='center' gap={1} justifyContent='space-between'>
                      <Text fontSize='18px' fontWeight='500'>Nome:</Text>
                      <Text>{profile.full_name ? <span>{profile.full_name}</span> : <span></span>}</Text>
                    </Flex>
                    <Flex alignItems='center' gap={1} justifyContent='space-between'>
                      <Text fontSize='18px' fontWeight='500'>Celular:</Text>
                      <Text>{profile.phone_number ? <span>{formatPhoneNumber(profile.phone_number)}</span> : <span></span>}</Text>
                    </Flex>
                    <Flex alignItems='center' gap={1} justifyContent='space-between'>
                      <Text fontSize='18px' fontWeight='500'>Documento:</Text>
                      <Text>{profile.document_number ? <span>{handleDocumentDisplay(profile.document_number)}</span> : <span></span>}</Text>
                    </Flex>
                    <Flex alignItems='center' gap={1} justifyContent='space-between'>
                      <Text fontSize='18px' fontWeight='500'>Email:</Text>
                      <Text>{profile.email ? <span>{profile.email}</span> : <span></span>}</Text>
                    </Flex>
                    {(profile.address_street || profile.address_number || profile.address_complement  || profile.address_neighborhood || profile.city_id || profile.state_id || profile.address_postal_code) && (<>
                      <Text fontSize='18px' fontWeight='500'>Endereço:</Text>
                      <Text>{profile.address_street ? <span>{profile.address_street}</span> : <span></span>}, {profile.address_number ? <span>{profile.address_number}</span> : <span></span>}{profile.address_complement ? <span>, {profile.address_complement}</span> : <span></span>}, {profile.address_neighborhood ? <span>{profile.address_neighborhood}</span> : <span></span>}</Text>
                      <Text>{profile.city_id ? <span>{cities.find(city => city.id === profile.city_id)?.name}</span> : <span></span>} - {profile.state_id ? <span>{states.find(state => state.id === profile.state_id)?.acronym}</span> : <span></span>}{profile.address_postal_code ? <span> | {profile.address_postal_code}</span> : <span></span>}</Text>
                    </>)}
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
    <Footer />
    </>
  )
}
