import {
  Box,
  Button,
  Text,
  Image,
  useClipboard,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
  useMediaQuery,
  Spinner
} from "@chakra-ui/react";
import { Footer } from "../../components/Footer";
import { theme } from "../../styles/theme";
import Navbar from "../../components/Navbar";
import { CardFormPayment } from "../../components/CardFormPayment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../services/api";
import swal  from 'sweetalert';


export function Payment() {
  const { id } = useParams();
  const [payment, setPayment] = useState({});
  const [orderInfos, setOrderInfos] = useState({});
  const [batchOrders, setBatchOrders] = useState([]);
  const [client, setClient] = useState({});
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [intervalId, setIntervalId] = useState(null);
  const [loadingPage, setLoadingPage] = useState(true);
  const { hasCopied, onCopy } = useClipboard(payment.pix_qrcode_text)

  const isMobile = useMediaQuery("(max-width: 767px)")[0];

  const handleCopy = (e) => {
    e.preventDefault();
    onCopy();
    e.currentTarget.innerHTML = "Copiado!!";
    e.currentTarget.disabled = true;
  };

  async function fetchPayment() {
    const response = await api.get(`/api/v1/payments/${id}`)
    setPayment(response.data.payment)
    setOrderInfos(response.data.order_infos)
    setBatchOrders(response.data.batch_orders)
    setClient(response.data.user)
    setLoadingPage(false)
  }

  async function updateInvoiceStatus() {
    const response = await api.get(`/api/v1/payments/${payment.id}/invoice_status`)
    if (response.data.invoice.status === "paid") {
      setInvoiceStatus(response.data.invoice_status)
      window.location.href = `/perfil/${client.id}?payment_success=true`
      const items = orderInfos.batch_details.map((item, index) => {
        return {
          item_id: item.id,
          price: item.amount,
          quantity: item.quantity
        };
      });
      if (window && window.gtag) {
        window.gtag("event", "purchase", {
          // This purchase event uses a different transaction ID
          // from the previous purchase event so Analytics
          // doesn't deduplicate the events.
          // Learn more: https://support.google.com/analytics/answer/12313109
          transaction_id: payment.id,
          value: (payment.price_in_cents / 100).toFixed(2),
          currency: "BRL",
          items: items
        });
      }
    } else {
      setInvoiceStatus(response.data.invoice_status)
    }
  }


  useEffect(() => {
    fetchPayment();
  }, [])

  useEffect(() => {
    if (Object.keys(payment).length > 0) {
      const id = setInterval(() => {
        updateInvoiceStatus();
      }, 15000);
      setIntervalId(id);
    }
  }, [payment])


  return (
    <Box
      background={theme.colors.white500}
    >
      <Navbar />
      <Box
        padding={{base: '5% 0%', md: '1% 0% 2% 0%'}}
      >
        <Box
          width={{base: "100%", md: "100%"}}
          borderRadius='15px'
          background={theme.colors.orange}
          margin={{base: "0px", md: ""}}
          boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px;"}
          height='auto'
          padding={{base: '2%', md: '30px 50px 50px 50px'}}
        >
          <Text margin={{base: '', md: '1% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '36px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Pagamento</Text>
          {loadingPage && (
            <Box display='flex' justifyContent='center' padding='50px'>
              <Spinner color={theme.colors.primary} margin='auto'/>
            </Box>
          )}
          {!loadingPage && (
            <Box
              display={window.screen.width < 768 ? "block" : "flex"}
              justifyContent={"space-between"}
            >
              <Box width={{base: '100%', md: '60%'}} margin={{base: '', md: '0% 0% 2% 0%'}}>
                <Text
                  fontWeight={"500"}
                  fontSize={"22px"}
                  marginTop={2}
                  marginBottom={5}
                  color={theme.colors.white500}
                >
                  Resumo do pedido:
                </Text>
                <Box pb={5} bg="transparent">
                  <Accordion defaultIndex={[0]} allowMultiple width='100%'>
                    {orderInfos.batch_details?.map((batch_order, index)  => {
                      return(
                        <AccordionItem key={index} bg="transparent" borderColor={theme.colors.white500}>
                          <h2>
                            <AccordionButton fontWeight='700' color={theme.colors.white500}>
                              <Box as="span" flex='1' textAlign='left'>
                                Item {index+= 1}
                              </Box>
                              {batch_order.item.amount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={5} bg="transparent">
                            <Box
                              display={"flex"}
                              justifyContent='space-between'
                              margin={"10px 0"}
                              gap={"20%"}
                            >
                              <Text
                                fontWeight={"400"}
                              >
                                Produto:
                              </Text>
                              <Text
                                fontWeight={"400"}
                              >
                                {isMobile && batch_order.product.name.length > 15
                                  ? `${batch_order.product.name.slice(0, 15)}...`
                                  : batch_order.product.name
                                }
                              </Text>
                            </Box>
                            <Box
                              display={"flex"}
                              justifyContent='space-between'
                              margin={"10px 0"}
                              gap={"20%"}
                            >
                              <Text
                                fontWeight={"400"}
                              >
                                Quantidade
                              </Text>
                              <Text
                                fontWeight={"400"}
                              >
                                {batch_order.item.quantity} {batch_order.batch.unity_of_measurement}{batch_order.item.quantity > 1 ? 's' : ' '}
                              </Text>
                            </Box>
                            <Box
                              display={"flex"}
                              justifyContent='space-between'
                              margin={"10px 0"}
                              gap={"20%"}
                            >
                              <Text
                                fontWeight={"400"}
                              >
                                Recebimento:
                              </Text>
                              <Text
                                fontWeight={"400"}
                              >
                                {batch_order.item.delivery_method === "delivery"
                                  ? <span>Entrega</span>
                                  : <span>Coleta</span>
                                }
                              </Text>
                            </Box>
                            <Divider orientation='horizontal' borderColor='black' borderBottomWidth='2px' margin='10px auto'></Divider>
                            <Box display='flex' justifyContent='space-between' alignitems='center'>
                              <Text
                                fontWeight={"400"}
                                textAlign='end'
                                margin='0rem 0rem'
                              >
                                Subtotal:
                              </Text>
                              <Text
                                fontSize={"18px"}
                                fontWeight={"400"}
                                textAlign='end'
                                margin='0rem 0rem'
                              >
                                {batch_order.item.amount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                              </Text>
                            </Box>
                          </AccordionPanel>
                        </AccordionItem>
                      )
                    })}
                  </Accordion>
                  <Box display='flex' justifyContent='space-between' alignitems='center' margin={{base: '2% 4%', md: '2% 2%'}}>
                    <Text
                      fontSize={"18px"}
                      fontWeight={"500"}
                      textAlign='end'
                      margin='0rem 0rem'
                      color={theme.colors.white500}
                    >
                      Total:
                    </Text>
                    <Text
                      fontSize={"18px"}
                      fontWeight={"500"}
                      textAlign='end'
                      margin='0rem 0rem'
                      color={theme.colors.white500}
                    >
                      {(payment.price_in_cents / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                    </Text>
                  </Box>
                </Box>
              </Box>
              {payment.payment_method === "credit_card" && (
                <CardFormPayment payment={payment} batchOrders={batchOrders}></CardFormPayment>
              )}
              <Box
                margin="15px 0"
                background={theme.colors.white500}
                borderRadius="15px"
                display={payment.payment_method === "pix" ? "block" : "none"}
                width={{base: '100%', md: '30%'}}
              >
                <Box

                  background='transparent'
                  borderRadius="15px 15px 0px 0px"
                  borderBottom='1px solid #D44120'
                >
                  <Text
                    fontSize={"22px"}
                    color={theme.colors.primary}
                    fontWeight="bold"
                    padding="10px 30px"
                  >
                    Pix
                  </Text>
                </Box>
                <Box
                  padding="10px 20px"
                >
                  <Text
                    textAlign={"center"}
                    fontSize={"22px"}
                    fontWeight={"500"}
                  >
                    Scaneie o QR code abaixo no aplicativo do seu banco
                  </Text>
                  <Image
                    src={payment.pix_qrcode}
                    margin={"30px auto"}
                    width={"70%"}
                  >
                  </Image>
                  <Button
                    onClick={handleCopy}
                    width={window.screen.width < 768 ? "40%" : "25%"}
                    margin={window.screen.width < 768 ? "20px 30%" : "20px 38.5%"}
                    background={"transparent"}
                    border={`1px solid ${theme.colors.primary}`}
                    _hover={{
                      background: theme.colors.primary
                    }}
                  >
                    Copiar texto
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}
