import React, { useEffect } from 'react';
import {
  Box,
  Text,
  Button,
  border,
} from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import { Link } from 'react-router-dom';


export function AddToCartPopUp() {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);


  return (
    <>
      <Box position='fixed' top='0' left='0' width='100%' height='100%' bg='rgba(0, 0, 0, .5)' display='flex' justifyContent='center' alignItems='center' zIndex='100000'>
        <Box className="popup" padding='3rem' borderRadius='15px' zIndex='1000' bg='white'>
          <Box position='relative' className="popup-inner">
            <Text textAlign='center' color={theme.colors.primaryGreen} fontSize='22px'>Produto Adicionado ao carrinho!!!</Text>
            <Text textAlign='center'>O que deseja fazer?</Text>
            <Box display='flex' marginTop='50px' gap={3} justifyContent='center' alignItems='center'>
              <Link
                to={"/loja"}
              >
                <Button
                  color={theme.colors.primary}
                  bg='#f5f5f5'
                  border='1px solid #D44120'
                  _hover={{
                    background: '#D44120',
                    color: '#f5f5f5',
                    border: '1px solid #D44120',
                  }}
                >
                  Continuar Comprando
                </Button>
              </Link>
              <Link to='/carrinho'
                style={{ textDecoration: 'none' }}
              >
                <Button
                  bg={theme.colors.primaryGreen}
                  color='#f5f5f5'
                  _hover={{
                    background: '#f5f5f5',
                    color: '#216a0f',
                    border: '1px solid #216a0f',
                  }}
                >
                  Ver Carrinho ({localStorage.getItem('cartItems')})
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
