import {
  Box,
  Input,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Select
} from "@chakra-ui/react";
import { theme } from "../../../styles/theme";
import { InputMask } from '../../../components/InputMask'

export function Form({isOpen, onClose, user, handleProfileChange, handleEditUser, states, cities, handleLocality, mapedCitiesForState, profile, handleFileChange, userPhoto, setUserDocument, userDocument, setUserPhoneNumber, userPhoneNumber}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} overlayStyle={{ backgroundColor: `${theme.colors.whiteSmoke}` }}>
      <ModalOverlay />
      <ModalContent bg="#f5f5f5">
        <ModalHeader color={theme.colors.primary}>Editar Infos</ModalHeader>
        <ModalCloseButton _hover={{background: theme.colors.primary}}/>
        <ModalBody>
          <FormControl encType="multipart/form-data">
            <Text color={theme.colors.primary}>Nome</Text>
            <Input borderColor={theme.colors.primary} _hover={{borderColor: theme.colors.primary}} name="full_name" value={profile?.full_name} onChange={handleProfileChange}></Input>
            <Text color={theme.colors.primary} marginTop="10px">CPF</Text>
            <InputMask maskType="cpf" placeholder="CPF" setUserDocument={setUserDocument} value={userDocument} onChange={(e) => setUserDocument(e.target.value)}/>
            <Text color={theme.colors.primary} marginTop="10px">Celular</Text>
            <InputMask maskType="phone" placeholder="(99) 99999-9999" name="phone_number" value={userPhoneNumber} setUserPhoneNumber={setUserPhoneNumber} onChange={(e) => setUserPhoneNumber(e.target.value)}/>
            <Box>
              <Text color={theme.colors.primary} marginTop="10px">Logradouro</Text>
              <Input borderColor={theme.colors.primary} _hover={{borderColor: theme.colors.primary}} name="address_street" value={profile?.address_street} onChange={handleProfileChange}></Input>
            </Box>
            <Box
              display={"flex"}
              gap={"10px"}
              marginTop="10px"
            >
              <Box>
                <Text color={theme.colors.primary}>Nº</Text>
                <Input borderColor={theme.colors.primary} _hover={{borderColor: theme.colors.primary}} name="address_number" value={profile?.address_number} onChange={handleProfileChange}></Input>
              </Box>
              <Box>
                <Text color={theme.colors.primary}>Complemento</Text>
                <Input borderColor={theme.colors.primary} _hover={{borderColor: theme.colors.primary}} name="address_complement" value={profile?.address_complement} onChange={handleProfileChange}></Input>
              </Box>
            </Box>
            <Box
              display={"flex"}
              gap={"10px"}
              marginTop="10px"
            >
              <Box>
                <Text color={theme.colors.primary}>Bairro</Text>
                <Input borderColor={theme.colors.primary} _hover={{borderColor: theme.colors.primary}} name="address_neighborhood" value={profile?.address_neighborhood} onChange={handleProfileChange}></Input>
              </Box>
              <Box>
                <Text color={theme.colors.primary}>CEP</Text>
                <Input borderColor={theme.colors.primary} _hover={{borderColor: theme.colors.primary}} name="address_postal_code" value={profile?.address_postal_code} onChange={handleProfileChange}></Input>
              </Box>
            </Box>
            <Box
              display={"flex"}
              gap={"10px"}
              marginTop="10px"
            >
              <Box>
                <Text color={theme.colors.primary} >Estado</Text>
                <Select
                  name="state_id"
                  id="state_input"
                  value={profile?.state_id || ""}
                  onChange={(e) => {
                    handleProfileChange(e);
                    handleLocality(e);
                  }}
                  borderColor={theme.colors.primary} _hover={{borderColor: theme.colors.primary}}
                >
                  <option value=""></option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.acronym}
                    </option>
                  ))}
                </Select>
              </Box>
              {(mapedCitiesForState.length > 0) && (
                <Box>
                  <Text color={theme.colors.primary}>Cidade</Text>
                  <Select
                    name="city_id"
                    value={profile?.city_id || ""}
                    onChange={handleProfileChange}
                    borderColor={theme.colors.primary} _hover={{borderColor: theme.colors.primary}}
                  >
                    {mapedCitiesForState.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </Select>
                </Box>
              )}
            </Box>
            <Input type="file" id="userPhoto" onChange={handleFileChange} name="photo" multiple={false} marginTop='20px' border='none' display="none"/>
            <label htmlFor="userPhoto">
              <Box
                as="span"
                display="inline-block"
                border="1px solid"
                borderColor={theme.colors.primary}
                borderRadius="md"
                padding="5px"
                cursor="pointer"
                backgroundColor="transparent"
                marginTop="20px"
                marginRight="10px"
                color={theme.colors.primary}
                _hover={{background: theme.colors.primary, color: theme.colors.orange}}
              >
                Escolher Foto
              </Box>
              {userPhoto && (
                <span>{userPhoto.name}</span>
              )}
            </label>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            background={"transparent"}
            _hover={{background: theme.colors.primary}}
            type="button"
            onClick={(e) => handleEditUser(e)}
            border='1px solid'
            color={theme.colors.primary}
          >
            Editar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
