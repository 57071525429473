import { Box, Text, Button, Image  } from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import { useAuth } from "../../Hooks/useAuth";
import { useContext, useEffect } from "react";
import Logo from "../../assets/images/logo.svg";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { api } from "../../services/api";
import logo from '../../assets/images/logo-boozen.PNG';
import { FaInstagram, FaMapMarkerAlt} from "react-icons/fa";
import { CityContext } from '../../Context/CityContext';

export function Footer() {
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { cityParam, cityName, cities, handleSetCity } = useContext(CityContext);

  useEffect(() => {
    isAuthenticated();
  }, [])

  const handleBestSellings = async () => {
    try {
      let url = `/api/v1/products?city_id=${cityParam}&produtos=best_selling`;
      const response = await api.get(url);
      const productsData = response.data.products;
      navigate('/produtos', { state: { products: productsData } });
    } catch (error) {
      console.error(error);
    }
  };

  return (<>
    <Box
      height={{ base: 'auto', md: '25vh' }}
      background={theme.colors.white500}
      display={window.screen.width < 768 ? "block" : "flex"}
      justifyContent={"space-around"}
      position={"relative"}
      bottom={"0"}
      alignItems={"end"}
      paddingTop={"30px"}
      textAlign={{ base: 'center', md: '' }}
    >
      <Box
        display={"block"}
      >
        <Box>
          <Link to='/quem_somos'
            style={{ textDecoration: 'none', fontWeight: 'bold', color: '#216a0f' }}
            fontSize={{ base: '15px', md: '10px' }}
            textDecoration="none"
          >
            QUEM SOMOS
          </Link>
        </Box>
        <Box>
          <Button
            style={{ textDecoration: 'none', fontWeight: 'bold', color: '#216a0f' }}
            fontSize='15px'
            onClick={handleBestSellings}
            background='transparent'
            _hover={{
              bg: 'transparent',
            }}
          >
            DESTAQUES
          </Button>
        </Box>
        <Button
          style={{ textDecoration: 'none', fontWeight: 'bold', color: 'black' }}
          fontSize={{ base: '15px', md: '15px' }}
          background='transparent'
          height='auto'
          _hover={{
            bg: 'transparent',
          }}
        >
          <a href='https://instagram.com/boozenoficial?igshid=NzZlODBkYWE4Ng==' target='_blank' rel="noopener noreferrer">
            <FaInstagram color='green' width='30px'></FaInstagram>
          </a>
        </Button>
        <Link to={'/'}>
          <Image
            src={logo}
            maxW={{ base: '50%', md: '220px' }}
            margin={{ base: '10px auto 2px auto', md: '' }}
          />
        </Link>
        <Box display='flex' alignItems='center' justifyContent='center' my={{base: 'px', md: 'opx'}}>
          <FaMapMarkerAlt  color={theme.colors.orange}></FaMapMarkerAlt>
          <Text fontSize='13px' color={theme.colors.orange}>Belo Horizonte | Contato: (31) 97130-1228</Text>
        </Box>
      </Box>

      <Link to='/loja'
        style={{ textDecoration: 'none' }}
      >
        <Button
          bg={theme.colors.orange}
          color={theme.colors.white}
          padding={"0px 50px"}
          my='10px'
          height={"30px"}
          _hover={{
            bg: theme.colors.secondary,
          }}
          size={'md'}
          borderRadius={"50px"}
        >
          LOJA
        </Button>
      </Link>
    </Box>
    <Box background={theme.colors.white500}>
      <Text
        fontSize={{ base: '16px', md: '12px' }}
        fontWeight={"lighter"}
        textAlign={"center"}
        paddingBottom="20px"
        color={"black"}
        px='10px'
      >
        Copyright 2022 Todos os direitos reservado a Boozen | INNOVATION . CNPJ: 49.997.548/0001-47
      </Text>
    </Box>
  </>);
}
