import { Box, Button, Container, Input, SimpleGrid, Text } from "@chakra-ui/react";
import { Footer } from "../../components/Footer";
import { theme } from "../../styles/theme";
import Navbar from "../../components/Navbar";


export function WhoWeAre() {
  return (
    <Box
      background={theme.colors.secondary}
    >
      <Navbar />
      <Box
        background={theme.colors.secondary}
        height={{ base: 'auto', md: 'auto' }}
        padding="50px"
      >
        <Text marginBotom="10px" textAlign='center' fontWeight="bolder" fontSize="32px" color={theme.colors.primary}>QUEM SOMOS</Text>
        <Text textAlign='start' fontWeight="400" fontSize="22px">
          Na BOOZEN, nossa paixão pelo universo das bebidas e o desejo de transformar a experiência de compra nos impulsionam a redefinir o mercado. Como líderes inovadores no setor de bebidas, estamos comprometidos em oferecer uma abordagem revolucionária que coloca você, nosso valioso cliente, no centro de tudo o que fazemos.
        </Text>
        <Text textAlign='start' fontWeight="400" fontSize="22px">
          Nossa jornada começou com uma visão simples, mas poderosa: possibilitar que os apreciadores de bebidas desfrutem de suas escolhas favoritas diretamente da fonte, eliminando intermediários e complexidades. Assim nasceu a BOOZEN - uma fusão de "booze" (gíria para bebida alcoólica) e "zen", refletindo nossa busca pela simplicidade, qualidade e tranquilidade em cada gole.
        </Text>
        <Text textAlign='start' fontWeight="400" fontSize="22px">
          Nossos valores fundamentais são a qualidade, conveniência e economia. Trabalhamos incansavelmente para estabelecer parcerias diretas com as melhores destilarias, vinícolas e cervejarias, garantindo que cada produto que chega até você seja autêntico, fresco e sublime. Através do nosso inovador modelo de assinaturas personalizadas, permitimos que você escolha suas bebidas favoritas e as receba regularmente no conforto da sua casa, economizando tempo e dinheiro.
        </Text>
        <Text textAlign='start' fontWeight="400" fontSize="22px">
          Somos mais do que apenas uma empresa - somos uma comunidade de entusiastas de bebidas que compartilham uma visão comum de desfrutar momentos especiais de forma mais acessível e autêntica. Com a BOOZEN, você está se unindo a um movimento que redefine como as bebidas são apreciadas, compradas e compartilhadas.
        </Text>
        <Text textAlign='start' fontWeight="400" fontSize="22px">
        Bem-vindo à BOOZEN. Celebre a vida, explore sabores e descubra um novo mundo de possibilidades.
        </Text>
      </Box>
      <Footer />
    </Box>
  )
}
