import { api } from '../services/api'; // Import your API module
import swal  from 'sweetalert';

async function finishCustomerOrder(
  user,
  cartBatches,
  useCredit,
  userCredits,
  deliveryMethod,
  paymentMethod,
  paymentObservations,
  deliveryAddress,
  deliveryAddressNumber,
  deliveryAddressComplement,
  couponDiscount,
  couponKind,
  validCoupon,
  products,
  batches
) {
  // if (user.full_name === "" || user.document_number === "" || user.phone_number === "") {
  //   // eslint-disable-next-line no-unused-expressions
  //   window.location.href = `/perfil/${user.id}?missing_data=true`;
  //   return
  // }
  // if (deliveryMethod === "" ) {
  //   swal({
  //     title: "Quase la!",
  //     text: "Escolha um método de recebimento apra continuar",
  //     icon: "info",
  //   });
  //   return
  // }
  // if (paymentMethod === "not_selected" ) {
  //   swal({
  //     title: "Quase la!",
  //     text: "Escolha um método de pagamento apra continuar",
  //     icon: "info",
  //   });
  //   return
  // }
  // if (deliveryMethod === "delivery" && (deliveryAddress.logradouro === "" || deliveryAddressNumber === ""  || deliveryAddress.bairro === "" || deliveryAddress.localidade === "" || deliveryAddress.uf === "" || deliveryAddress.cep === "")) {
  //   swal({
  //     title: "Quase la!",
  //     text: "Preencha o endereço de entrega completo antes de continuar",
  //     icon: "info",
  //   });
  //   return
  // }
  if (window.confirm("Tem certeza de que deseja continuar?")) {
    const dataObjectPayment = new FormData();
    dataObjectPayment.append('payment[created_at]', new Date());
    dataObjectPayment.append('payment[updated_at]', new Date());
    dataObjectPayment.append('payment[user_id]', user.id);
    dataObjectPayment.append('payment[payment_method]', paymentMethod);
    dataObjectPayment.append('payment[observations]', paymentObservations);
    if (couponDiscount > 0) {
      dataObjectPayment.append('payment[coupon_id]', validCoupon.coupon_id);
    }
    const paymentResponse = await api.post('/api/v1/payments', dataObjectPayment);
    const totalAmount = cartBatches.reduce((n, { amount }) => n + amount, 0);
    let creditDiscountAmountPerBatch = 0;
    let couponDiscountAmountPerBatch = 0;
    let totalCredits = 0;
    let deliveryFeePerBatch = 0;
    let deliveryFee = 0;
    let couponDiscountAmount = 0

    if (useCredit) {
      totalCredits = userCredits.reduce((n, { amount }) => n + amount, 0);
      creditDiscountAmountPerBatch = totalCredits / cartBatches.length;
      const dataObjectPayment = new FormData();
      dataObjectPayment.append('credit[payment_id]', paymentResponse.data.id);
      dataObjectPayment.append('credit[amount]', -totalCredits);
      dataObjectPayment.append('credit[user_id]', user.id);
      const creditResponse = await api.post('/api/v1/credits', dataObjectPayment);
    }

    const targetBrands = ["Xa de Cana", "StoneLight", "Drink Pronto ", "Ice Guella", "Whitte Rabbit", "Xangolião", "Lambe Lambe"];
    const productsWithTargetBrands = products.filter(product => targetBrands.includes(product.brand));
    const productsWithoutTargetBrands = products.filter(product => !targetBrands.includes(product.brand));

    const hasProductWithTargetBrands = productsWithTargetBrands.length > 0;
    const hasProductWithoutTargetBrands = productsWithoutTargetBrands.length > 0;

    if (totalAmount.toFixed(2) < 120.00 && deliveryMethod === 'delivery') {
      deliveryFeePerBatch = 6.90 / cartBatches.length;
      deliveryFee = 6.90;
    } else if (totalAmount.toFixed(2) < 120.00 && deliveryMethod === 'delivery-express' && hasProductWithTargetBrands && hasProductWithoutTargetBrands) {
      deliveryFeePerBatch = 18.80 / cartBatches.length;
      deliveryFee = 18.80;
    } else if (totalAmount.toFixed(2) < 120.00 && deliveryMethod === 'delivery-express' && hasProductWithTargetBrands && !hasProductWithoutTargetBrands) {
      deliveryFeePerBatch = 11.90 / cartBatches.length;
      deliveryFee = 11.90;
    }


    if (couponDiscount > 0 && couponKind === 'fixed') {
      couponDiscountAmountPerBatch = couponDiscount / cartBatches.length;
    } else if (couponDiscount > 0 && couponKind === 'percentage') {
      couponDiscountAmountPerBatch = ((totalAmount * couponDiscount) / 100) / cartBatches.length;
    }

    if (paymentMethod === 'credit_card') {
      if (couponDiscount > 0 && couponKind === 'fixed') {
        couponDiscountAmount = couponDiscount
      } else if (couponDiscount > 0 && couponKind === 'percentage') {
        couponDiscountAmount = ((totalAmount * couponDiscount) / 100);
      }
      const totalAfterDiscounts = totalAmount + deliveryFee - totalCredits - couponDiscountAmount
      // cardFeePerBatch = (totalAfterDiscounts * 0.04) / cartBatches.length;
    }

    const promises = cartBatches.map(async (cartBatch, index) => {
      const cartBatchId = cartBatch.batch_id;
      const matchingBatch = batches.find(batch => batch.id === cartBatchId);
      if (!matchingBatch) {
        console.log(`No batch found with ID: ${cartBatchId}`);
      }
      const matchingProductId = matchingBatch ? matchingBatch.product_id : undefined;
      const cartProduct = products?.find(product => product.id === matchingProductId);
      const isTargetBrand = targetBrands.includes(cartProduct.brand);
      const deliveryStatusForProduct = deliveryMethod === "collect"
      ? "collect"
      : isTargetBrand && deliveryMethod === "delivery-express"
        ? "delivery-express"
        : "delivery";
      const dataObject = new FormData();
      const batchAmount = cartBatch.amount + deliveryFeePerBatch - couponDiscountAmountPerBatch - creditDiscountAmountPerBatch;
      dataObject.append('batch_order[amount]', batchAmount.toFixed(2));
      dataObject.append('batch_order[quantity]', cartBatch.quantity);
      dataObject.append('batch_order[user_id]', user.id);
      dataObject.append('batch_order[batch_id]', cartBatch.batch_id);
      dataObject.append('batch_order[payment_id]', paymentResponse.data.id);
      dataObject.append('batch_order[delivery_method]', deliveryStatusForProduct);
      dataObject.append('batch_order[delivery_status]', 'pending');
      if (deliveryMethod === 'delivery' || deliveryMethod === 'delivery-express') {
        dataObject.append(
          'batch_order[delivery_address]',
          `${deliveryAddress.logradouro}, ${deliveryAddressNumber}, ${deliveryAddressComplement}, ${deliveryAddress.bairro} - ${deliveryAddress.localidade} - ${deliveryAddress.uf} CEP: ${deliveryAddress.cep}`
        );
      }
      // const response = await api.post(`/api/v1/cart_batches/${cartBatch.id}/finish_my_order`, dataObject);
      const response = await api.post(`/api/v1/cart_batches/finish_my_order`, dataObject);

      return response;
    });

    return Promise.all(promises).then((responses) => {
      if (responses.every((response) => response.data.status === 'created')) {
        return {
          success: true,
          paymentId: paymentResponse.data.id,
          totalPrice: (paymentResponse.data.price_in_cents / 100),
        };
      } else {
        return {
          success: false,
          message: 'Algo deu errado',
        };
      }
    });
  }

}

export { finishCustomerOrder };
