import { Box, Input, Text, Image, Button, Checkbox, Spinner, Divider, Card, CardBody, CardFooter, Stack, Heading, RadioGroup,
Radio } from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import { Footer } from "../../components/Footer";
import { api } from "../../services/api";
import { calculateDeliveryPeriod } from "../../services/deliveryPeriodCalculation";
import { setFinalPriceForCartBatch } from "../../services/setFinalPriceForCartBatch";
import { setFinalPriceForOrder } from "../../services/setFinalPriceForOrder";
import { theme } from "../../styles/theme";
import { useAuth } from "../../Hooks/useAuth";
import { BsDash, BsPlus } from 'react-icons/bs';
import { debounce } from 'lodash'
import Navbar from "../../components/Navbar";
import { FaRegTimesCircle, FaThumbsUp, FaThumbsDown, FaTruck  } from 'react-icons/fa';
import swal  from 'sweetalert';
import { finishCustomerOrder } from "../../services/finishCustomerOrder";
import { InputMask } from '../../components/InputMask'
import { Link } from 'react-router-dom';


export function Cart() {
  const [cartBatches, setCartBatches] = useState([]);
  const { user, signInUser, getUserInfo, signUpUser } = useAuth();
  const [batches, setBatches] = useState([]);
  const [products, setProducts] = useState([]);
  const [prices, setPrices] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [userCredits, setUserCredits] = useState([]);
  const [amountAlreadyPurchased, setAmountAlreadyPurchased] = useState([]);
  const [useCredit, setUseCredit] = useState(false);
  const [CEP, setCEP] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState({});
  const [deliveryAddressNumber, setDeliveryAddressNumber] = useState("");
  const [deliveryAddressComplement, setDeliveryAddressComplement] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [collectionAddress, setCollectionAddress] = useState({});
  const [collectionAddressCity, setCollectionAddressCity] = useState({});
  const [collectionAddressState, setCollectionAddressState] = useState({});
  const [loadingPage, setLoadingPage] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("not_selected");
  const [paymentObservations, setPaymentObservations] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [validCoupon, setValidCoupon] = useState(undefined);
  const [statusCoupon, setStatusCoupon] = useState("Não aplicado");
  const [couponKind, setCouponKind] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [useAddressFromUser, setUseAddressFromUser] = useState(false);
  const [guestCartBatches, setGuestCartBatches] = useState([]);
  const [authType, setAuthType] = useState('signUp');
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userDocument, setUserDocument] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isOrderProcessing, setIsOrderProcessing] = useState(false);

  const [userAddress, setUserAddress] = useState({
    city: "",
    complement: "",
    neighborhood: "",
    number: "",
    postal_code: "",
    state: "",
    street: ""
  });

  const allowedCities = [
    'Belo Horizonte',
    'Nova Lima',
    'Contagem',
    'Betim',
    'Lagoa Santa'
  ];



  async function fetchCartBatches() {
    const cartData = localStorage.getItem('guestCart');
    const guestCart = cartData ? JSON.parse(cartData) : [];

    const guestBatches = guestCart.map(item => ({
      product_id: item.product_id,
      batch_id: item.batch_id,
      quantity: item.quantity,
      amount: parseFloat(item.amount),
    }));

    setCartBatches(guestBatches);
    console.log(guestBatches)
    const batchIds = guestBatches.map(item => item.batch_id);
    console.log(batchIds)
    if (batchIds.length > 0) {
      try {
        const response = await api.get(`/api/v1/cart_batches/get_guest_cart?batch_ids=${batchIds}`);
        setBatches(response.data.batches);
        setProducts(response.data.products);
        setPrices(response.data.prices);

        setPhotos(response.data.photos);
        setAmountAlreadyPurchased(response.data.amount_already_purchased);
        setUserCredits([]);
        setCollectionAddress(response.data.batches[0]?.collection_address || {});
        setCollectionAddressCity(response.data.batches[0]?.collection_address?.city || '');
        setCollectionAddressState(response.data.batches[0]?.collection_address?.state || '');
      } catch (error) {
        console.error("Error fetching batch/product details:", error);
      }
    }
    setLoadingPage(false);
  }

  function handleUseCreditChange() {
    setUseCredit(!useCredit);
  }

  const delayedQuery = useRef(
    debounce(async cartBatch => {
      const dataObject = new FormData();
      dataObject.append("cart_batch[amount]", cartBatch.amount);
      dataObject.append("cart_batch[quantity]", cartBatch.quantity);
      const response = await api.put(`/api/v1/cart_batches/${cartBatch.id}`, dataObject);
    }, 1000)
  ).current;

  function handleIncrement(cartBatch) {
    let updatedCartBatch;
    setAmountAlreadyPurchased(amountAlreadyPurchased + 1)
    const currentCartBatches = [...cartBatches]
    updatedCartBatch = currentCartBatches.find(e => e.batch_id === cartBatch.batch_id)
    ++updatedCartBatch.quantity
    const productId = batches.find(element => element.id === updatedCartBatch.batch_id).product_id
    const quantityPerBox = products.find(e => e.id === productId).quantity_per_box
    const sortedPrices = prices.filter(e => e.batch_id === updatedCartBatch.batch_id).sort((a, b) => a.unity - b.unity);
    let selectedPrice = sortedPrices[0];
    for (let i = 0; i < sortedPrices.length; i++) {
      if (updatedCartBatch.quantity >= sortedPrices[i].unity) {
        selectedPrice = sortedPrices[i];
      } else {
        break;
      }
    }
    updatedCartBatch.amount = quantityPerBox * updatedCartBatch.quantity * selectedPrice.price_per_unity
    setCartBatches(currentCartBatches);
    const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
    const updatedGuestCart = guestCart.map(item =>
      item.batch_id === cartBatch.batch_id
        ? {
            ...item,
            quantity: updatedCartBatch.quantity,
            amount: updatedCartBatch.amount
        }
        : item
    );
    localStorage.setItem('guestCart', JSON.stringify(updatedGuestCart));
  }


  function handleDecrement(cartBatch) {
    if (cartBatch.quantity <= 1 ) {
      document.getElementById("button-decrement").disabled = true
      return
    }
    let updatedCartBatch;
    setAmountAlreadyPurchased(amountAlreadyPurchased - 1)
    const currentCartBatches = [...cartBatches]
    updatedCartBatch = currentCartBatches.find(e => e.batch_id === cartBatch.batch_id)
    --updatedCartBatch.quantity
    const productId = batches.find(element => element.id === updatedCartBatch.batch_id).product_id
    const quantityPerBox = products.find(e => e.id === productId).quantity_per_box
    const sortedPrices = prices.filter(e => e.batch_id === updatedCartBatch.batch_id).sort((a, b) => a.unity - b.unity);
    let selectedPrice = sortedPrices[0];
    for (let i = 0; i < sortedPrices.length; i++) {
      if (updatedCartBatch.quantity >= sortedPrices[i].unity) {
        selectedPrice = sortedPrices[i];
      } else {
        break;
      }
    }
    updatedCartBatch.amount = quantityPerBox * updatedCartBatch.quantity * selectedPrice.price_per_unity
    setCartBatches(currentCartBatches)
    const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
    const updatedGuestCart = guestCart.map(item =>
      item.batch_id === cartBatch.batch_id
        ? {
            ...item,
            quantity: updatedCartBatch.quantity,
            amount: updatedCartBatch.amount
        }
        : item
    );
    localStorage.setItem('guestCart', JSON.stringify(updatedGuestCart));
  }

  useEffect(() => {
    if (authType === 'signIn') {
      window.location.href = "/sign_in";
    }
  }, [authType]);


  const handleFinishOrder = async (e) => {
    e.preventDefault();

    if (user && (user.full_name === "" || user.document_number === "" || user.phone_number === "")) {
      window.location.href = `/perfil/${user.id}?missing_data=true`;
      return;
    }

    if (deliveryMethod === "") {
      swal({
        title: "Quase lá!",
        text: "Escolha um método de recebimento para continuar.",
        icon: "info",
      });
      return;
    }

    if (paymentMethod === "not_selected") {
      swal({
        title: "Quase lá!",
        text: "Escolha um método de pagamento para continuar.",
        icon: "info",
      });
      return;
    }

    if (deliveryMethod === "delivery" && (deliveryAddress.logradouro === "" || deliveryAddressNumber === "" || deliveryAddress.bairro === "" || deliveryAddress.localidade === "" || deliveryAddress.uf === "" || deliveryAddress.cep === "")) {
      swal({
        title: "Quase lá!",
        text: "Preencha o endereço de entrega completo antes de continuar.",
        icon: "info",
      });
      return;
    }

    if (!user && authType === 'signUp') {
      if (userEmail === "" || userPassword === "" || userName === "" || userDocument === "" || userPhoneNumber === "") {
        swal({
          title: "Quase lá!",
          text: "Preencha todos os campos obrigatórios antes de continuar.",
          icon: "info",
        });
        return;
      }

      try {
        await signUpUser(userEmail, userPassword, userName, userDocument, userPhoneNumber);
      } catch (error) {
        swal({
          title: "Erro",
          text: "Ocorreu um erro ao se cadastrar. Tente novamente.",
          icon: "error",
        });
        return;
      }
    }

    setIsOrderProcessing(true);
  };

  useEffect(() => {
    const processOrder = async () => {
      if (user && isOrderProcessing && !isProcessing) {
        setIsProcessing(true);
        try {
          const result = await finishCustomerOrder(
            user,
            cartBatches,
            useCredit,
            userCredits,
            deliveryMethod,
            paymentMethod,
            paymentObservations,
            deliveryAddress,
            deliveryAddressNumber,
            deliveryAddressComplement,
            couponDiscount,
            couponKind,
            validCoupon,
            products,
            batches
          );
          if (result && result.success) {
            window.location = `/pagamentos/${result.paymentId}`;
            localStorage.removeItem('guestCart');
            const items = cartBatches.map((cartItem) => ({
              item_id: cartItem.product_id,
              price: cartItem.amount,
              quantity: cartItem.quantity
            }));
            if (window && window.gtag) {
              window.gtag("event", "begin_checkout", {
                value: result.totalPrice.toFixed(2),
                currency: "BRL",
                items: items
              });
            }
          } else if (result) {
            console.error("Order processing failed:", result.message);
          }
        } catch (error) {
          console.error("Error processing order:", error);
        } finally {
          setIsProcessing(false);
          setIsOrderProcessing(false);
        }
      }
    };

    if (isOrderProcessing) {
      processOrder();
    }
  }, [user, isOrderProcessing]);


  async function hendleRemoveCartBatch(e, cartBatch) {
    e.preventDefault()
    const userId = localStorage.getItem('userId');
    const itemsCounter = localStorage.getItem('cartItems');
    const updatedCounter = parseInt(itemsCounter, 10) - 1;
    localStorage.setItem('cartItems', updatedCounter.toString());
    if (window.confirm("Tem certeza de que deseja continuar?")) {
      const matchingBatch = batches.find(batch => batch.id === cartBatch.batch_id)
      if (matchingBatch) {
        const matchingProduct = products.find(product => product.id === matchingBatch.product_id);
        if (matchingProduct) {
          window.gtag('event', 'remove_from_cart', {
            currency: "BRL",
            value: cartBatch.amount,
            'items': [{
              'item_name': matchingProduct.name,
              'item_id': matchingProduct.id,
              'price': cartBatch.amount,
              'quantity': cartBatch.quantity
            }]
          });
        } else {
          console.error('Matching product not found.');
        }
      } else {
        console.error('Matching batch not found.');
      }
      try {
        const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
        const updatedGuestCart = guestCart.filter(item => item.batch_id !== cartBatch.batch_id);
        localStorage.setItem('guestCart', JSON.stringify(updatedGuestCart));
        updateCartState(cartBatch);
        swal({
          title: "Ok",
          text: "Produto removido do carrinho",
          icon: "success",
        });
      } catch (error) {
        console.error('Error removing cart batch:', error);
        return false;
      }
    }
  }

  function updateCartState(cartBatch) {
    const updatedCartBatches = cartBatches.filter(item => item.batch_id !== cartBatch.batch_id);
    console.log(updatedCartBatches)
    setCartBatches(updatedCartBatches);
    const removedProductBatch = batches.find(batch => batch.id === cartBatch.batch_id);
    if (removedProductBatch) {
      const updatedProducts = products.filter(product => product.id !== removedProductBatch.product_id);
      setProducts(updatedProducts);
      const updatedBatches = batches.filter(batch => batch.id !== cartBatch.batch_id);
      setBatches(updatedBatches);
    }
    swal({
      title: "Ok",
      text: "Produto removido do carrinho",
      icon: "success",
    });
  }



  async function fetchCEP(e) {
    e.preventDefault();
    try {
      const response = await fetch(`https://viacep.com.br/ws/${CEP}/json/`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      console.log(data);
      if (
        data.localidade !== 'Belo Horizonte' &&
        data.localidade !== 'Nova Lima' &&
        data.localidade !== 'Contagem' &&
        data.localidade !== 'Betim' &&
        data.localidade !== 'Lagoa Santa'
      ) {
        swal({
          title: 'Opss',
          text: 'Entregas somente para BH e região',
          icon: 'error',
        });
        setDeliveryAddress({});
        return;
      }
      setDeliveryAddress(data);
    } catch (error) {
      swal({
        title: 'Opss',
        text: 'Erro ao buscar o CEP. Formato inválido ou CEP não encontrado.',
        icon: 'error',
      });
      setDeliveryAddress({});
    }
  }


  function handleDeliveryMethod(value) {
    if (cartBatches.length === 0) {
      alert("Nenhum produto adicionado.");
      return
    }
    setDeliveryMethod(value)
  }

  function handlePaymentMethod(value) {
    setPaymentMethod(value)
  }

  function handleUseUserAddressForDelivery(event) {
    const isChecked = event.target.checked;
    setUseAddressFromUser(isChecked);
    if (isChecked) {
      if (
        userAddress.city !== "" &&
        userAddress.complement !== "" &&
        userAddress.neighborhood !== "" &&
        userAddress.number !== "" &&
        userAddress.postal_code !== "" &&
        userAddress.state !== "" &&
        userAddress.street !== ""
      ) {
        setDeliveryAddress({
          localidade: userAddress.city,
          bairro: userAddress.neighborhood,
          cep: userAddress.postal_code,
          uf: userAddress.state,
          logradouro: userAddress.street
        });
        setDeliveryAddressNumber(userAddress.number);
        setDeliveryAddressComplement(userAddress.complement);
      }
    } else {
      setDeliveryAddress({});
      setDeliveryAddressNumber('');
      setDeliveryAddressComplement('');
    }
  }


  function areAllFieldsFilled(userAddress) {
    return Object.entries(userAddress).every(([key, value]) => {
      if (key === 'complement') {
        return true;
      }
      if (key === 'city') {
        return allowedCities.includes(value.trim());
      }
      return value.trim() !== '';
    });
  }

  const isAllUserAddressFieldsFilled = areAllFieldsFilled(userAddress);

  useEffect(() => {
    fetchCartBatches();
  }, [])


  useEffect(() => {
    async function validateCoupon() {
      if (couponCode !== "") {
        const response = await api.get(`/validate_coupon?coupon_code=${couponCode}`);
        setValidCoupon(response.data);
        const { result, error, discount, message, discount_kind } = response.data;
        if (result) {
          setStatusCoupon(message);
          setCouponKind(discount_kind)
          setCouponDiscount(discount);
        } else {
          setStatusCoupon(error);
        }
      } else {
        setValidCoupon(undefined);
        setStatusCoupon("Não aplicado");
        setCouponDiscount(0);
      }
    }
    validateCoupon();
  }, [couponCode]);

  const now = new Date();
  const dayOfWeek = now.getDay();
  const currentHour = now.getHours();

  function hasBrandInCartBatches(brand) {
    return products.some(product => {
        return product.brand === brand;
    });
  }

  const hasBrandInCart = hasBrandInCartBatches("Xa de Cana") ||
                       hasBrandInCartBatches("StoneLight") ||
                       hasBrandInCartBatches("Drink Pronto ") ||
                       hasBrandInCartBatches("Ice Guella") ||
                       hasBrandInCartBatches("Whitte Rabbit") ||
                       hasBrandInCartBatches("Xangolião") ||
                       hasBrandInCartBatches("Lambe Lambe") ||
                       hasBrandInCartBatches("Off Bebidas");

  return (
    <Box
      background={theme.colors.white500}
      minHeight={"90vh"}
    >
      <Navbar />
      <Box
        padding={{base: '5% 0%', md: '1% 0% 2% 0%'}}
      >
        <Box
          borderRadius='15px'
          background={theme.colors.orange}
          boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px;"}
          padding={{base: '2%', md: '30px 50px 50px 50px'}}
        >
          <Text margin={{base: '', md: '1% 0% 0% 0%'}} textAlign='start' fontSize={{base: '22px', md: '36px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Carrinho</Text>
          <Text color={theme.colors.white500} fontSize={{base: '18px', md: '22px'}}  fontWeight="500" marginBottom="10px">Entrega grátis para pedidos acima de R$120,00</Text>
          <Box
            display={"flex"}
            flexDirection={{base: 'column', md: 'row'}}
            justifyContent={"space-between"}
            width={{base: '100%', md: '100%'}}
            margin={{base: 'auto', md: ''}}
            minHeight={{base: '90vh', md: ''}}
          >
            <Box gap='10px' width='100%'>
              {(cartBatches.length <= 0 && loadingPage) && (
                <Box display='flex' justifyContent='center' padding='50px'>
                  <Spinner color={theme.colors.primary} margin='auto'/>
                </Box>
              )}
              {(cartBatches.length === 0 && !loadingPage) && (
                <Text
                  textAlign={"center"}
                  fontSize={"24px"}
                  color={theme.colors.primaryGreen}
                  fontWeight={"bold"}
                  py='30px'
                >
                  Nenhum item adicionado ao carrinho.
                </Text>
              )}
              {cartBatches.length > 0 && cartBatches.map((cartBatch, index) => {
                return (
                  <Card
                    key={index}
                    direction={{ base: 'column', sm: 'row' }}
                    overflow='hidden'
                    variant='outline'
                    marginBottom='30px'
                    marginTop={{base: '30px', md: '0px'}}
                    borderColor='transparent'
                    background={theme.colors.white500}
                    boxShadow={"rgba(0, 0, 0, 0.25) 0px 5px 15px;"}
                    width={{base: '100%', md: '95%'}}
                    height='min-content'
                  >
                    <Image
                      objectFit='cover'
                      maxW={{ base: '100%', sm: '200px' }}
                      src={photos.find(element => element.product === (batches.find(element => element.id === cartBatch.batch_id).product_id))?.photo || require('../../assets/images/home-cover.webp')}
                      alt='Produto'
                      display={{base: 'none', md: 'block'}}
                      maxHeight='16.5rem'
                    />
                    <Button
                      position="absolute"
                      top="3px"
                      right="-5px"
                      onClick={(e) => hendleRemoveCartBatch(e, cartBatch)}
                      colorScheme="red"
                      size="sm"
                      background='transparent'
                      color='red'
                      fontSize='lg'
                      _hover={{
                        background: 'transparent',
                        color: 'red'
                      }}
                    >
                      <FaRegTimesCircle></FaRegTimesCircle>
                    </Button>
                    <Stack w='100%'>
                      <CardBody padding={{base: '5px 10px 0px 10px', md: '20px 20px 0px 5px'}}>
                      <Heading margin={{ base: '25px 0px 0px 0px', md: '0px 0px 0px 0px' }} size='md' display="flex" gap={10} alignItems="center">
                        {
                          products?.find(element => element.id === (batches?.find(element => element.id === cartBatch.batch_id).product_id))?.name.length > 60
                          ? `${products?.find(element => element.id === (batches?.find(element => element.id === cartBatch.batch_id).product_id))?.name.slice(0, 60)}...`
                          : products?.find(element => element.id === (batches?.find(element => element.id === cartBatch.batch_id).product_id))?.name
                        }
                        {
                          ((products?.find(element => element.id === (batches?.find(element => element.id === cartBatch.batch_id).product_id))?.brand === "Xa de Cana" ||
                          products?.find(element => element.id === (batches?.find(element => element.id === cartBatch.batch_id).product_id))?.brand === "StoneLight" ||
                          products?.find(element => element.id === (batches?.find(element => element.id === cartBatch.batch_id).product_id))?.brand === "Drink Pronto " ||
                          products?.find(element => element.id === (batches?.find(element => element.id === cartBatch.batch_id).product_id))?.brand === "Ice Guella" ||
                          products?.find(element => element.id === (batches?.find(element => element.id === cartBatch.batch_id).product_id))?.brand === "Whitte Rabbit" ||
                          products?.find(element => element.id === (batches?.find(element => element.id === cartBatch.batch_id).product_id))?.brand === "Xangolião" ||
                          products?.find(element => element.id === (batches?.find(element => element.id === cartBatch.batch_id).product_id))?.brand === "Lambe Lambe"||
                          products?.find(element => element.id === (batches?.find(element => element.id === cartBatch.batch_id).product_id))?.brand === "Off Bebidas") &&
                          ((dayOfWeek >= 3 && dayOfWeek <= 5 && currentHour >= 12 && currentHour < 20) || (dayOfWeek === 6 && currentHour >= 10 && currentHour < 16)))
                          ? (
                            <Box display="flex" gap={2} alignItems="center">
                              <FaTruck size={15} color={theme.colors.primaryGreen}></FaTruck>
                              <Text fontSize="15px" color={theme.colors.primaryGreen}>Entrega Express</Text>
                            </Box>
                          )
                          : (
                            <Text fontSize="15px" color={theme.colors.primaryGreen} marginBottom="0px">Entrega Normal</Text>
                          )
                        }
                      </Heading>
                        <Text pb='2'>{products.find(element => element.id === (batches.find(element => element.id === cartBatch.batch_id).product_id))?.brand}</Text>
                        <Box my={2} display={{base: 'block', md: 'flex'}} width='100%' justifyContent='space-between' alignItems='center'>
                          <Text fontWeight='500' pb={{base: '2', md: '0'}}>Quantidade:</Text>
                          <Box display='flex' marginTop={{base: '10px', md: '0'}} marginBottom='0' alignItems='center' padding='3px' border={{base: '1px solid rgba(212,65,32, .3)', md: '1px solid rgba(212,65,32, .3)'}} borderRadius='5px'>
                            <BsDash
                              size={32}
                              weight="fill"
                              cursor="pointer"
                              id="button-decrement"
                              onClick={(e) => handleDecrement(cartBatch)}
                            />
                            <Input
                              id={`cart${cartBatch.id}-quantity`}
                              margin={"auto"}
                              type="number"
                              width={{base: '30px', md: '100px'}}
                              padding='0%'
                              background={"transparent"}
                              fontWeight={"bold"}
                              textAlign="center"
                              border="none"
                              value={cartBatch.quantity}
                              readOnly={true}
                            />
                            <BsPlus
                              size={32}
                              weight="fill"
                              cursor="pointer"
                              margin={"auto"}
                              id="button-increment"
                              onClick={(e) => handleIncrement(cartBatch)}
                            />
                          </Box>
                        </Box>
                        <Text my={2} textAlign={{base: 'center', md: 'end'}}>{cartBatch.quantity} {batches?.find(element => element.id === cartBatch.batch_id)?.unity_of_measurement}{cartBatch.quantity > 1 ? 's' : ''} / {cartBatch.quantity * products?.find(element => element.id === (batches?.find(element => element.id === cartBatch.batch_id).product_id))?.quantity_per_box} {products.find(element => element.id === (batches.find(element => element.id === cartBatch.batch_id).product_id))?.size}</Text>
                        <Divider orientation='horizontal' borderColor={theme.colors.primary} borderBottomWidth='2px' margin='10px auto'></Divider>
                        {setFinalPriceForCartBatch(cartBatch)}
                      </CardBody>
                      <CardFooter padding='0px 0px 20px 5px'>
                        {deliveryMethod !== "" && (
                          <Text  textAlign={{base: 'start', md: 'center'}} fontWeight='500' color={theme.colors.primary} fontSize={{base: '15px', md: ''}}>
                            {calculateDeliveryPeriod(batches?.find(element => element.id === cartBatch.batch_id).id, products?.find(e => e.id === batches?.find(element => element.id === cartBatch.batch_id).product_id), deliveryMethod)}
                          </Text>
                        )}
                      </CardFooter>
                    </Stack>
                  </Card>
                );
              })}
            </Box>
            <Box width={{base: '100%', md: '45%'}}>
              <Box
                background={theme.colors.white500}
                borderRadius={"15px"}
                boxShadow={"rgba(0, 0, 0, 0.25) 0px 5px 15px;"}
                marginBottom={{base: '30px', md: '10px'}}
              >
                <Box
                  background='transparent'
                  borderRadius="15px 15px 0px 0px"
                  borderBottom='1px solid #D44120'
                >
                  <Text
                    fontSize={"30px"}
                    color={theme.colors.primary}
                    fontWeight="bold"
                    padding="10px 30px"
                  >
                    Detalhes
                  </Text>
                </Box>
                <Box padding='20px'>
                {!user && (<>
                    <Box border="1px solid red" padding="10px" my="15px" borderRadius="10px">
                      <Box my="5px">
                        <RadioGroup onChange={setAuthType} value={authType}>
                          <Stack direction="flex" justifyContent="space-between">
                            <Radio value="signIn">Já tenho uma conta</Radio>
                            <Radio value="signUp">Cadastrar</Radio>
                          </Stack>
                        </RadioGroup>
                      </Box>
                      { authType === "signIn" ?
                        <>

                        </>
                      :
                        <>
                          <Input
                            placeholder="E-mail"
                            margin="10px 0"
                            onChange={(e) => setUserEmail(e.target.value)}
                            borderColor={theme.colors.primaryOrange}
                            _focus={{
                              outline: 'none',
                              boxShadow: 'none',
                              borderColor: theme.colors.primary,
                            }}
                            autoComplete="current-email"
                          />
                          <Input
                            id="name-input"
                            placeholder="Nome Completo"
                            onChange={(e) => setUserName(e.target.value)}
                            borderColor={theme.colors.primaryOrange}
                            marginBottom="10px"
                            _focus={{
                              outline: 'none',
                              boxShadow: 'none',
                              borderColor: theme.colors.primary,
                            }}
                            autoComplete="current-name"
                          />

                          <InputMask maskType="cpf" placeholder="CPF" setUserDocument={setUserDocument} marginTop="10px"/>
                          <Box marginTop="10px">
                            <InputMask maskType="phone" placeholder="Celular" setUserPhoneNumber={setUserPhoneNumber}/>
                          </Box>
                          <Input
                            id="password-input"
                            placeholder="Escolha uma senha"
                            type="password"
                            onChange={(e) => setUserPassword(e.target.value)}
                            borderColor={theme.colors.primaryOrange}
                            marginTop="10px"
                            _focus={{
                              outline: 'none',
                              boxShadow: 'none',
                              borderColor: theme.colors.primary,
                            }}
                            autoComplete="current-password"
                          />
                        </>
                      }
                    </Box>
                  </>)}
                  <Text
                    fontWeight={"bold"}
                  >
                    Creditos disponíveis: {userCredits.length > 0 && (userCredits.reduce((n, { amount }) => n + amount, 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})) || `R$0,00`}
                  </Text>
                  <Checkbox colorScheme='green' borderColor={theme.colors.primary} onChange={handleUseCreditChange}>
                    Usar créditos na compra
                  </Checkbox>
                  <Text
                    marginTop={"20px"}
                    fontWeight={"bold"}
                  >
                    Selecione o método de pagamento
                  </Text>
                  <Box display='inline-grid'>
                    <Checkbox
                      colorScheme='green'
                      value="collect"
                      borderColor={theme.colors.primary}
                      onChange={() => handlePaymentMethod('pix')}
                      isChecked={paymentMethod === 'pix'}
                    >
                      PIX
                    </Checkbox>
                    <Checkbox
                      colorScheme='green'
                      value="collect"
                      borderColor={theme.colors.primary}
                      onChange={() => handlePaymentMethod('credit_card')}
                      isChecked={paymentMethod === 'credit_card'}
                    >
                      Cartão de Crédito (até 4x sem juros)
                    </Checkbox>
                  </Box>
                  <Text
                    marginTop={"20px"}
                    fontWeight={"bold"}
                  >
                    Logística:
                  </Text>
                  <Box>
                    <Box>
                      <Checkbox
                        colorScheme='green'
                        value="delivery"
                        marginBottom='10px'
                        borderColor={theme.colors.primary}
                        onChange={() => handleDeliveryMethod('delivery')}
                        isChecked={deliveryMethod === 'delivery'}
                      >
                        Entrega - (BH e Região) - 2 a 8 dias úteis (grátis acima de R$120,00)
                        {cartBatches.reduce((acc, batch) => acc + batch.amount, 0) > 120.00 ? <Text>Grátis</Text> : <Text>R$6,90</Text>}
                      </Checkbox>
                    </Box>
                    {((dayOfWeek >= 3 && dayOfWeek <= 5 && currentHour >= 12 && currentHour < 20) || (dayOfWeek === 6 && currentHour >= 10 && currentHour < 16)) && hasBrandInCart &&  (
                      <Box>
                        <Checkbox
                          colorScheme='green'
                          value="collect"
                          borderColor={theme.colors.primary}
                          onChange={() => handleDeliveryMethod('delivery-express')}
                          isChecked={deliveryMethod === 'delivery-express'}
                        >
                          Entrega Express - (BH)<br /> Mesmo dia (Para alguns produtos) (grátis acima de R$120,00)
                          {cartBatches.reduce((acc, batch) => acc + batch.amount, 0) > 120.00 ? <Text>Grátis</Text> : <Text>R$11,90</Text>}
                        </Checkbox>
                      </Box>
                    )}
                    <Box>
                      <Checkbox
                        colorScheme='green'
                        value="collect"
                        borderColor={theme.colors.primary}
                        onChange={() => handleDeliveryMethod('collect')}
                        isChecked={deliveryMethod === 'collect'}
                      >
                        Coleta - a partir de 8 dias úteis
                      </Checkbox>
                    </Box>
                    {(deliveryMethod === "delivery" || deliveryMethod === "delivery-express") && (
                      <Box marginTop='1rem'>
                        {isAllUserAddressFieldsFilled && (
                          <Checkbox
                            colorScheme='green'
                            value="useAdressForDelivery"
                            borderColor={theme.colors.primary}
                            onChange={(e) => handleUseUserAddressForDelivery(e)}
                          >
                            Usar meu endereço
                          </Checkbox>
                        )}
                        <Box display='flex' gap='10px'>
                          <Input
                            placeholder="Digite o CEP"
                            borderColor={theme.colors.primary}
                            type="text"
                            id="cep-input"
                            onChange={(e) => setCEP(e.target.value.replace(/[.-]/g, ''))}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                fetchCEP(e);
                              }
                            }}
                          />
                          <Button
                            onClick={(e) => fetchCEP(e)}
                            background='transparent'
                            border='1px solid red'
                            borderColor={theme.colors.primary}
                            _hover={{
                              background: theme.colors.primary,
                              color: 'white'
                            }}
                          >Buscar</Button>
                        </Box>
                        <Box
                          margin="10px 0"
                          display="flex"
                          gap={5}
                        >
                          <Input
                            placeholder="Rua"
                            value={deliveryAddress.logradouro || ''}
                            disabled
                            borderColor={theme.colors.primary}
                          />
                          <Input
                            placeholder="Número"
                            type="number"
                            borderColor={theme.colors.primary}
                            onChange={(e) => setDeliveryAddressNumber(e.target.value)}
                          />
                        </Box>
                        <Box
                          margin="10px 0"
                          display="flex"
                          gap={5}
                        >
                          <Input
                            placeholder="Complemento"
                            onChange={(e) => setDeliveryAddressComplement(e.target.value)}
                            borderColor={theme.colors.primary}
                          />
                          <Input
                            placeholder="Bairro"
                            value={deliveryAddress.bairro || ''}
                            disabled
                            borderColor={theme.colors.primary}
                          />
                        </Box>
                        <Box
                          margin="10px 0"
                          display="flex"
                          gap={5}
                        >
                          <Input
                            placeholder="Cidade"
                            value={deliveryAddress.localidade || ''}
                            disabled
                            borderColor={theme.colors.primary}
                          />
                          <Input
                            placeholder="Estado"
                            value={deliveryAddress.uf || ''}
                            disabled
                            borderColor={theme.colors.primary}
                          />
                        </Box>
                      </Box>
                    )}

                    {deliveryMethod === "collect" && (<>
                      <Text fontWeight='bolder'>Endereço:</Text>
                      <Text textAlign='start'>
                        {collectionAddress.address_street}, {collectionAddress.address_number}, {collectionAddress.address_complement}{collectionAddress.address_neighborhood ? <span>, {collectionAddress.address_neighborhood}</span> : <span></span>} - {collectionAddressCity.name} - {collectionAddressState.acronym} <br />
                        CEP: {collectionAddress.address_postal_code}
                      </Text>
                    </>)}

                    {((deliveryMethod === "delivery" || deliveryMethod === "delivery-express") &&
                        deliveryAddress.logradouro !== "" &&
                        deliveryAddress.bairro !== "" &&
                        deliveryAddress.localidade !== "" &&
                        deliveryAddress.uf !== "" &&
                        deliveryAddress.cep !== "") &&
                     (<>
                      <Text fontWeight='bolder'>Endereço para entrega:</Text>
                      <Text textAlign='start'>
                        {deliveryAddress.logradouro}, {deliveryAddressNumber}{deliveryAddressComplement ?  <span>, {deliveryAddressComplement}</span> : <span></span> }, {deliveryAddress.bairro} -
                        {deliveryAddress.localidade} -
                        {deliveryAddress.uf} <br />
                        CEP: {deliveryAddress.cep}
                      </Text>
                    </>)}
                  </Box>
                  <Text
                    marginTop={"20px"}
                    fontWeight={"bold"}
                  >
                    Resumo:
                  </Text>
                  <Box>
                    {setFinalPriceForOrder(cartBatches, deliveryMethod, useCredit, userCredits, paymentMethod, couponDiscount, couponKind, products)}
                  </Box>
                  <Text marginTop='20px' fontWeight={"500"}>Observações:</Text>
                  <Input
                    placeholder='Escolha o sabor ou
                    acrescente alguma info'
                    onChange={(e) => setPaymentObservations(e.target.value)}
                    border='1px solid #D44120'
                    padding='5px'
                    fontSize='16px'
                    background='transparent'
                    width={{base: '100%', md: '100%'}}
                    height='40px'
                    _focus={{
                      outline: 'none',
                      boxShadow: 'none',
                    }}
                  />
                  <Box marginTop='20px' display={{base: 'flex', md: 'flex'}} justifyContent='start' gap='3px' alignItems='center'>
                    <Input
                      placeholder='Cupom de desconto'
                      onChange={(e) => setCouponCode(e.target.value)}
                      border='1px solid #D44120'
                      padding='5px'
                      fontSize='16px'
                      background='transparent'
                      width={{base: '60%', md: '70%'}}
                      height='40px'
                      _focus={{
                        outline: 'none',
                        boxShadow: 'none',
                      }}
                    />
                    {validCoupon !== undefined && statusCoupon === "Ok" && (<>
                      <Text display='flex' alignItems='center' gap='3px' color='green' fontWeight='bolder'>{statusCoupon} <FaThumbsUp></FaThumbsUp></Text>
                    </>)}

                    {validCoupon !== undefined && statusCoupon !== "Ok"  && statusCoupon !== "Não Aplicado" && (<>
                      <Text display='flex' alignItems='center' gap='3px' color='red' fontWeight='bolder'>{statusCoupon} <FaThumbsDown></FaThumbsDown></Text>
                    </>)}
                    {statusCoupon === "Não aplicado" && (<>
                      <Text color='black' >{statusCoupon}</Text>
                    </>)}
                  </Box>

                  <Button
                    width="100%"
                    margin={{base: '5% 0%', md: '20px 0px 0px 0px'}}
                    background={theme.colors.secondaryGreen}
                    _hover={{
                      background: theme.colors.secondary
                    }}
                    onClick={(e) => handleFinishOrder(e)}
                  >
                    FINALIZAR PEDIDO
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}
