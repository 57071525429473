import {

  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Grid,
  GridItem
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { api } from "../../services/api";
import { CityContext } from '../../Context/CityContext';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export function SubcategoryFilters(props) {
  const location = useLocation();
  const [subcategories, setSubcategories] = useState([]);
  const { cityParam } = useContext(CityContext);
  const subcategoriesOptions = [
    {
      param: "CERVEJA, Cerveja",
      name: "Cervejas",
    },
    {
      param: "Vinho",
      name: "Vinhos",
    },
    {
      name: "Destilados",
      param: "DESTILADOS",
    },
    {
      name: "Outras Bebidas",
      param: "BEBIDAS MISTAS, OUTRAS BEBIDAS",
    },
    {
      name: "Não Alcoólicos",
      param: "Não alcoólicos, Não Alcoólicos",
    },
    {
      name: "Acessórios",
      param: "Acessórios",
    },
    {
      name: "Kits",
      param: "Kits",
    }
  ];

  async function handleSubcategories(e, subcategory) {
    e.preventDefault();
    const updatedSubcategories = subcategories.includes(subcategory)
      ? subcategories.filter((type) => type !== subcategory)
      : [...subcategories, subcategory];

    setSubcategories(updatedSubcategories);
    const flattenedSubcategories = updatedSubcategories.flat();
    filterProducts(flattenedSubcategories);
  }

  async function filterProducts(subcategories) {
    let url = `/api/v1/products?city_id=${cityParam}`;
    const urlParams = new URLSearchParams(location.search);

    if (subcategories.length > 0) {
      url = `${url}&subcategories=${subcategories.join(',')}`;
    }

    const filter = urlParams.get("filter");
    if (filter) {
      url = `${url}&filter=${filter}`;
    }

    const response = await api.get(url);
    props.setProducts(response.data.products);
  }

  return (
    <>
    <Accordion allowToggle defaultIndex={window.screen.width < 762 ? [] : [0]}>
      <AccordionItem>
        <AccordionButton
          fontWeight='bold'
          display={{base: 'block', md: 'none'}}
        >
          FILTRAR
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={2}>
          <Grid
            templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(7, 1fr)" }}
            gap={4}
            px={7}
          >
            {subcategoriesOptions.map((subcategory, index) => {
              return(
                <GridItem key={index}>
                  <Button
                    width={"100%"}
                    onClick={(e) => handleSubcategories(e, subcategory.param)}
                    border='1px solid #D44120'
                    borderRadius='5px'
                    _hover={{
                      transform: "scale(1.05)",
                      color: 'white',
                      bg: '#D44120'
                    }}
                    style={{
                      backgroundColor: subcategories.includes(subcategory.param)
                        ? '#D44120'
                        : 'transparent',
                        color: subcategories.includes(subcategory.param)
                        ? 'white'
                        : '#D44120',
                    }}
                  >
                    {subcategory.name}
                  </Button>
                </GridItem>
              )
            })}
          </Grid>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
    </>
  );
}
