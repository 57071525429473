import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function OrdersChart({ orders }) {
  const optionsOrders = {
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Pedidos',
      },
    },
  };

  const labels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const ordersByMonth = {};

  labels.forEach(month => {
    ordersByMonth[month] = {
      paid: [],
      notPaid: [],
    };
  });

  orders.forEach(order => {
    const updatedAtDate = new Date(order.updated_at);
    const monthName = updatedAtDate.toLocaleString('pt-BR', { month: 'long' });
    const monthCapitalize = monthName.charAt(0).toUpperCase() + monthName.slice(1);
    const monthIndex = labels.findIndex(month => month === monthCapitalize);
    if (monthIndex !== -1) {
      if (order.paid) {
        ordersByMonth[labels[monthIndex]].paid.push(order.amount);
      } else {
        ordersByMonth[labels[monthIndex]].notPaid.push(order.amount);
      }
    }
  });

  const paidOrdersByMonth = labels.map(month => ordersByMonth[month].paid.reduce((sum, amount) => sum + amount, 0));
  const notPaidOrdersByMonth = labels.map(month => ordersByMonth[month].notPaid.reduce((sum, amount) => sum + amount, 0));

  const dataOrders = {
    labels,
    datasets: [
      {
        label: 'Pedido Pagos',
        data: paidOrdersByMonth,
        borderColor: 'rgb(44,211,72)',
        backgroundColor: 'rgb(44,211,72)',
      },
      {
        label: 'Pedidos em Aberto',
        data: notPaidOrdersByMonth,
        borderColor: 'rgb(255,0,0)',
        backgroundColor: 'rgb(255,0,0)',
      },
    ],
  };

  return (<>
    <div  style={{ width: '100%', height: '27rem', background: '#f2f2f2', borderRadius: '15px', padding: '20px', margin: 'auto', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 15px', }}>
      <Bar options={optionsOrders} data={dataOrders} style={{ margin: 'auto'}}/>
    </div>
  </>)
}
