import { Box, Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, useDisclosure } from "@chakra-ui/react";
import { useState, useReducer } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import { api } from "../../../services/api";
import swal  from 'sweetalert';

export function EditProductType({ productTypeId, allCategories, onUpdateProductType }) {
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [productType, setProductType] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      categoryId: "",
    }
  );
  const [categories, setCategories] = useState(allCategories)

  const handleProductTypeChange = (e) => {
    const { name, value } = e.target;
    setProductType({ [name]: value });
  }

  async function handleEditSubcategory(e) {
    e.preventDefault();
    if (productType.category === "") {
      swal({
        title: "Opa",
        text: "escolha uma categoria!",
        icon: "info",
      });
    }
    const formData = new FormData()
    formData.append("product_type[name]", productType.name);
    formData.append("product_type[category_id]", productType.categoryId);
    const response = await api.put(`/api/v1/product_types/${productTypeId}`, formData, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
        contentType: "multipart/form-data"
      },
    })

    if (response.status === 200) {
      onUpdateProductType(productTypeId, response.data.name);
      swal({
        title: "Ohhh Yeahhh",
        text: "Dados atualizados com sucesso!",
        icon: "success",
      });
      onClose()
    } else {
      swal({
        title: "Ohhh Noo",
        text: "Algo deu errado!",
        icon: "error",
      });
    }
    onClose()
  }

  return (
    <>
      <Button onClick={onOpen}>Editar</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{productType.name} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Box
                margin="20px 0"
              >
                <Text>Nome</Text>
                <Input
                  placeholder={`${productType?.name}`}
                  onChange={handleProductTypeChange}
                  name="name"
                />
              </Box>

              <Box
                margin="20px 0"
              >
                <Text>categoria</Text>
                <Select
                  placeholder='Selecione a categoria'
                  onChange={handleProductTypeChange}
                  name="categoryId"
                >
                  {categories.map(subcategory => (
                    <option
                      key={subcategory.id}
                      value={subcategory.id}
                    >
                      {subcategory.name}
                    </option>
                  ))}
                </Select>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={onClose}
            >
              Fechar
            </Button>
            <Button
              colorScheme="green"
              onClick={(e) => handleEditSubcategory(e)}
            >
              Editar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
