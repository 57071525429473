import { Box, Text, Grid, GridItem  } from "@chakra-ui/react";
import React from "react";
import { Footer } from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { theme } from "../../styles/theme";
import { Link } from 'react-router-dom';
import { CarouselStore } from '../../components/CarouselStore'
import beerImage from '../../assets/images/01.jpg';
import wineImage from '../../assets/images/wines.jpg';
import spiritImage from '../../assets/images/03.jpg';
import readyToDrinkImage from '../../assets/images/04.jpg';
import bestSellingImage from '../../assets/images/05.jpg';
import nonAlcoholicImage from '../../assets/images/non-alcoholic.jpg';
import biggerDiscountImage from '../../assets/images/07.jpg';
import kitsImage from '../../assets/images/kits.jpg';
import accessoriesImage from '../../assets/images/accessories-store.jpeg';
import buffetsImage from '../../assets/images/buffets-cover.jpg';
import collabImage from '../../assets/images/collab-cover.jpg';
import brewersImage from '../../assets/images/brewers.jpg';
import choppImage from '../../assets/images/chopp-store.jpeg';

const MemoizedNavbar = React.memo(Navbar);
export function Store() {
  const typesOfCardToSelec = [
    {
      cardTitle: "Chopp",
      param: JSON.stringify(["chopp", "Chopp"]),
      cardImageUrl: choppImage,

    },
    {
      cardTitle: "Drinks Prontos",
      param: JSON.stringify(["OUTRAS BEBIDAS", "BEBIDAS MISTAS"]),
      cardImageUrl: readyToDrinkImage,

    },
    {
      cardTitle: "Microcervejarias",
      param: JSON.stringify(["Microcervejarias", "microcervejarias", "microcervejaria", "Microcervejaria"]),
      cardImageUrl: brewersImage,

    },
    {
      cardTitle: "Cervejas",
      param: JSON.stringify(["CERVEJA", "Cerveja"]),
      cardImageUrl: beerImage,

    },
    {
      cardTitle: "Vinhos e Espumantes",
      param: JSON.stringify(["Vinho"]),
      cardImageUrl: wineImage,

    },
    {
      cardTitle: "Destilados",
      param: JSON.stringify(["DESTILADOS"]),
      cardImageUrl: spiritImage,

    },
    {
      cardTitle: "Eventos, Buffets e Casamentos",
      param: JSON.stringify(["Eventos, Buffets e Casamentos"]),
      cardImageUrl: buffetsImage,

    },
    {
      cardTitle: "KITS",
      param: JSON.stringify(["Kits"]),
      cardImageUrl: kitsImage,

    },
    {
      cardTitle: "Não Alcoólicos",
      param: JSON.stringify(["NÃO ALCOOLICOS", "Não alcoólicos", "Não Alcoólicos", "NÃO ALCOOLICO", "Não alcóolico", "Não Alcóolico"]),
      cardImageUrl: nonAlcoholicImage,

    },
    {
      cardTitle: "Acessórios",
      param: JSON.stringify(["Acessórios"]),
      cardImageUrl: accessoriesImage,

    },
    {
      cardTitle: "Projetos Sociais",
      param: JSON.stringify(["Projetos Sociais", "projetos sociais", "Projetos sociais"]),
      cardImageUrl: collabImage,

    },
    {
      cardTitle: "Mais Vendidos",
      param: JSON.stringify(["Mais Vendidos"]),
      cardImageUrl: bestSellingImage,

    },
    {
      cardTitle: "Maiores Descontos",
      param: JSON.stringify(["Maiores Descontos"]),
      cardImageUrl: biggerDiscountImage,

    }
  ];

  return (
    <Box
      background={theme.colors.white500}
    >
      <MemoizedNavbar />
      <Box
        padding={{base: '5% 0%', md: '1% 0% 2% 0%'}}
      >
        <Box
          padding={{base: '2%', md: '30px 50px 50px 50px'}}
          width={{base: "100%", md: "100%"}}
          borderRadius='15px'
          background={theme.colors.orange}
          margin={{base: "0px", md: ""}}
          boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px;"}
          height='auto'
        >
          <Text textAlign='start' fontSize={{base: '22px', md: '36px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Loja</Text>
          <Grid
            templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(7, 1fr)" }}
            gap={4}
            margin='10px auto'
          >
            {typesOfCardToSelec.map((card, index) => {
              return (
                <Link to={`/produtos?filter=${encodeURIComponent(card.param)}`} key={index}>
                  <GridItem
                    backgroundImage={`url("${card.cardImageUrl}")`}
                    backgroundSize="cover"
                    backgroundPosition="center"
                    height={{ base: "7rem", md: "14rem" }}
                    borderRadius="15px"
                    display="flex"
                    alignItems="center"
                    opacity="0.80"
                    transition="transform 0.2s"
                    border="1px solid #D44120"
                    _hover={{
                      transform: "scale(1.05)"
                    }}
                  >
                    <Text
                      textAlign="center"
                      color='#fe660a'
                      fontSize={{ base: "22px", md: "27px" }}
                      fontWeight="bold"
                      textShadow="1px 1px 2px black"
                      margin="auto"
                      zIndex="10001"
                    >
                      {card.cardTitle}
                    </Text>
                  </GridItem>
                </Link>
              )
            })}
          </Grid>
          <Box pt={7}>
            <Text
              color={theme.colors.whiteSmoke}
              fontSize={{ base: "22px", md: "35px" }}
              fontWeight="bold"
              textShadow="1px 1px 2px #fe660a"
              marginBottom="20px"
              ps={6}
            >
              Destaques
            </Text>
            <CarouselStore></CarouselStore>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}
