import { Box, Button, Image, Input, Text, Checkbox } from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import logo from '../../assets/images/logo-boozen.PNG';
import { useState, useEffect, useRef } from "react";
import { useAuth } from "../../Hooks/useAuth";
import { jwtDecode } from "jwt-decode";
import { api } from "../../services/api";
import swal  from 'sweetalert';
import { Link } from 'react-router-dom';
import banner from "../../assets/images/sign_in.png";
import { FaHome } from "react-icons/fa";
import { InputMask } from '../../components/InputMask'


export const userToken = () => window.localStorage.getItem("@app/token");
export function SignUp() {
  const { signUpUser, getUserInfo } = useAuth();
  const isAuthenticated = () => userToken() !== null;

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userDocument, setUserDocument] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");


  function handleUserSignUp(e) {
    e.preventDefault();
    signUpUser(userEmail, userPassword, userName, userDocument.replace(/[.-\s]/g, ''), userPhoneNumber.replace(/\D/g, ''))
  }

  function handleTogglePasswordVisibility(e) {
    e.preventDefault();
    const passwordInput = document.getElementById('password-input')
    if (e.target.checked) {
      passwordInput.type = "text"
    } else {
      passwordInput.type = "password"
    }
  }

  async function handleCallbackResponse(response) {
    let userObject = jwtDecode(response.credential);
    localStorage.setItem('userPhoto', userObject.picture);
    signInUserWithGoogleEmail(response.credential)
  }

  async function signInUserWithGoogleEmail(token) {
    const response = await api.get(`/api/v1/users/sign_in_with_google?id_token=${token}`);
    if (response.status === 200) {
      window.localStorage.setItem("@app/token", response.data.tokens["access-token"]);
      window.localStorage.setItem("@app/uid", response.data.tokens["uid"]);
      window.localStorage.setItem("@app/client", response.data.tokens["client"]);
      window.localStorage.setItem("@app/expiry", response.data.tokens["expiry"]);
      await getUserInfo(response.data.user);
      window.location.href = "/loja";
      if (typeof window !== "undefined") {
        if (window.fbq != null) {
          window.fbq('trackCustom', 'signInUserWithGoogleEmail');
        }
      }
    } else {
      swal({
        title: "Ops",
        text: "Ocorreu um erro no seu login, confira seus dados",
        icon: "error",
      });
    }
  }


  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: "480318445952-v2iilvljfmm9jk2l9c1ldi66fkjfh06f.apps.googleusercontent.com",
      callback: handleCallbackResponse
    });

    google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      {theme: "outline", size: "large", text: "Cadastrar com Google"}
    )
  }, []);



  return (
    <Box
      height="100vh"
      background={theme.colors.secondary}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        width={{base: '95%', md: '75%'}}
        padding={{base: '3', md: '0'}}
        borderRadius={8}
        background={theme.colors.white500}
        display="flex"
        flexDirection="row"
        justifyContent='space-between'
        boxShadow={"rgba(255, 102, 0, 0.8) 0px 5px 25px;"}
        position='relative'
      >
        <Link to={'/'}
          style={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            textDecoration: 'none',
            transition: 'color 0.3s ease',
            fontSize: '20px',
            color: '#216a10'
          }}
        >
          <FaHome></FaHome>
        </Link>
        <Image
          borderRadius='15px 0px 0px 15px'
          objectFit='cover'
          src={banner}
          width="50%"
          display={{base: 'none', md: 'block'}}
        />
        <Box
          display="flex"
          flexDirection="column"
          padding={{base: '0px', md: '50px 100px'}}
          width={{base: '100%', md: '60%'}}
        >
          <Link to={'/'}>
            <Image
              objectFit='cover'
              maxW={{ base: '200px', md: '300px' }}
              src={logo}
              margin="20px auto"
              display={{base: 'block', md: 'none'}}
            />
          </Link>
          <Text
            fontSize={{base: '24px', md: '32px'}}
            color={theme.colors.primaryOrange}
            textAlign={"center"}
            fontWeight='bolder'
          >
            Cadastre-se
          </Text>
          <form onSubmit={(e) => handleUserSignUp(e)}>
            <Input
              placeholder="E-mail"
              margin="10px 0"
              onChange={(e) => setUserEmail(e.target.value)}
              borderColor={theme.colors.primaryOrange}
              _focus={{
                outline: 'none',
                boxShadow: 'none',
                borderColor: theme.colors.primary,
              }}
              autoComplete="current-email"
            />
            <Input
              id="name-input"
              placeholder="Nome Completo"
              onChange={(e) => setUserName(e.target.value)}
              borderColor={theme.colors.primaryOrange}
              marginBottom="10px"
              _focus={{
                outline: 'none',
                boxShadow: 'none',
                borderColor: theme.colors.primary,
              }}
              autoComplete="current-name"
            />

            <InputMask maskType="cpf" placeholder="CPF" setUserDocument={setUserDocument} marginTop="10px"/>
            <Box marginTop="10px">
              <InputMask maskType="phone" placeholder="Celular" setUserPhoneNumber={setUserPhoneNumber}/>
            </Box>
            <Input
              id="password-input"
              placeholder="Senha"
              type="password"
              onChange={(e) => setUserPassword(e.target.value)}
              borderColor={theme.colors.primaryOrange}
              marginTop="10px"
              _focus={{
                outline: 'none',
                boxShadow: 'none',
                borderColor: theme.colors.primary,
              }}
              autoComplete="current-password"
            />
            <Checkbox onChange={(e) => handleTogglePasswordVisibility(e)} textAlign={"left"} marginTop={"5px"}>Mostrar senha?</Checkbox>
            <Box
              marginTop="30px"
              display={"flex"}
              justifyContent={"space-between"}
              gap={10}
            >
              <Button
                background={theme.colors.primaryOrange}
                color={theme.colors.white}
                border='1px solid #ff6600'
                type='submit'
                _hover={{
                  background: `${theme.colors.white500}`,
                  opacity: 0.8,
                  color: theme.colors.primaryOrange,
                  borderColor: theme.colors.primaryOrange
                }}
              >
                Cadastrar
              </Button>
            </Box>
          </form>
          <Box margin={{base: '10px 0px', md: '30px 0px'}}>
            <div id="signInDiv"></div>
          </Box>
        </Box>
      </Box>
    </Box>

  )
}
