import {
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Grid, GridItem
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { api } from "../../services/api";
import { CityContext } from '../../Context/CityContext';
import { theme } from "../../styles/theme";
import { useLocation } from 'react-router-dom';

export function KitFilters(props) {
  const location = useLocation();
  const [wineTypes, setWineTypes] = useState([]);
  const { cityParam } = useContext(CityContext);

  const wineTypeOptions = ["Kit Casamento", "Kit Verão"]
  async function handleGetProductTypes(e, productType) {
    e.preventDefault();
    const updatedWineTypes = wineTypes.includes(productType)
      ? wineTypes.filter((type) => type !== productType)
      : [...wineTypes, productType];

    setWineTypes(updatedWineTypes);
    filterProducts(updatedWineTypes);
  }

  async function filterProducts(types) {
    let url = `/api/v1/products?city_id=${cityParam}`;

    if (types.length > 0) {
      url = `${url}&kit_types=${types.join(',')}`;
    }
    if (types.length === 0 ) {
      const urlParams = new URLSearchParams(location.search);
      const filter = urlParams.get("filter");
      url = `${url}&filter=${filter}`;
    }
    const response = await api.get(url);
    props.setProducts(response.data.products);
  }

  return (
    <>
    <Accordion allowToggle defaultIndex={window.screen.width < 762 ? [] : [0]}>
      <AccordionItem borderColor={theme.colors.primary}>
        <AccordionButton
          fontWeight='bold'
          display={{base: 'block', md: 'none'}}
        >
          FILTRAR
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={2}>
          <Grid
            templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(2, 1fr)" }}
            gap={4}
            px={7}
            margin='auto'
          >
            {wineTypeOptions.map((wineType, index) => {
              return(
                <GridItem key={index}>
                  <Button
                    width={"100%"}
                    onClick={(e) => handleGetProductTypes(e, wineType)}
                    border='1px solid #D44120'
                    borderRadius='5px'
                    _hover={{
                      transform: "scale(1.02)",
                      color: 'white',
                      bg: '#D44120'
                    }}
                    style={{
                      backgroundColor: wineTypes.includes(wineType)
                        ? '#D44120'
                        : 'transparent',
                        color: wineTypes.includes(wineType)
                        ? 'white'
                        : '#D44120',
                    }}
                  >
                    {wineType}
                  </Button>
                </GridItem>
              )
            })}
          </Grid>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
    </>
  );
}
