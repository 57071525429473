import { useEffect, useState } from "react";
import { createContext } from "react";
import { api } from "../services/api";

export const CityContext = createContext({});

export function CityContextProvider(props) {
  const [cityParam, setCityParam] = useState(1411);
  const [cityName, setCityName] = useState("Belo Horizonte");
  const [cities, setCities] = useState([]);

  async function fetchLocalities(param) {
    const response = await api.get(`/api/v1/get_localities`);
    const citiesData = response.data.cities;
    if (param !== null && citiesData.length > 0) {
      const foundCity = citiesData.find((element) => element.id === Number(param));
      if (foundCity) {
        setCityName(foundCity.name);
      } else {
        setCityName("Belo Horizonte");
      }
    }
    setCities(citiesData);
  }

  useEffect(() => {
    fetchLocalities(1411);
  }, []);

  useEffect(() => {
    fetchLocalities(cityParam);
  }, [cityParam]);

  function handleSetCity(id) {
    setCityParam(id);
    localStorage.setItem('cityId', id);
  }

  return (
    <CityContext.Provider
      value={{ cityParam, cityName, cities, handleSetCity}}
    >
      {props.children}
    </CityContext.Provider>
  );
}
