import React, { useState, useEffect } from 'react';

export function CardNumberInputMask({ maskType, placeholder, setCardNumber}) {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };


  const handleChange = (e) => {
    const inputValue = e.target.value;
    let maskedValue = '';

    switch (maskType) {
      case 'cpf':
        maskedValue = inputValue
          .replace(/\D/g, '')
          .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
          .substr(0, 14);
        break;
      case 'date':
        maskedValue = inputValue
          .replace(/\D/g, '')
          .replace(/(\d{2})(\d{2})/, '$1/$2')
          .substr(0, 5);
        break;
      case 'number':
        maskedValue = inputValue
          .replace(/\D/g, '')
          .substr(0, 3);
        break;
      case 'cardNumber':
        maskedValue = inputValue
          .replace(/\D/g, '')
          .replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4')
          .substr(0, 19);
        break;

      default:
        maskedValue = inputValue;
        break;
    }



    if (setCardNumber) {
      setCardNumber(inputValue);
    }

    setValue(maskedValue);
  };

  useEffect(() => {
    if (setCardNumber) {
      setCardNumber(value);
    }


  }, [value, setCardNumber]);

  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      id='number'
      name="number"
      data-iugu="number"
      style={{
        border: `1px solid #ff6600`,
        borderRadius: '5px',
        padding: '5px',
        fontSize: '16px',
        background: 'transparent',
        width: '100%',
        height: '40px',
        margin: '10px 0px',
        outline: 'none',
        boxShadow: 'none',
      }}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};

// export default InputMask;
