import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function PaymentMethodChart({payments}) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Métodos de pagamento',
      },
    },
  };

  const labels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const pixPayments = payments.filter(payment => payment.status === 'paid' && payment.payment_method === 'pix');
  const creditCardPayments = payments.filter(payment => payment.status === 'paid' && payment.payment_method === 'credit_card');

  function convertMonthToLabelFormat(date) {
    const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const updatedAtDate = new Date(date);
    const monthIndex = updatedAtDate.getMonth();
    return monthNames[monthIndex];
  }

  const getMonthlySum = (month) => {
    return (month.reduce((sum, order) => sum + order.price_in_cents, 0) / 100);
  };

  const dataset1Data = labels.map(labelMonth => {
    const monthlyOrders = pixPayments.filter(order => convertMonthToLabelFormat(order.created_at) === labelMonth);
    return getMonthlySum(monthlyOrders);
  });

  const dataset2Data = labels.map(labelMonth => {
    const monthlyOrders = creditCardPayments.filter(order => convertMonthToLabelFormat(order.created_at) === labelMonth);
    return getMonthlySum(monthlyOrders);
  });


  const data = {
    labels,
    datasets: [
      {
        label: 'Pix',
        data: dataset1Data,
        borderColor: 'rgb(255, 0, 0)',
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
      },
      {
        label: 'Cartão',
        data: dataset2Data,
        borderColor: 'rgb(0, 0, 255)',
        backgroundColor: 'rgba(0, 0, 255, 0.5)',
      },
    ],
  };

  return (<>
    <div  style={{ width: '100%', height: '27rem', background: '#f2f2f2', borderRadius: '15px', padding: '20px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 15px',}}>
      <Line options={options} data={data} style={{ margin: 'auto'}}/>
    </div>
  </>)
}
