import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import { ProductCard } from "../../components/ProductCard";
import { Box, Text, Container, Heading, AspectRatio, Button, Center, Flex  } from "@chakra-ui/react";
import { useAuth } from "../../Hooks/useAuth";
import { theme } from "../../styles/theme";
import { Footer } from '../../components/Footer'
import { Carousel } from '../../components/Carousel'

import { Link } from 'react-router-dom';

import { Factory, Storefront, HouseLine, Truck } from "phosphor-react";

export function MarketPath() {
  return (
    <>
      <Box
        background={theme.colors.white500}
        height={"auto"}
        padding={"3% 0"}
        my='30px'
      >
        <Box
          display={{ base: 'block', md: 'flex' }}
          justifyContent={"center"}
          padding={{ base: '0 2% 1% 2%', md: '0 18% 0% 18%' }}
          gap={"5%"}
        >
          <Box
            width={{ base: '100%', md: '50%' }}
            marginBottom={{ base: '5%', md: '' }}
            textAlign={{ base: 'center', md: '' }}
          >
            <Text
              color={theme.colors.orange}
              fontWeight={"bold"}
              fontSize={"27px"}
              lineHeight={"1"}
              marginBottom={"10px"}
            >
            Porque comprar na Boozen é mais barato?
            </Text>
            <Text
              lineHeight={"1"}
              marginBottom={"15px"}
              fontSize={{ base: '18px', md: '15px' }}
            >
              No mercado tradicional, os supermercados compram das fábricas
              e estocam para revender aos seus clientes, assumindo riscos de
              estocagem e vencimento, além de precisar manter um espaço de
              compra organizado, com caixas, gôndolas e tudo mais.
            </Text>
            <Text
              lineHeight={"1"}
              fontSize={{ base: '18px', md: '15px' }}
            >
              Tudo isso custa muito caro e quem acaba pagando somos nós,
              clientes. Para manter tudo isso, os supermercados chegam a
              cobrar de 30% a mais de 100% sobre o preço que comprou da
              fábrica, e todo
            </Text>
            <Link to='/loja'
              style={{ textDecoration: 'none' }}
            >
              <Button
                bg={theme.colors.orange}
                color={theme.colors.white}
                padding={"0 50px"}
                marginTop={"20px"}
                height={"30px"}
                _hover={{
                  bg: theme.colors.secondary,
                }}
                size={'md'}
                borderRadius={"50px"}
              >
                VEJA AS OFERTAS
              </Button>
            </Link>
          </Box>
          <Box
            background={theme.colors.primaryGreen}
            height={{ base: 'auto', md: '35vh' }}
            width={{ base: '100%', md: '60%' }}
            padding={"30px"}
            borderRadius={"15px"}
            boxShadow={"rgba(0, 0, 0, 0.8) 0px 5px 15px;"}
          >
            <Text
              color={theme.colors.secondary}
              fontWeight={"bold"}
              fontSize={"20px"}
              lineHeight={"1"}
              textAlign={"center"}
              width={"50%"}
              margin={"auto"}
            >
              Comprando a sua cerveja no mercado
            </Text>
            <Flex
              margin={"15% 0 0 0"} justifyContent={'space-between'}
            >
              <Center minWidth={"60px"} h={"60px"} background={"#FEECA5"} borderRadius={"50%"} margin={"auto"}>
                <Factory size={30} color={"#ef9207"} weight="fill"/>
              </Center>
              <hr
              style={{
                border: '3px solid #ef9207',
                width: '50%',
                margin: 'auto'
              }}
              />
              <Center minWidth={"60px"} h={"60px"} background={"#FEECA5"} borderRadius={"50%"} margin={"auto"}>
                <Truck size={30} color={"#ef9207"} weight="fill"/>
              </Center>
              <hr
              style={{
                border: '3px solid #ef9207',
                width: '50%',
                margin: 'auto'
              }}
              />
              <Center minWidth={"60px"} h={"60px"} background={"#FEECA5"} borderRadius={"50%"}>
                <Storefront size={30} color={"#ef9207"} weight="fill"/>
              </Center>
              <hr
                style={{
                  border: '3px solid #ef9207',
                  width: '50%',
                  margin: 'auto'
                }}
              />
              <Center minWidth={"60px"} h={"60px"} background={"#FEECA5"} borderRadius={"50%"} margin={"auto"}>
                <HouseLine size={30} color={"#ef9207"} weight="fill"/>
              </Center>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box
        background={theme.colors.orange}
        borderRadius='15px'
        boxShadow={"rgba(0, 0, 0, 0.4) 0px 5px 15px;"}
        height={"auto"}
        padding={"3% 0"}
        my='30px'
      >
        <Box
          display={{ base: 'block', md: 'flex' }}
          padding={{ base: '5% 2%', md: '1% 18%' }}
          justifyContent={"center"}
          gap={"5%"}
        >
          <Box
            background={theme.colors.primaryGreen}
            height={{ base: 'auto', md: '35vh' }}
            width={{ base: '100%', md: '60%' }}
            padding={"30px"}
            borderRadius={"15px"}
            boxShadow={"rgba(0, 0, 0, 0.8) 0px 5px 15px;"}
          >
            <Text
              color={theme.colors.secondary}
              fontWeight={"bold"}
              fontSize={"20px"}
              lineHeight={"1"}
              textAlign={"center"}
            >
            Comprando com a
            </Text>
            <Text
              fontSize={'28px'}
              fontWeight={'extrabold'}
              color={theme.colors.secondary}
              marginBottom={"0"}
              textAlign={"center"}

            >
              BOOZEN
            </Text>
            <Flex margin={"8% 15% 0 15%"} justifyContent={'space-between'}>
              <Center minWidth={"60px"} h={"60px"} background={"#FEECA5"} borderRadius={"50%"} margin={"auto"}>
                <Factory size={30} color={"#ef9207"} weight="fill"/>
              </Center>
              <hr
              style={{
                border: '3px solid #ef9207',
                width: '80%',
                margin: 'auto'
              }}
              />

              <Center minWidth={"60px"} h={"60px"} background={"#FEECA5"} borderRadius={"50%"} margin={"auto"}>
                <HouseLine size={30} color={"#ef9207"} weight="fill"/>
              </Center>
            </Flex>
          </Box>
          <Box
            width={{ base: '100%', md: '50%' }}
            textAlign={{ base: 'center', md: '' }}
            margin={{ base: '5% auto', md: '' }}
          >
            <Text
              color={theme.colors.primaryGreen}
              fontWeight={"bold"}
              fontSize={"27px"}
              lineHeight={"1"}
              marginBottom={"10px"}
            >
              Ficou mais fácil e barato comprar sua cerveja favorita
            </Text>
            <Text
              lineHeight={"1"}
              marginBottom={"15px"}
              fontSize={{ base: '18px', md: '15px' }}

            >
              Agora é possível que você compre direto da fábrica sem ter que
              pagar por todo esse custo de intermediação. Basta que você se
              programe com alguma antecedência e poderá ter acesso
              cotidiano a preços que quase nunca estão disponíveis para o
              consumidor.
            </Text>
            <Link to='/loja'
              style={{ textDecoration: 'none' }}
            >
              <Button
                bg={theme.colors.primaryGreen}
                color={theme.colors.white}
                padding={"0 50px"}
                marginTop={"20px"}
                height={"30px"}
                _hover={{
                  bg: theme.colors.secondary,
                }}
                size={'md'}
                borderRadius={"50px"}
              >
                VEJA AS OFERTAS
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  )
}
