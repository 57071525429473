import { Box, Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState, useReducer } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import { api } from "../../../services/api";
import swal  from 'sweetalert';


export function EditBatch({
  productId,
  productBatch,
  onUpdateBatch
}) {
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [batch, setBatch] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      stock: "",
      discount_per_centage: "",
      start_date: "",
      expiration_date: "",
      unity_of_measurement: "",

    }
  );
  const [batchId, setBatchId] = useState("");

  useEffect(() => {
    setBatch(productBatch)
  }, [])

  const handleBatchChange = (e) => {
    const { name, value } = e.target;
    setBatch({ [name]: value });
  }

  async function handleEditBatch(e) {
    e.preventDefault();
    const formData = new FormData()
    formData.append("batch[stock]", Number(batch.stock));
    formData.append("batch[discount_per_centage]", batch.discount_per_centage);
    formData.append("batch[start_date]", batch.start_date);
    formData.append("batch[expiration_date]", batch.expiration_date);
    formData.append("batch[unity_of_measurement]", batch.unity_of_measurement);
    const response = await api.put(`/api/v1/batches/${batch.id}`, formData, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
        contentType: "multipart/form-data"
      },
    })
    if (response.status === 200) {
      onUpdateBatch(response.data);
      swal({
        title: "Ohhh Yeahhh",
        text: "Dados atualizados com sucesso!",
        icon: "success",
      });
      onClose()
    } else {
      swal({
        title: "Ohhh Noo",
        text: "Algo deu errado!",
        icon: "error",
      });
    }
    onClose()
  }

  return (
    <>
      <Button onClick={onOpen}>Editar Lote</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader><Text>LOTE #{batch?.id}</Text> </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Box
                display="flex"
                gap="10px"
              >
                <Box
                  margin="20px 0"
                >
                  <Text>Quantidade de produto</Text>
                  <Input
                    placeholder={`${batch?.stock}`}
                    onChange={handleBatchChange}
                    name="stock"
                    type="number"
                  />
                </Box>
                <Box
                  margin="20px 0"
                >
                  <Text>Desconto</Text>
                  <Input
                  placeholder={`${batch?.discount_per_centage}`}
                    onChange={handleBatchChange}
                    name="discount_per_centage"
                    type="number"
                  />
                </Box>
              </Box>
              <Box>
                <Text>Data de início do lote</Text>
                <Input
                  margin="10px 0"
                  placeholder={`${batch?.start_date}`}
                  name="start_date"
                  type="date"
                  onChange={handleBatchChange}
                />
                <Text>Data de expiração do lote</Text>
                <Input
                  margin="10px 0"
                  placeholder={`${batch?.expiration_date}`}
                  name="expiration_date"
                  type="date"
                  onChange={handleBatchChange}
                />
                <Select
                  margin="10px 0"
                  placeholder="Selecionar unidade de medida"
                  type="date"
                  name="unity_of_measurement"
                  onChange={handleBatchChange}
                >
                  <option value="Caixa">Caixa</option>
                  <option value="Fardo">Fardo</option>
                  <option value="Pacote">Pacote</option>
                  <option value="Unidade">Unidade</option>
                </Select>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={onClose}
            >
              Fechar
            </Button>
            <Button
              colorScheme="green"
              onClick={(e) => handleEditBatch(e)}
            >
              Editar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
