import { createContext, useEffect, useState } from "react";
import { api } from "../services/api";
import swal  from 'sweetalert';


export const userToken = () => window.localStorage.getItem("@app/token");
export const AuthContext = createContext({});
export function AuthContextProvider(props) {
  const [user, setUser] = useState();
  const isAuthenticated = () => userToken() !== null;
  const [showCartPopUp, setShowCartPopUp] = useState(false)
  const [cartBatches, setCartBatches] = useState([]);


  // async function getUserInfo(requestBody) {
  //   if (isAuthenticated()) {
  //     try {
  //       const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];

  //       const response = await api.post('/api/v1/users/me', {
  //         guest_cart: guestCart
  //       }, {
  //         headers: {
  //           Authorization: `Bearer ${userToken()}`,
  //           uid: window.localStorage.getItem("@app/uid"),
  //           client: window.localStorage.getItem("@app/client"),
  //           "access-token": window.localStorage.getItem("@app/token"),
  //         }
  //       });
  //       // console.log(response);
  //       const user = {
  //         id: response.data.id,
  //         email: response.data.email,
  //         full_name: response.data.full_name,
  //         document_number: response.data.document_number,
  //         phone_number: response.data.phone_number,
  //         user_photo: response.data.user_photo,
  //         access: response.data.access
  //       };


  //       setUser(user);


  //       const cartBatches = response.data.created_cart_batches;
  //       if (cartBatches.lenght > 0) {
  //         setCartBatches(cartBatches)
  //         localStorage.removeItem('guestCart');
  //       }

  //       localStorage.setItem("userPhoto", response.data.user_photo)
  //       localStorage.setItem("userId", response.data.id)

  //     } catch {
  //       signOutUser();
  //     }
  //   }
  // }

  async function getUserInfo(requestBody) {
    if (isAuthenticated()) {
      try {
        const response = await api.get("/api/v1/users/me", {
          headers: {
            Authorization: `Bearer ${userToken()}`,
            uid: window.localStorage.getItem("@app/uid"),
            client: window.localStorage.getItem("@app/client"),
            "access-token": window.localStorage.getItem("@app/token"),
            "Cross-Origin-Opener-Policy": "same-origin-allow-popups",
          }
        });
        const user = response.data;
        setUser(user);
        localStorage.setItem("userPhoto", response.data.user_photo)
        localStorage.setItem("userId", response.data.id)

      } catch {
        signOutUser();
      }
    }
  }

  useEffect(() => {
    getUserInfo();
  }, []);

  async function signInUser(email, password) {
    const requestBody = {
      email,
      password
    };
    try {
      const response = await api.post("/api/v1/auth/sign_in", requestBody);
      if (response) {
        window.localStorage.setItem("@app/token", response.headers["access-token"]);
        window.localStorage.setItem("@app/uid", response.headers["uid"]);
        window.localStorage.setItem("@app/client", response.headers["client"]);
        window.localStorage.setItem("@app/expiry", response.headers["expiry"]);
        await getUserInfo(requestBody);
        window.location.href = "/carrinho";
      } else {
        // alert("Ocorreu um erro no seu login");
        swal({
          title: "Ops",
          text: "Ocorreu um erro no seu login, confira seus dados",
          icon: "error",
        });
      }
    } catch {
      // alert("Ocorreu um erro no seu login");
      swal({
        title: "Ops",
        text: "Ocorreu um erro no seu login, confira seus dados",
        icon: "error",
      });
    }
  }

  async function signUpUser(email, password, full_name, document_number, phone_number) {
    const requestBody = { email, password, full_name, document_number, phone_number };
    try {
      const response = await api.post("/api/v1/auth", requestBody);

      if (response) {
        window.localStorage.setItem(
          "@app/token",
          response.headers["access-token"]
        );
        window.localStorage.setItem("@app/uid", response.headers["uid"]);
        window.localStorage.setItem("@app/client", response.headers["client"]);
        window.localStorage.setItem("@app/expiry", response.headers["expiry"]);

        await getUserInfo();
        // window.location.href = "/loja";
        if (typeof window !== "undefined") {
          if (window.fbq != null) {
            window.fbq('trackCustom', 'signUpUser');
          }
        }
      } else {
        alert("Ocorreu um erro no seu cadastro");
      }
    } catch (e) {
      swal({
        title: "Ops",
        text: "Confira os dados de cadastro, devem ser válidos",
        icon: "error",
      });

    }
  }

  async function signOutUser() {
    setUser(null);
    window.localStorage.removeItem("@app/token");
    window.localStorage.removeItem("@app/uid");
    window.localStorage.removeItem("@app/client");
    window.localStorage.removeItem("@app/expiry");
    localStorage.removeItem('userId');
    window.location = "/"
  }

  return (
    <AuthContext.Provider
      value={{ user, signInUser, isAuthenticated, signOutUser, signUpUser, setUser, getUserInfo }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
