import { Box, Button, Input, Text, Checkbox } from "@chakra-ui/react";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { PlusCircle } from 'phosphor-react'
import { api } from "../../../services/api";
import { useAuth } from "../../../Hooks/useAuth";
import swal  from 'sweetalert';
import { theme } from "../../../styles/theme";
import { FaPlus, FaMinus  } from "react-icons/fa";

export function BatchCardInfo({
  batch,
  productId,
  prices,
}) {
  const { user } = useAuth();
  const [isUserAddingPrices, setIsUserAddingPrices] = useState(false);
  const [batchNewPrices, setBatchNewPrices] = useState([]);
  const [pricePerUnity, setPricePerUnity] = useState(0);
  const [batchUnity, setBatchUnity] = useState(0);
  const [batchPrices, setBatchPrices] = useState([])
  const [editPriceSection, setEditPriceSection] = useState(false);
  const [usePreviousPrices, setUsePreviousPrices] = useState(false);
  const [previousPrices, setPreviousPrices] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);

  function handleAddNewPrices(e) {
    e.preventDefault();
    const data = {
      batch_id: batch.id,
      price_per_unity: pricePerUnity,
      unity: batchUnity
    }
    setBatchNewPrices([...batchNewPrices, data])
  }

  useEffect(() => {
    if (!dataFetched) {
      handleFetchCurrentPrices();
      setDataFetched(true);
    }
  }, [dataFetched]);

  async function handleFetchCurrentPrices() {
    const apiUrl = `/api/v1/admin/panels/get_current_prices?batch_id=${batch?.id}`;
    try {
      const response = await api.get(apiUrl, {
        headers: {
          "access-token": user?.token,
          client: user?.client,
          uid: user?.uid,
          accept: "application/json",
          contentType: "multipart/form-data"
        },
      });

      if (response.data.prices) {
        setBatchPrices(response.data.prices);
      } else {
        swal({
          title: "Ok",
          text: "Nenhum preço encontrado para o lote",
          icon: "warning",
        });
      }
    } catch (error) {
      console.error("Error fetching batch orders:", error);
    }
  }

  function handlePricesPerBatch() {
    return (
      batchNewPrices.map((price, index) => (
        <Box key={price.id}>
          <Text>Preço por unidade: {price.price_per_unity}</Text>
          <Text>Unidades pedidas: {price.unity}</Text>
        </Box>
      ))
    )
  }

  async function handleRegisterPrices(e) {
    e.preventDefault();
    if (batchNewPrices.length === 0 ) {
      swal({
        title: "Ok",
        text: "Deve conter pelo menos 1 preço. Adicione um valor antes de cadastrar, (clique no +)",
        icon: "warning",
      });
    }
    const data = {
      batchPrices: batchNewPrices
    }
    if (data.batchPrices.length === 0) {
      return;
    }
    const response = await api.post(`api/v1/prices`, data, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
      },
    })
    if (response.status === 200) {
      setBatchPrices(response.data.batch_prices)
      setBatchNewPrices([])
      setIsUserAddingPrices(false)
      setUsePreviousPrices(false)
      swal({
        title: "Ok",
        text: "Preços Adicionado",
        icon: "success",
      });
    } else {
      swal({
        title: "Opss",
        text: "Algo deu errado!!",
        icon: "error",
      });
    }
  }

  async function handleEditPrice(e, priceId) {
    e.preventDefault();
    const price = batchPrices.find(price => price.id === Number(e.target.id))
    const data = {
      price_per_unity: pricePerUnity === 0 ? price.price_per_unity : pricePerUnity,
      unity: batchUnity === 0 ? price.unity : batchUnity,
    }
    const response = await api.put(`api/v1/prices/${price.id}`, data, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
      },
    })
    if (response.status === 200){
      const updatedPrices = batchPrices.map((price) =>
        price.id === response.data.id ? response.data : price
      );
      setBatchPrices(updatedPrices);
    }
    const priceSectionToEdit = document.getElementById(`price-${e.target.id}`)
    if (priceSectionToEdit.style.display === "none") {
      priceSectionToEdit.style.display = "block";
    } else {
      priceSectionToEdit.style.display = "none";
    }
  }

  async function handleRemovePrice(e, priceId) {
    e.preventDefault();
    const price = batchPrices.find(price => price.id === priceId)
    const response = await api.delete(`/api/v1/prices/${priceId}`);
    if (response.status === 204) {
      const indexToRemove = batchPrices.findIndex((price) => price.id === priceId);
      if (indexToRemove !== -1) {
        const updatedPrices = [...batchPrices];
        updatedPrices.splice(indexToRemove, 1);
        setBatchPrices(updatedPrices);
        swal({
          title: "Ok",
          text: "Preço removido",
          icon: "success",
        });
      } else {
        swal({
          title: "Opss",
          text: "Preço não encontrado na lista",
          icon: "error",
        });
      }
    } else {
      swal({
        title: "Opss",
        text: "Algo deu errado!!",
        icon: "error",
      });
    }
  }

  async function handleUsePreviousPrices(e) {
    e.preventDefault();

    if (e.target.checked) {
      const response = await api.get(`/api/v1/admin/panels/get_previous_prices_for_batch?product_id=${productId}`, {
        headers: {
          "access-token": user.token,
          client: user.client,
          uid: user.uid,
          accept: "application/json",
        },
      })
      setUsePreviousPrices(true)
      if (response.data.prices ) {
        setPreviousPrices(response.data.prices)
      } else {
        alert('Nenhum preço encontrado.')
      }
    } else {
      setUsePreviousPrices(false)
    }
  }

  async function handleSubmitPreviousPrices(e) {
    e.preventDefault();
    const newBatchPrices = previousPrices.map((price) => ({
      batch_id: batch.id,
      price_per_unity: price.price_per_unity,
      unity: price.unity,
    }));
    if (newBatchPrices.length === 0) {
      return;
    }
    const data = {batchPrices: newBatchPrices}
    const response = await api.post(`api/v1/prices`, data, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
      },
    })
    if (response.status === 200) {
      setBatchPrices((batchPrices) => [...batchPrices, ...response.data.batch_prices])
      setBatchNewPrices([])
      setIsUserAddingPrices(false)
      setUsePreviousPrices(false)
      document.getElementById('previous-prices').removeAttribute('checked');

      swal({
        title: "Ok",
        text: "Preços Adicionado",
        icon: "success",
      });
    } else {
      swal({
        title: "Opss",
        text: "Algo deu errado!!",
        icon: "error",
      });
    }
  }


  const handleToggleEditPriceSection = (e) => {
    const priceSectionToEdit = document.getElementById(`price-${e.target.value}`)
    if (priceSectionToEdit.style.display === "none") {
      priceSectionToEdit.style.display = "block";
    } else {
      priceSectionToEdit.style.display = "none";
    }
  }

  useEffect(() => {
    handlePricesPerBatch();
  }, [batchNewPrices])

  return (
    <Box>
      <Text>Código do lote: {batch.id}</Text>
      <Text>Produto: {productId}</Text>
      <Text>Quantidade de produtos: {batch.stock}</Text>
      <Text>Desconto: {batch.discount_per_centage}% </Text>
      <Text>Início do lote: {format(new Date(batch.start_date), 'dd-MM-yyyy HH:mm:ss') }</Text>
      <Text>Término do lote: {format(new Date(batch.expiration_date), 'dd-MM-yyyy HH:mm:ss')}</Text>

      <Box>
        <Text fontWeight="bold">Preços do Lote</Text>
        {batchPrices?.map((price) => {
          return (<>
            <Box key={price.id} display="flex"  justifyContent='space-between'>
              <Box display="flex">
                <Text marginEnd="10px">
                  {price.unity} {batch.unity_of_measurement}{price.unity === 1 ? "" : "s" }:
                </Text>
                <Text>
                  {price.price_per_unity?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}/unidade
                </Text>
              </Box>
              <Button type="button" value={price.id} onClick={(e) => handleToggleEditPriceSection(e)} background="transparent" height="20px">Editar</Button>
              <Button id={price.id} color='red' background="transparent" height="20px" onClick={(e) => handleRemovePrice(e, price.id)}>
                Remover
              </Button>
            </Box>
            <Box id={`price-${price.id}`} display="none" marginBottom="20px">
              <Box display="flex">
                <Input
                  margin="0"
                  placeholder={`${price.unity}`}
                  onChange={(e) => setBatchUnity(e.target.value)}
                  height="20px"
                  type="number"
                />
                <Input
                  margin="0"
                  placeholder={`${price.price_per_unity}`}
                  onChange={(e) => setPricePerUnity(e.target.value)}
                  height="20px"
                />
                <Button id={price.id} background="transparent" height="20px" onClick={(e) => handleEditPrice(e, price.id)}>
                  Editar
                </Button>
              </Box>
            </Box>
          </>)
        })}
      </Box>
      {
        (batchPrices.lenght >= 0 || batchPrices.length < 50) && (
          <Box display='flex' justifyContent='space-between'>
            <Button
              margin="10px 0"
              width="25%"
              bg='transparent'
              onClick={(e) => setIsUserAddingPrices(!isUserAddingPrices)}
              _hover={{
                bg: 'transparent',
                color: `${theme.colors.primary}`
              }}
            >
              Novos preços
              {isUserAddingPrices ? (
                <FaMinus></FaMinus>
              ) : (
                <FaPlus></FaPlus>
              )}
            </Button>
            <Checkbox
              colorScheme='green'
              value="collect"
              onChange={(e) => handleUsePreviousPrices(e)}
              id='previous-prices'
            >
              Usar preços lote anterior
            </Checkbox>
          </Box>
        )
      }
      <Box>
        {usePreviousPrices && (
          <Box padding='5px 20px 20px 20px' border='1px solid red' borderRadius='15px'>
            <Text fontWeight='400' fontSize='18px' marginBottom='10px'>Preços do lote anterior:</Text>
            {previousPrices.map((price) => (
              <Text key={price.id} marginBottom='5px' borderBottom='1px solid red'>
                Até {price.unity} {batch.unity_of_measurement}
                {price.unity > 1 ? "s" : ""} - Preço p/ Unidade: R${price.price_per_unity}
              </Text>
            ))}
            <Button
              onClick={(e) => handleSubmitPreviousPrices(e)}
              bg='transparent'
              color='green'
              _hover={{
                bg: 'green',
                color: 'white'
              }}
              border='1px solid green'
              marginTop='15px'
            >
              Usar preços</Button>
          </Box>
        )}
      </Box>
      <Box>
        {isUserAddingPrices && (
          <Box padding='5px 20px 20px 20px' border='1px solid red' borderRadius='15px' marginTop='20px'>
            <Box  display='flex' justifyContent='start' gap={2}>
              <Text fontWeight='400' fontSize='18px'>Novos preços:</Text>
              <Text fontWeight='400' fontSize='18px'> ({batchNewPrices.length} preço{batchNewPrices.length > 1 ? 's' : ''} adicionado{batchNewPrices.length > 1 ? 's' : ''})</Text>
            </Box>
            {batchNewPrices.length === 0 && (
             <Text fontSize='10px' color='red' marginBottom='10px'>Deve conter pelo menos 1 preço*</Text>
            )}
            <Box>
              {handlePricesPerBatch()}
            </Box>
            <Box>
              <Input
                margin="10px 0"
                placeholder="Preço por unidade"
                onChange={(e) => setPricePerUnity(e.target.value)}
              />
              <Input
                margin="10px 0"
                placeholder="Unidades pedidas"
                onChange={(e) => setBatchUnity(e.target.value)}
                type="number"
              />
              <Box
                display="flex"
                gap={8}
                justifyContent='space-between'
              >
                <Button
                  margin="10px 0"
                  width="auto"
                  onClick={(e) => handleAddNewPrices(e)}
                  bg='transparent'
                  color='gray'
                  padding='0px'
                  _hover={{
                    bg: 'transparent',
                    color: 'green'
                  }}
                >
                  <PlusCircle size={32} weight="fill" />
                </Button>
                <Button
                  bg='transparent'
                  color='green'
                  _hover={{
                    bg: 'green',
                    color: 'white'
                  }}
                  border='1px solid green'
                  marginTop='15px'
                  w='50%'
                  onClick={(e) => handleRegisterPrices(e)}
                >
                  Cadastrar preços({batchNewPrices.length})
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
