import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { useEffect, useState, useReducer } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import { api } from "../../../services/api";
import { theme } from "../../../styles/theme";
import swal  from 'sweetalert';

export function EditCoupon({ couponId, onUpdateCoupon, thisCoupon, handleFetchCoupons }) {
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [coupon, setCoupon] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      redemption_limit: "",
      valid_until: "",
      kind: "",
      code: "",
      discount: "",

    }
  );


  useEffect(() => {
    setCoupon(thisCoupon);
  }, [thisCoupon])

  const handleCouponChange = (e) => {
    const { name, value } = e.target;
    setCoupon({ [name]: value });
  }

  async function handleEditCoupon(e) {
    e.preventDefault();
    const formData = new FormData()
    formData.append("coupon[redemption_limit]", coupon.redemption_limit);
    formData.append("coupon[valid_until]", coupon.valid_until);
    formData.append("coupon[kind]", coupon.kind);
    formData.append("coupon[code]", coupon.code);
    formData.append("coupon[discount]", coupon.discount);
    const response = await api.put(`/api/v1/admin/coupons/${couponId}`, formData, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
        contentType: "multipart/form-data"
      },
    })
    if (response.status === 200) {
      handleFetchCoupons()
      swal({
        title: "Ohhh Yeahhh",
        text: "Dados atualizados com sucesso!",
        icon: "success",
      });
      onClose()
    } else {
      swal({
        title: "Ohhh Noo",
        text: "Algo deu errado!",
        icon: "error",
      });
    }
    onClose()
  }

  return (
    <>
      <Button onClick={onOpen}>Editar</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{coupon.code} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            <Box
            margin="20px 0"
          >
            <Input
              placeholder={coupon?.code}
              onChange={(e) => handleCouponChange(e)}
              name="code"
            />
          </Box>

          <Box
            display="flex"
            gap={8}
            margin="20px 0"
          >
            <Input
              placeholder={coupon?.valid_until}
              onChange={(e) => handleCouponChange(e)}
              name="valid_until"
              type='datetime-local'
            />
            <Input
              placeholder="Quantidade de vezes que pode ser usado"
              type={"number"}
              onChange={(e) => handleCouponChange(e)}
              name="redemption_limit"
            />
          </Box>
          <Box display="flex" gap={8} margin="20px 0">
            <Select
              placeholder={coupon?.kind}
              onChange={(e) => handleCouponChange(e)}
              name="kind"
            >
              <option value=''>
              </option>
              <option value='fixed'>
                Fixo
              </option>
              <option value='percentage'>
                Porcentagem
              </option>
            </Select>
            <Input
              placeholder={coupon?.discount}
              onChange={(e) => handleCouponChange(e)}
              name="discount"
              type={"number"}
            />
          </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={onClose}
            >
              Fechar
            </Button>
            <Button
              colorScheme="green"
              onClick={(e) => handleEditCoupon(e)}
            >
              Editar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
