import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { useReducer } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import { api } from "../../../services/api";
import swal  from 'sweetalert';

export function EditSubcategory({ subcategoryId, onUpdateSubcategory }) {
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [subcategory, setSubcategory] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
    }
  );

  const handleSubcategoryChange = (e) => {
    const { name, value } = e.target;
    setSubcategory({ [name]: value });
  }

  async function handleEditSubcategory(e) {
    e.preventDefault();
    const formData = new FormData()
    formData.append("subcategory[name]", subcategory.name);
    const response = await api.put(`/api/v1/subcategories/${subcategoryId}`, formData, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
        contentType: "multipart/form-data"
      },
    })
    if (response.status === 200) {
      onUpdateSubcategory(subcategoryId, response.data.name);
      swal({
        title: "Ohhh Yeahhh",
        text: "Dados atualizados com sucesso!",
        icon: "success",
      });
      onClose()
    } else {
      swal({
        title: "Ohhh Noo",
        text: "Algo deu errado!",
        icon: "error",
      });
    }
    onClose()
  }


  return (
    <>
      <Button onClick={onOpen}>Editar</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{subcategory.name} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Box
                margin="20px 0"
              >
                <Text>Nome</Text>
                <Input
                  placeholder={`${subcategory?.name}`}
                  onChange={handleSubcategoryChange}
                  name="name"
                />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={onClose}
            >
              Fechar
            </Button>
            <Button
              colorScheme="green"
              onClick={(e) => handleEditSubcategory(e)}
            >
              Editar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
