import { Box, Text, Divider } from "@chakra-ui/react";
import { theme } from "../styles/theme";

function setFinalPriceForOrder(cartBatches, deliveryMethod, useCredit, userCredits, paymentMethod, couponDiscount, couponKind, products) {
  let totalItemsValue = cartBatches.reduce((n, { amount }) => n + amount, 0);
  let deliveryFee = 0;
  let creditDiscount = 0;
  let couponDiscountAmount = 0
  console.log(cartBatches);
  console.log(totalItemsValue);



  if (cartBatches.length > 0 && useCredit) {
    creditDiscount = userCredits.reduce((n, { amount }) => n + amount, 0);
  }

  const targetBrands = ["Xa de Cana", "StoneLight", "Drink Pronto ", "Ice Guella", "Whitte Rabbit", "Xangolião", "Lambe Lambe", "Off Bebidas"];

  const productsWithTargetBrands = products.filter(product => targetBrands.includes(product.brand));
  const productsWithoutTargetBrands = products.filter(product => !targetBrands.includes(product.brand));

  const hasProductWithTargetBrands = productsWithTargetBrands.length > 0;
  const hasProductWithoutTargetBrands = productsWithoutTargetBrands.length > 0;

  if (totalItemsValue.toFixed(2) < 120.00 && deliveryMethod === 'delivery') {
    deliveryFee = 6.90;
  } else if (totalItemsValue.toFixed(2) < 120.00 && deliveryMethod === 'delivery-express' && hasProductWithTargetBrands && hasProductWithoutTargetBrands) {
    deliveryFee = 18.80;
  } else if (totalItemsValue.toFixed(2) < 120.00 && deliveryMethod === 'delivery-express' && hasProductWithTargetBrands && !hasProductWithoutTargetBrands) {
    deliveryFee = 11.90;
  }

  if (couponDiscount > 0 && couponKind === 'fixed') {
    couponDiscountAmount = couponDiscount
  } else if (couponDiscount > 0 && couponKind === 'percentage') {
    couponDiscountAmount = (totalItemsValue * couponDiscount) / 100;
  }


  const total = totalItemsValue + deliveryFee  - creditDiscount - couponDiscountAmount;
  return (<Box>
    <Box display='flex' justifyContent='space-between'>
      <Text>Itens:</Text>
      <Text>{totalItemsValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
    </Box>
    <Box display='flex' justifyContent='space-between'>
      <Text>Taxa de Entrega: </Text>
      <Text>{deliveryFee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
    </Box>
    <Box display='flex' justifyContent='space-between'>
      <Text>Créditos:</Text>
      <Text>- {creditDiscount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
    </Box>
    <Box display='flex' justifyContent='space-between'>
      <Text>Desconto (sobre itens):</Text>
      <Text>- {couponDiscountAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} {(couponKind === 'percentage' && couponDiscount > 0 )? `(${couponDiscount}%)` : ''}</Text>
    </Box>
    <Divider orientation='horizontal' borderColor={theme.colors.primary} borderBottomWidth='2px' margin='10px auto'/>
    <Box display='flex' justifyContent='space-between'>
      <Text fontWeight={"bold"}>Total:</Text>
      <Text>{total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
    </Box>
  </Box>)

}

export { setFinalPriceForOrder };
