import {
  Box,
  Button,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th, Td,
  Tbody,
  FormControl,
  FormLabel,
  Select,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import { api } from "../../../services/api";
import { Logistics } from "../Logistics";


export function Sales() {
  const { user } = useAuth();
  const [allOrders, setAllOrders] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [batches, setBatches] = useState([]);
  const [products, setProducts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [showPaidOrders, setShowPaidOrders] = useState(false);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const yearParam = parseInt(selectedYear, 10);
    const monthParam = parseInt(selectedMonth, 10);
    handleFetchAllPaidOrders(yearParam, monthParam);
  };

  async function handleFetchAllPaidOrders(year, month) {
    const apiUrl = `api/v1/batch_orders?year=${year}&month=${month}`;
    try {
      const response = await api.get(apiUrl);
      setAllOrders(response.data.batch_orders);
      setBatches(response.data.batches);
      setProducts(response.data.products);
      setPayments(response.data.payments);
    } catch (error) {
      console.error("Error fetching batch orders:", error);
    }
  }

  async function handleFetchAllUsers() {
    const response = await api.get("api/v1/users")
    setAllUsers(response.data)
  }

  useEffect(() => {
    handleFetchAllPaidOrders();
    handleFetchAllUsers();
  }, [])

  const translateWord = (word) => {
    const languageMap = {
      "pending" : "Pendente",
      "out_for_delivery" : "Saiu Para Entrega",
      "delivered" : "Entregue",
      "collected" : "Coletado",
      "cancelled" : "Cancelado"

    };
    return languageMap[word]
  }

  const generateYearOptions = (minYear, maxYear) => {
    const options = [];
    for (let year = maxYear; year >= minYear; year--) {
      options.push(year.toString());
    }
    return options;
  };

  function formatDateDifference() {
    const createdDateObject = new Date(2023, 8, 15);
    const currentDateObject = new Date();
    const monthNames = [
      "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
      "Jul", "Ago", "Set", "Out", "Nov", "Dez"
    ];
    const monthCreated = monthNames[createdDateObject.getUTCMonth()];
    const yearCreated = createdDateObject.getUTCFullYear().toString().substring(2);
    const monthCurrent = monthNames[currentDateObject.getUTCMonth()];
    const yearCurrent = currentDateObject.getUTCFullYear().toString().substring(2);
    const formattedCreatedDate = `${monthCreated} ${yearCreated}`;
    const formattedCurrentDate = `${monthCurrent} ${yearCurrent}`;
    return (
      <Text>{formattedCreatedDate} - {formattedCurrentDate}</Text>
    )
  }

  const handleOrdersToShow = async (e) => {
    e.preventDefault()
    setShowPaidOrders(!showPaidOrders)
    if (showPaidOrders) {
      setAllOrders(allOrders.filter(e => e.paid === true))
    } else {
      await handleFetchAllPaidOrders(parseInt(selectedYear, 10), parseInt(selectedMonth, 10));
    }
  }

  const updateOrderStatus = (orderId, newStatus) => {
    const updatedOrders = allOrders.map((order) =>
      order.id === orderId ? { ...order, delivery_status: newStatus } : order
    );
    setAllOrders(updatedOrders);
  };


  const minYear = 2023;
  const maxYear = new Date().getFullYear();

  const displayPaymentStatusAndDate = (order) => {
    const payment = payments.find(payment => payment.id === order.payment_id);

    let date = new Date(payment?.invoice_paid_at)
    return (
      <Td>
        {order.paid ? (
          payment ? (
            <span>Pago ({date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()} | {date.getHours()}:{date.getMinutes()})</span>
          ) : (
            <span>Data de pagamento inválida</span>
          )
        ) : (
          <span>Em Aberto</span>
        )}
      </Td>
    );
  }

  return (
    <Box>
      <Text textAlign="center" fontSize="26px">
        Relatório de vendas
      </Text>
      <Box display={{base: 'block' , md: 'flex'}} justifyContent='space-around' width={{base: '100%' , md: '50%'}} gap='1rem' margin='10px auto 50px auto'>
        <Box flex='1' borderRadius='15px' padding='10px 50px' bg='#f2f2f2' boxShadow='rgba(0, 0, 0, 0.25) 0px 5px 15px'>
          <Stat>
            <StatLabel> Pedidos pagos: {allOrders.filter(element => element.paid === true ).length}</StatLabel>
            <StatNumber>{allOrders.filter(element => element.paid === true ).reduce((total, order) => total + order.amount, 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</StatNumber>
            <StatHelpText>{formatDateDifference()}</StatHelpText>
          </Stat>
        </Box>
        <Box flex='1' borderRadius='15px' padding='10px 50px' bg='#f2f2f2' boxShadow='rgba(0, 0, 0, 0.25) 0px 5px 15px' marginTop={{base: '20px', md: '0px'}}>
          <Stat>
            <StatLabel>Pedidos em aberto: {allOrders.filter(element => element.paid === false ).length}</StatLabel>
            <StatNumber> {allOrders.filter(element => element.paid === false ).reduce((total, order) => total + order.amount, 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</StatNumber>
            <StatHelpText>{formatDateDifference()}</StatHelpText>
          </Stat>
        </Box>
      </Box>
      <Box marginTop='30px'>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Box display='flex' gap='5px' alignItems='end' justifyContent='center'>
            <FormControl width='30%'>
              <FormLabel>Mês</FormLabel>
              <Select value={selectedMonth} onChange={handleMonthChange}>
                <option value="">Escolha mês referência</option>
                <option value="01">Janeiro</option>
                <option value="02">Fevereiro</option>
                <option value="03">Março</option>
                <option value="04">Abril</option>
                <option value="05">Maio</option>
                <option value="06">Junho</option>
                <option value="07">Julho</option>
                <option value="08">Agosto</option>
                <option value="09">Setembro</option>
                <option value="10">Outubro</option>
                <option value="11">Novembro</option>
                <option value="12">Dezembro</option>
              </Select>
            </FormControl>
            <FormControl width='30%'>
              <FormLabel>Ano</FormLabel>
              <Select value={selectedYear} onChange={handleYearChange}>
                <option value="">Escolha ano referência</option>
                {generateYearOptions(minYear, maxYear).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Button type="submit">Filtrar data</Button>
          </Box>
        </form>
        <Button onClick={(e) => handleOrdersToShow(e)}>
          {showPaidOrders ? "Mostrar todos os pedidos" : "Mostrar pedidos pagos"}
        </Button>
      </Box>


      <TableContainer marginTop="100px">
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>#Id</Th>
              <Th>Valor</Th>
              <Th>Pagamento</Th>
              <Th>Quantidade</Th>
              <Th>Pagamento(#id)</Th>
              <Th>Cliente</Th>
              <Th>Produto</Th>
              <Th>Logística</Th>
              <Th>Status</Th>

            </Tr>
          </Thead>
          <Tbody>
            {allOrders?.map((order) => {
              return (
                <Tr
                  key={order.id}
                  background={
                    order.delivery_status === 'cancelled'
                      ? 'gray.100'
                      : order.paid
                      ? 'green.100' // Change this to the color you desire for cancelled orders
                      : 'red.100'
                  }
                >
                  <Td>{order.id}</Td>
                  <Td>{order.amount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Td>
                  {displayPaymentStatusAndDate(order)}
                  <Td>{order.quantity} - {batches.find(element => element.id === order.batch_id)?.unity_of_measurement}</Td>
                  <Td>{order.payment_id}</Td>
                  <Td>{allUsers.find(element => element.id === order.user_id)?.full_name}</Td>
                  <Td>{products.find(element => element.id === batches.find(element => element.id === order.batch_id)?.product_id)?.name}</Td>

                  {order.delivery_method === "delivery" && (<>
                    <Td>Entrega</Td>
                    {order.paid ? (
                      <>
                        {order.delivery_status === 'pending' && (
                          <Td fontWeight='bold' color='red'>{translateWord(order.delivery_status)}</Td>
                        )}
                        {order.delivery_status === 'out_for_delivery' && (
                          <Td fontWeight='bold' color='blue'>{translateWord(order.delivery_status)}</Td>
                        )}
                        {order.delivery_status === 'delivered' && (
                          <Td fontWeight='bold' color='green'>{translateWord(order.delivery_status)}</Td>
                        )}
                        {order.delivery_status === 'cancelled' && (
                          <Td fontWeight='bold' color='red'>{translateWord(order.delivery_status)}</Td>
                        )}
                      </>
                    ) : (
                      <Td fontWeight='bold'>Aguardando pagamento</Td>
                    )}
                  </>)}

                  {order.delivery_method === "delivery-express" && (<>
                    <Td>Entrega EXPRESS</Td>
                    {order.paid ? (
                      <>
                        {order.delivery_status === 'pending' && (
                          <Td fontWeight='bold' color='red'>{translateWord(order.delivery_status)}</Td>
                        )}
                        {order.delivery_status === 'out_for_delivery' && (
                          <Td fontWeight='bold' color='blue'>{translateWord(order.delivery_status)}</Td>
                        )}
                        {order.delivery_status === 'delivered' && (
                          <Td fontWeight='bold' color='green'>{translateWord(order.delivery_status)}</Td>
                        )}
                        {order.delivery_status === 'cancelled' && (
                          <Td fontWeight='bold' color='red'>{translateWord(order.delivery_status)}</Td>
                        )}
                      </>
                    ) : (
                      <Td fontWeight='bold'>Aguardando pagamento</Td>
                    )}
                  </>)}
                  {order.delivery_method === "collect" && (<>
                    <Td>Coleta</Td>
                    {order.paid ?
                      (
                        <Td fontWeight='bold' color={order.delivery_status === 'collected' ? 'green' : 'red'}>{translateWord(order.delivery_status)}</Td>
                      )
                      :
                      (
                        <Td fontWeight='bold'>Aguardando pagamento</Td>
                      )
                    }
                  </>)}
                  <Td>
                    <Logistics
                      orderId={order.id}
                      updateOrderStatus={updateOrderStatus}
                      deliveryStatus={order.delivery_status}
                    />
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}
