function calculateDeliveryPeriod(batchId, product, deliveryMethod, order) {
  if (deliveryMethod === "delivery") {
    let date;
    if (order && order.paid) {
      date = new Date(order.updated_at);
    } else {
      date = new Date();
    }

    const businessDaysToAdd = (date, days) => {
      const result = new Date(date);
      while (days > 0) {
        result.setDate(result.getDate() + 1);
        if (result.getDay() !== 0 && result.getDay() !== 6) {
          days--;
        }
      }
      return result;
    };
    const expirationPlus2BusinessDays = businessDaysToAdd(date, 2);
    const expirationPlus8BusinessDays = businessDaysToAdd(date, 8);
    return `Prazo de entrega: ${expirationPlus2BusinessDays.toLocaleDateString('pt-BR')} - ${expirationPlus8BusinessDays.toLocaleDateString('pt-BR')}`;
  } else if (deliveryMethod === "delivery-express" && (product.brand === "Xa de Cana" || product.brand === "StoneLight" || product.brand === "Drink Pronto " || product.brand === "Ice Guella" || product.brand === "Whitte Rabbit" || product.brand === "Xangolião" || product.brand === "Lambe Lambe" || product.brand === "Off Bebidas")) {
    let date;
    if (order && order.paid) {
      date = new Date(order.updated_at);
    } else {
      date = new Date();
    }

    const thisThursday = new Date();
    const thisFriday = new Date();
    const desiredDayOfWeekForThursday = 4;
    const desiredDayOfWeekForFriday = 5;
    const daysToAddForThursday = desiredDayOfWeekForThursday - thisThursday.getDay();
    const daysToAddForFriday = desiredDayOfWeekForFriday - thisFriday.getDay();
    thisThursday.setDate(thisThursday.getDate() + daysToAddForThursday);
    thisFriday.setDate(thisFriday.getDate() + daysToAddForFriday);

    const businessDaysToAdd = (date, days) => {
      const result = new Date(date);
      while (days > 0) {
        result.setDate(result.getDate() + 1);
        if (result.getDay() !== 0 && result.getDay() !== 6) {
          days--;
        }
      }
      return result;
    };
    const now = new Date();
    const thursdayTenPm = new Date(thisThursday);
    thursdayTenPm.setHours(22, 0, 0, 0);
    const nextFriday = new Date(thisFriday);
    nextFriday.setDate(thisFriday.getDate() + 7);
    const nextThursday = new Date(thisThursday);
    nextThursday.setDate(thisThursday.getDate() + 7);

    const dayOfWeek = now.getDay();
    const currentHour = now.getHours();
    const tomorrow = new Date(now);
    tomorrow.setDate(now.getDate() + 1);

    if (dayOfWeek >= 3 && dayOfWeek <= 5 && currentHour >= 12 && currentHour < 20) {
      if (order && order.paid) {
        return `Prazo de entrega: ${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`;
      } else {
        return `Prazo de entrega: ${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} | Para pedidos feitos até ${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} - 20h.`;
      }
    } else if (dayOfWeek === 6 && currentHour >= 10 && currentHour < 16) {
      if (order && order.paid) {
        return `Prazo de entrega: ${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`;
      } else {
        return `Prazo de entrega: ${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} | Para pedidos feitos até ${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()} - 16h.`;
      }
    }
  }
  // } else if (deliveryMethod === "delivery-express" && !(product.brand === "Xa de Cana" || product.brand === "StoneLight" || product.brand === "Drink Pronto " || product.brand === "Ice Guella" || product.brand === "Whitte Rabbit" || product.brand === "Xangolião" || product.brand === "Lambe Lambe")) {
  //   console.log(product)

  //   let date;
  //   if (order && order.paid) {
  //     date = new Date(order.updated_at);
  //   } else {
  //     date = new Date();
  //   }

  //   const businessDaysToAdd = (date, days) => {
  //     const result = new Date(date);
  //     while (days > 0) {
  //       result.setDate(result.getDate() + 1);
  //       if (result.getDay() !== 0 && result.getDay() !== 6) {
  //         days--;
  //       }
  //     }
  //     return result;
  //   };
  //   const expirationPlus2BusinessDays = businessDaysToAdd(date, 2);
  //   const expirationPlus8BusinessDays = businessDaysToAdd(date, 8);
  //   return `Prazo de entrega: ${expirationPlus2BusinessDays.toLocaleDateString('pt-BR')} - ${expirationPlus8BusinessDays.toLocaleDateString('pt-BR')}`;
  // }
  else if (deliveryMethod === "collect") {
    let date;
    if (order && order.paid) {
        date = new Date(order.updated_at);
    } else {
        date = new Date();
    }
    const businessDaysToAdd = (date, days) => {
      const result = new Date(date);
      while (days > 0) {
        result.setDate(result.getDate() + 1);
        if (result.getDay() !== 0 && result.getDay() !== 6) {
          days--;
        }
      }
      return result;
    };
    const expirationPlus8BusinessDays = businessDaysToAdd(date, 8);
    return `Disponível para coleta apartir de: ${expirationPlus8BusinessDays.toLocaleDateString('pt-BR')}`;
  }
}

export { calculateDeliveryPeriod };
