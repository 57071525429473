import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { Box, Text, Container, AspectRatio  } from "@chakra-ui/react";
import { useAuth } from "../../Hooks/useAuth";
import { theme } from "../../styles/theme";
import { Footer } from '../../components/Footer'
import { Carousel } from '../../components/Carousel'
import { ProductsCarousel } from '../../components/ProductsCarousel'
import { MarketPath } from '../../components/MarketPath'
import { api } from "../../services/api";
import video from '../../assets/images/Boozen_Video.mp4';

export function Home() {
  const { user, isAuthenticated } = useAuth();
  const [products, setProducts] = useState([])
  const [batches, setBatches] = useState([]);
  const [prices, setPrices] = useState([]);
  const [productsPhotos, setProductsPhotos] = useState({});

  let product = null
  let batch = null
  async function fetchProducts() {
    const response = await api.get(`/`)
    setProducts(response.data.products)
    setBatches(response.data.batches)
    setPrices(response.data.prices)
    setProductsPhotos(response.data.product_photos_url)
    product = response.data.products.find(e => e.id === 250)
    batch = response.data.batches.find(e => e.product_id === 250)
  }

  useEffect(() => {
    isAuthenticated();
    fetchProducts();
  }, [])

  return (<>
  <Navbar />
  <Text color={theme.colors.primaryGreen} fontWeight="500" marginBottom="10px" textAlign="center">Entrega grátis para pedidos acima de R$120,00</Text>
  <Box bg={theme.colors.white500}>
    <Carousel/>
    <MarketPath/>
    <Box
      background={theme.colors.white500}
      display={{ base: 'block', md: 'flex' }}
      height={{ base: 'auto', md: '60vh' }}
      justifyContent={"center"}
      padding={"5%  0"}
      gap={"5%"}
    >
      <Box
        width={{ base: '100%', md: '30%' }}
        textAlign={{ base: 'center', md: '' }}
      >
        <Text
          fontWeight={"bold"}
          fontSize={"27px"}
          lineHeight={"1"}
          color={theme.colors.orange}
        >
          Assista ao vídeo ao lado e
          entenda melhor como tudo
          funciona.
        </Text>
        <Text
          marginTop={"5%"}
          lineHeight={"1.25"}
          fontSize={{ base: '18px', md: '15px' }}
        >
          Essa é a missão da Boozen, permitir que você tenha acesso às
          melhores bebidas do país por um preço muito mais acessível!
          Basta se planejar :) Com a gente, você compra direto da fábrica!
        </Text>
      </Box>
      <AspectRatio
        maxW='500px'
        ratio={1}
        maxHeight={"calc(50vh - 80px)"}
        width={window.screen.width < 768 ? "95%" : "50%"}
        margin={window.screen.width < 768 ? "8% auto" : ""}
        boxShadow={"rgba(0, 0, 0, 0.4) 0px 5px 15px;"}
        borderRadius='15px'
      >
        <video src={video} width="500" controls
          style={{
            borderRadius: '15px',
          }}
        >
        </video>
      </AspectRatio>
    </Box>
      <Container
        maxWidth={"none"}
        padding={{base: '5% 2%', md: '3% 0%'}}
        background={theme.colors.orange}
        borderRadius='15px'
        boxShadow={"rgba(0, 0, 0, 0.4) 0px 5px 15px;"}
        mb='30px'
      >
      <Text
        textAlign={"center"}
        fontSize={"28px"}
        fontWeight={"700"}
        color={theme.colors.primaryGreen}
      >
        PRODUTOS EM DESTAQUE
      </Text>
      <Text
        fontSize={{ base: '18px', md: '15px' }}
        textAlign={"center"}
        padding={"0 0 3% 0 "}
      >
        São bebidas especialmente selecionadas com sabor e preço especial
      </Text>
      <Box
        margin={"0"}
        justifyContent={"center"}
        gap={"50px"}
        maxWidth={"none"}
      >
        <ProductsCarousel></ProductsCarousel>
      </Box>
    </Container>
  </Box>
  <Footer/>
</>)}
