import { Box, Button, Image, Input, Text, Link } from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import { api } from "../../services/api";
import Logo from "../../assets/images/logo.svg";
import { useState } from "react";
import { useAuth } from "../../Hooks/useAuth";
import swal  from 'sweetalert';



export function Password() {
  const [userEmail, setUserEmail] = useState("");

  async function handlePasswordResetForUser(e) {
    e.preventDefault();
    try {
      const response = await api.post('/users/password', {
        headers: {
          'Content-Type': 'application/json',
        },
        body: { email: userEmail },
      });

      if (response.data.success) {
        swal({
          title: "Ok",
          text: "Instruções de recueração de senha enviadas para seu email.",
          icon: "success",
        });

      } else {
        swal({
          title: "Oh nooo",
          text: "Algo deu errado, tente novamente!.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error('Error occurred while making the password reset request:', error);
    }
  }

  return (
    <Box
      height="100vh"
      background={theme.colors.background}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        width="100%"
        maxWidth={360}
        padding="8"
        borderRadius={8}
        background={theme.colors.white}
        boxShadow="lg"
        display="flex"
        flexDirection="column"
      >
        <Image
          src={Logo}
          alt="Boozen logo"
        />
        <Text
          fontSize={"24px"}
          color={theme.colors.secondary}
          textAlign={"center"}
        >
          Recuperação de senha
        </Text>
        <Input
          placeholder="E-mail"
          margin="20px 0"
          onChange={(e) => setUserEmail(e.target.value)}
        />

        <Button
          bg={theme.colors.orange}
          color={theme.colors.white}
          padding={"0 50px"}
          width={"25%"}
          _hover={{
            bg: theme.colors.secondary,
          }}
          size={'md'}
          margin={"20px auto"}
          onClick={(e) => handlePasswordResetForUser(e)}
        >
          Enviar
        </Button>

        {/* <Box
          margin={"auto"}
        >
          <Link to='/sign_in'
            style={{ textDecoration: 'none' }}
          >
            <Button
              bg={"transparent"}
              color={theme.colors.orange}
              marginTop={"15px"}
              _hover={{
                color: theme.colors.secondary,
              }}
              size={'md'}
            >
              Entrar
            </Button>
          </Link>
        </Box> */}
      </Box>
    </Box>
  )
}
