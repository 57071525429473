import { Box, Text, Button, Image, HStack, VStack  } from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import React, { useState, useEffect } from "react";
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageUrls = [
    {
      url: require("../../assets/images/home-cover-1.jpg"),
      productUrl: "/produtos/93",
      bannerTitle: "Soulcaipi mix 250ml",
      bannerDiscount: "Até 17% de desconto",
      bannerPrice: "de R$13,00 por até R$11,13"
    },
    {
      url: require("../../assets/images/home-cover-2.jpg"),
      productUrl: "/produtos/65",
      bannerTitle: "Gin Jam - Verace 700ml",
      bannerDiscount: "Até 19% de desconto",
      bannerPrice: "de R$88,00 por até R$73,75"
    },
    {
      url: require("../../assets/images/home-cover-3.jpg"),
      productUrl: "/produtos/50",
      bannerTitle: "Vinhos Filgueira",
      bannerDiscount: "Até 39% de desconto",
      bannerPrice: "de R$109,90 por até R$69,75"
    }
  ];

  const handlePrev = () => {
    const newIndex = (currentIndex - 1 + imageUrls.length) % imageUrls.length;
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % imageUrls.length;
    setCurrentIndex(newIndex);
  };

  const handleDotClick = (index) => {
    console.log("Dot clicked with index:", index);
    setCurrentIndex(index);
  };


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "transparent",
          borderRadius: "10px",
          padding: "10px",
          bottom: '-5px'
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    style: {
      boxShadow: "rgba(0, 0, 0, 0.8) 0px 5px 15px",
      width: "90%",
      height: "55vh",
      position: "relative",
      overflow: "hidden",
      margin: "25px auto",
      borderRadius: "40px"
    }
  };

  return (
    <Slider {...settings}>
      {imageUrls.map((imageUrl, index) => (
        <Box
          key={index}
          height='55vh'
          width={{ base: '90%', md: '90%' }}
        >
          <Image src={imageUrl.url} alt={`image ${index}`} w="100%" h="100%" objectFit="cover" backgroundPosition="center" />
        </Box>
      ))}
    </Slider>
  );
}
