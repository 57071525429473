import {
  Box,
  Button,
  Input,
  Select,
  Text
} from "@chakra-ui/react";
import { useState } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import { api } from "../../../services/api";
import { EditProductType } from "../EditProductType";
import { theme } from "../../../styles/theme";
import { FaPlus } from "react-icons/fa";
import swal  from 'sweetalert';

export function TypeCard({allCategories, allProductTypes}) {
  const { user } = useAuth();
  const [productTypeName, setProductTypeName] = useState("")
  const [categoryId, setCategoryId] = useState("")
  const [categories, setCategories] = useState(allCategories)
  const [productTypes, setProductTypes] = useState(allProductTypes)
  const [toggleTypeCreation, setToggleTypeCreation] = useState(false);

  async function fetchCreateProductType(e) {
    e.preventDefault();
    if (productTypeName === "" || categoryId === "") {
      swal({
        title: "Ops",
        text: "Nome e categoria não podem ficar em branco.",
        icon: "info",
      });
      return
    }
    const data = {
      name: productTypeName,
      user_id: user.id,
      category_id: categoryId
    }

    const response = await api.post("api/v1/product_types", data, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
      }
    })
    if (response.status === 200)  {
      productTypes.push(response.data)
      setProductTypeName("")
      setCategoryId("")
      swal({
        title: "Ok",
        text: "Tipo criada com sucesso!.",
        icon: "success",
      });
    } else {
      swal({
        title: "Opa",
        text: "Algo deu errado.",
        icon: "error",
      });
    }
  }

  const updateProductTypeName = (productTypeId, newName) => {
    const updatedProductTypes = productTypes.map((productType) =>
      productType.id === productTypeId
        ? { ...productType, name: newName }
        : productType
    );
    setProductTypes(updatedProductTypes);
  };


  const handleToggleShowCreationOfType = () => {
    setToggleTypeCreation(!toggleTypeCreation);
  }


  return (
    <>
      <Button
        type="button"
        onClick={handleToggleShowCreationOfType}
        background="transprent"
        _hover={{ bg: "transparent", color: `${theme.colors.primary}` }}

      >
        <FaPlus></FaPlus> Cadastrar novo tipo
      </Button>
      {toggleTypeCreation && (<>
        <Box
          margin="20px 0"
        >
          <Input
            placeholder="Tipo de produto"
            onChange={(e) => setProductTypeName(e.target.value)}
            value={productTypeName}
          />
        </Box>

        <Box>
          <Text>
            Selecione uma categoria
          </Text>
          <Select
            onChange={(e) => setCategoryId(e.target.value)}
            value={categoryId}
          >
            <option value="">Selecione</option>
            {categories?.map((category, index) => {
              return (
                <option
                  key={index}
                  value={category.id}
                >
                  {category.name}
                </option>
              )
            })}
          </Select>
          <Box
            margin="20px 0"
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              onClick={(e) => fetchCreateProductType(e)}
            >
              Criar tipo de produto
            </Button>
          </Box>
        </Box>
      </>)}
      <Text textAlign="center" marginBottom="20px" fontSize="28px" fontWeight="bolder">Tipos</Text>

      {productTypes?.map((productType, index) => {
        return (
          <>
            <Box
              key={productType.id}
              display={"flex"}
              justifyContent="space-between"
              alignItems="center"
              padding="10px 0"
            >
              <Text key={index}>
                #{productType.id} - {productType.name}
              </Text>
              <EditProductType
                productTypeId={productType.id}
                allCategories={categories}
                onUpdateProductType={updateProductTypeName}
              />
            </Box>
          </>
        )
      })}
    </>
  )
}
