import React from "react";
import {
  Box,
  Flex,
  Avatar,
  HStack,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  Stack,
  Badge,
  Select,
  Image,

} from '@chakra-ui/react';
import { theme } from '../../styles/theme';
import { AuthContext } from '../../Context/AuthContext';
import { UserContext } from '../../Context/UserContext';

import { CityContext } from '../../Context/CityContext';
import { Search } from '../Search';
import { useContext, useEffect, useState } from 'react';
import { FaMapMarkerAlt, FaShoppingCart } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { api } from "../../services/api";
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo-boozen.PNG';
import { FaInstagram } from "react-icons/fa";


const Links = ['HOME', 'SOBRE', 'QUEM SOMOS'];
const NavLink = ({ children }) => (
  <Link
    px={2}
    py={1}
    fontSize={'18px'}
    rounded={'md'}
    color={theme.colors.black}
    _hover={{
      backgroundColor: theme.colors.primary,
    }}
    style={{ textDecoration: 'none' }}
    href={'#'}>
    {children}
  </Link>
);

const Navbar = ({ onFilterProducts }) => {
  const { user, signOutUser, isAuthenticated } = useContext(AuthContext);
  const { cityParam, cityName, cities, handleSetCity } = useContext(CityContext);
  const { userInfos, userCity, userPhoto } = useContext(UserContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cartBatchesCounter, setCartBatchesCounter] = useState(0)
  const [selectLocation, setSelectLocation] = useState(false)
  const navigate = useNavigate();
  const [cartCounter, setCartCounter] = useState("")


  // async function fetchCartBatches() {
  //   if (user) {
  //     const response = await api.get(`/api/v1/cart_batches?user_id=${user.id}`)
  //     setCartBatchesCounter(response.data.cart_batches.length)
  //     localStorage.setItem("cartItems", response.data.cart_batches.length)
  //   }
  // }

  async function fetchCartBatches() {
    // const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
    // setCartBatchesCounter(guestCart.length);
    // localStorage.setItem("cartItems", guestCart.length);  // Update localStorage counter
    // if (user) {
    //   // If the user is logged in, fetch cart from the backend
    //   const response = await api.get(`/api/v1/cart_batches?user_id=${user.id}`);
    //   setCartBatchesCounter(response.data.cart_batches.length);
    //   localStorage.setItem("cartItems", response.data.cart_batches.length);
    // } else {
    //   // If no user is logged in, fetch the cart from localStorage for guest
    // }

    const cartData = localStorage.getItem('guestCart');
      let guestCart = [];

      // Safely parse localStorage data
      try {
        if (cartData) {
          guestCart = JSON.parse(cartData);
        }
      } catch (error) {
        console.error("Error parsing guestCart from localStorage:", error);
      }

      // Update the state and localStorage counter
      setCartBatchesCounter(guestCart.length);
      localStorage.setItem("cartItems", guestCart.length.toString());  // Update localStorage counter
  }

  useEffect(() => {
    isAuthenticated();
    fetchCartBatches();
  }, [user])

  // useEffect(() => {
  //   const getCartCounter = () => {
  //     setCartCounter(localStorage.getItem('cartItems'));
  //   };
  //   getCartCounter();
  //   const counterInterval = setInterval(() => {
  //     getCartCounter();
  //   }, 30000);
  //   return () => {
  //     clearInterval(counterInterval);
  //   };
  // }, []);

  useEffect(() => {
    const getCartCounter = () => {
      setCartCounter(localStorage.getItem('cartItems') || '0');
    };

    // Update the cart counter based on user presence
    fetchCartBatches();

    getCartCounter();
    const counterInterval = setInterval(() => {
      getCartCounter();
    }, 100);

    return () => {
      clearInterval(counterInterval);
    };
  }, []);


  function handleMenu() {
    return (
      <>
        {user?.access === "admin" && (

          <MenuItem bg={theme.colors.white500}
            color={theme.colors.secondary}
            fontWeight='bold'
            _hover={{bg: theme.colors.secondary, color: theme.colors.white500}}
          >
            <Link to='/admin/dashboard' width='100%'>
              Painel Do Admin
            </Link>
          </MenuItem>
        )}
        <MenuItem
          inset={{base: '0px auto auto -2000px !important', md: '0px auto auto 0px'}}
          bg={theme.colors.white500}
          color={theme.colors.secondary}
          fontWeight='bold'
          _hover={{bg: theme.colors.secondary, color: theme.colors.white500}}
        >
          <Link to={`/perfil/${user.id}`} style={{ width: '100%',}}>
            Perfil
          </Link>
        </MenuItem>
        <MenuItem
          bg={theme.colors.white500}
          color={theme.colors.secondary}
          fontWeight='bold'
          _hover={{bg: theme.colors.secondary, color: theme.colors.white500}}
          onClick={() => signOutUser()}>
            Sair
        </MenuItem>
      </>
    )
  }

  const handleBestSellings = async () => {
    try {
      let url = `/api/v1/products?city_id=${cityParam}&produtos=best_selling`;
      const response = await api.get(url);
      const productsData = response.data.products;
      navigate('/produtos', { state: { products: productsData } });
    } catch (error) {
      console.error(error);
    }
  };


  async function handleLocality(e)  {
    if (e.target.value !== "") {
      handleSetCity(e.target.value)
    } else {
      handleSetCity(1411)
    }
    setSelectLocation(false)
  }

  const handleShowLocationFilter = () => {
    if (selectLocation === false) {
      setSelectLocation(true)
    } else {
      setSelectLocation(false)
    }
  }

  return (
    <Box
      bg={theme.colors.white500}
      px={3}
      pb={4}
      pt={1}
      height={{ base: '185px', md: '150px' }}
    >
      <Flex
        h={16}
        alignItems={'center'}
        justifyContent={{ base: 'space-between', md: 'space-around' }}
        display='flex'
      >
        <Link to={'/'}>
          <Image
            objectFit='cover'
            maxW={{ base: '130px', md: '200px' }}
            src={logo}
          />
        </Link>
        <Box
          display={{ base: 'none', md: 'contents'}}
        >
          <Button
            style={{ textDecoration: 'none', fontWeight: 'bold', color: '#216a0f' }}
            fontSize='15px'
            background='transparent'
            _hover={{
              bg: 'transparent',
            }}
          >
            <a href='https://instagram.com/boozenoficial?igshid=NzZlODBkYWE4Ng=='
              target='_blank' rel="noopener noreferrer"
              style={{ display: 'flex', alignItems: 'center', gap: '2px' }}
              _hover={{
                color: 'red',
              }}
            >
              INSTA <FaInstagram></FaInstagram>
            </a>
          </Button>
          <Button
            style={{ textDecoration: 'none', fontWeight: 'bold', color: '#216a0f' }}
            fontSize='15px'
            background='transparent'
            _hover={{
              bg: 'transparent',
              color: 'red',
            }}
          >
            <Link to='/quem_somos'>
              QUEM SOMOS
            </Link>
          </Button>
          <Button
            style={{ textDecoration: 'none', fontWeight: 'bold', color: '#216a0f' }}
            fontSize='15px'
            onClick={handleBestSellings}
            background='transparent'
            _hover={{
              bg: 'transparent',
            }}
          >
            DESTAQUES
          </Button>
        </Box>
        <Link to='/loja'
          style={{ textDecoration: 'none' }}
        >
          <Button
            bg={theme.colors.orange}
            color={theme.colors.white}
            padding={{base: '0 30px', md: '0 60px'}}
            height={"40px"}

            _hover={{
              bg: theme.colors.secondary,
            }}
            size={'md'}
            borderRadius={"10px"}
          >
            LOJA
          </Button>
        </Link>
        <Box
          display={{base: 'flex', md: 'flex'}}
        >
          <Button
            background={"transparent"}
            onClick={() => handleShowLocationFilter()}
            color={theme.colors.secondary}
            _hover={{
              background: "transparent"
            }}
            position={{base: 'absolute', md: 'initial'}}
            top={{base: '45px', md: ''}}
            left={{base: '0%', md: ''}}
          >
            <FaMapMarkerAlt color={theme.colors.secondary}></FaMapMarkerAlt>
            {cityName}
          </Button>
          {selectLocation && (<>
            <Select
              borderColor={theme.colors.primary}
              id="city-input"
              maxWidth={"130px"}
              height={"22px"}
              fontSize="15px"
              value={cityParam || ""}
              onChange={(e) => handleLocality(e)}
              _hover={{
                borderColor: theme.colors.secundary
              }}
              position={{base: 'absolute', md: 'initial'}}
              top={{base: '75px', md: ''}}
              left={{base: '27px', md: ''}}
              marginTop={{base: '0%', md: '5%'}}
            >
              <option value='' ></option>
              {cities.map((city, index)=> {
                return (<option id="city-input" key={index} value={city.id}>{city.name}</option>);
              })}
            </Select>
          </>)}
        </Box>
        { user === undefined && (
          <Link
            to={"/carrinho"}
          >
            <Box
              display={"block"}
              position={{base: 'absolute', md: 'relative'}}
              left={{base: '38%', md: '0%'}}
              top={{base: '0.25%', md: '0%'}}
            >
              <Badge
                background={"#f6b93d"}
                borderRadius={"50%"}
                color={"black"}
                width={"18px"}
                height={"18px"}
                marginTop={{base: '30%', md: '30%'}}
                marginLeft={{base: '8%', md: '15%'}}
                textAlign={"center"}
                fontSize="11px"
              >
                {cartCounter}
              </Badge>
              <FaShoppingCart
                size={'18px'}
                color="#fc694d"
              />
            </Box>
          </Link>
        )}
        {user !== undefined ? (
          <Flex alignItems={'center'} gap={{base: '10px', md: '10px'}}>
            <Link
              to={"/carrinho"}
            >
              <Box
                display={"block"}
              >
                <Badge
                  background={"#f6b93d"}
                  borderRadius={"50%"}
                  color={"black"}
                  width={"18px"}
                  height={"18px"}
                  marginTop={{base: '0%', md: '30%'}}
                  marginLeft={{base: '5%', md: '15%'}}
                  textAlign={"center"}
                  fontSize="11px"
                >
                  {cartCounter}
                </Badge>
                <FaShoppingCart
                  size={'18px'}
                  color="#fc694d"
                />
              </Box>
            </Link>
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}
                marginLeft={{base: '0%', md: '5%'}}
              >
                <Avatar
                  size={{base: 'sm', md: 'md'}}
                  src={
                    userPhoto !== ""
                    ? userPhoto : 'https://img.freepik.com/free-icon/user_318-159711.jpg'
                  }
                />
              </MenuButton>
              <MenuList position='relative' zIndex='10000' bg={theme.colors.white500} borderColor={theme.colors.secondary}>
                {handleMenu()}
              </MenuList>
            </Menu>
          </Flex>
        ) : (
          <HStack
            align={'end'}
            alignItems={'center'}
            justifyContent={'end'}
            position={{base: 'absolute', md: 'initial'}}
            top={{base: '15px', md: ''}}
            right={{base: '0px', md: ''}}
          >
            <Link to='/sign_in'
              style={{ textDecoration: 'none' }}
            >
              <Button
                bg='transparent'
                color={theme.colors.orange}
                _hover={{
                  color: theme.colors.secondary,
                }}
                size={'md'}
                rounded={'lg'}
              >
                Entrar
              </Button>
            </Link>
            <Link to='/sign_up'
              style={{ textDecoration: 'none' }}
            >
              <Button
                bg='transparent'
                color={theme.colors.orange}
                _hover={{
                  color: theme.colors.secondary,
                }}
                size={'md'}
                rounded={'md'}
                display={{base: 'none', md: 'block'}}
              >
                Cadastrar
              </Button>
            </Link>
          </HStack>
        )}
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as={'nav'} spacing={4}>
            {Links.map((link, index) => (
              <NavLink key={index}>{link}</NavLink>
            ))}
          </Stack>
        </Box>
      ) : null}
      <Search/>
    </Box>
  );
};

export default React.memo(Navbar);
