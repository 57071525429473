import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { useEffect, useState, useReducer } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import { api } from "../../../services/api";
import { theme } from "../../../styles/theme";
import swal  from 'sweetalert';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function EditProduct({ productId, allProductTypes, onUpdateProduct, thisProduct }) {
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [productTypes, setProductTypes] = useState(allProductTypes);
  const [product, setProduct] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      description: "",
      brand: "",
      quantity_per_box: "",
      product_type_id: "",
      market_pice: "",
      size: "",
      suggested_price_for_sale: "",
      customer_review: ""
    }
  );
  const [productPhotos, setProductPhotos] = useState([]);
  const [productFlyer, setProductFlyer] = useState(null);

  useEffect(() => {
    setProduct(thisProduct);
  }, [thisProduct])

  // const handleProductChange = (e) => {
  //   const { name, value } = e.target;
  //   setProduct({ [name]: value });
  // }
  const handleProductChange = (name, value) => {
    console.log("name:", name, "value:", value);
    setProduct({ [name]: value });
  }


  async function handleEditProduct(e) {
    e.preventDefault();
    const formData = new FormData()
    formData.append("product[name]", product.name);
    formData.append("product[product_type_id]", product.product_type_id);
    formData.append("product[brand]", product.brand);
    formData.append("product[size]", product.size);
    formData.append("product[description]", product.description);
    formData.append("product[market_price]", product.market_price);
    formData.append("product[quantity_per_box]", product.quantity_per_box);
    formData.append("product[suggested_price_for_sale]", product.suggested_price_for_sale);
    formData.append("product[customer_review]", product.customer_review);


    if (productPhotos) {
      for (let i = 0; i < productPhotos.length; i++) {
        formData.append("product[photos][]", productPhotos[i]);
      }
    }

    if (productFlyer) {
      formData.append("product[flyer]", productFlyer);
    }

    const response = await api.put(`/api/v1/products/${productId}`, formData, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
        contentType: "multipart/form-data"
      },
    })
    if (response.status === 200) {
      onUpdateProduct(response.data);
      swal({
        title: "Ohhh Yeahhh",
        text: "Dados atualizados com sucesso!",
        icon: "success",
      });
      onClose()
    } else {
      swal({
        title: "Ohhh Noo",
        text: "Algo deu errado!",
        icon: "error",
      });
    }
    onClose()
  }

  let modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{
        'color': ['black', 'red', 'blue', 'white', 'green', 'yellow', 'pink', 'gray', '#90a7bf', 'color-picker', '#D44120', '#F6B93D', '#216a0f', '#49b82d', '#ef9207', '#ef5f0e', '#ff6600']
      }],
      [{ 'background': ['transparent', 'black', 'red', 'blue', 'white', 'green', 'yellow', 'pink', 'gray', '#90a7bf', '#D44120', '#F6B93D', '#216a0f', '#49b82d', '#ef9207', '#ef5f0e', '#ff6600'] }],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  return (
    <>
      <Button onClick={onOpen}>Editar</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{product.name} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Box
                margin="20px 0"
              >
                <Text>Nome</Text>
                <Input
                  placeholder={`${product?.name}`}
                  // onChange={handleProductChange}
                  onChange={(e) => handleProductChange("name", e.target.value)}
                  name="name"
                />
              </Box>
              <Box
                margin="20px 0"
              >
                <Text>Descrição</Text>
                {/* <Input
                  placeholder={`${product?.description}`}
                  onChange={handleProductChange}
                  name="description"
                  type='text'
                  textAlign='start'
                /> */}
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  name="description"
                  placeholder="Descrição"
                  // onChange={(e) => handleProductChange(e)}
                  onChange={(value) => handleProductChange("description", value)}
                />
              </Box>
              <Box
                margin="20px 0"
              >
                <Text>Tamanho</Text>
                <Input
                  placeholder={`${product?.size}`}
                  // onChange={handleProductChange}
                  onChange={(e) => handleProductChange("size", e.target.value)}
                  name="size"
                  textAlign='start'
                />
              </Box>
              <Box
                display="flex"
                gap={8}
                margin="20px 0"
              >
                <Box>
                  <Text>Marca</Text>
                  <Input
                    placeholder={`${product?.brand}`}
                    // onChange={handleProductChange}
                    onChange={(e) => handleProductChange("brand", e.target.value)}
                    name="brand"
                  />
                </Box>
                <Box>
                  <Text>Quant/caixa</Text>
                  <Input
                    placeholder={`${product?.quantity_per_box}`}
                    type={"number"}
                    // onChange={handleProductChange}
                    onChange={(e) => handleProductChange("quantity_per_box", e.target.value)}
                    name="quantity_per_box"
                  />
                </Box>
              </Box>
              <Box marginBottom="20px">
                <Text>Review do cliente</Text>
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  name="customer_review"
                  placeholder="Review do cliente"
                  // onChange={(e) => handleProductChange(e)}
                  onChange={(value) => handleProductChange("customer_review", value)}
                />
              </Box>
              <Box marginBottom="20px">
                <Text>Tipo de babida</Text>
                <Select
                  placeholder="Selecione o tipo"
                  // onChange={handleProductChange}
                  onChange={(e) => handleProductChange("product_type_id", e.target.value)}
                  name="product_type_id"
                >
                  {productTypes.map(productType => (
                    <option
                      key={productType.id}
                      value={productType.id}
                    >
                      {productType.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box marginBottom="20px">
                <Text>Preço de Mercado</Text>
                <Input
                  placeholder={`${product?.market_price}`}
                  type={"number"}
                  // onChange={handleProductChange}
                  onChange={(e) => handleProductChange("market_price", e.target.value)}
                  name="market_price"
                  marginBottom="20px"
                />
                <Text>Preço sugerido</Text>
                <Input
                  placeholder={`${product?.suggested_price_for_sale}`}
                  type={"number"}
                  // onChange={handleProductChange}
                  onChange={(e) => handleProductChange("suggested_price_for_sale", e.target.value)}
                  name="suggested_price_for_sale"
                />
              </Box>
              <Box>
                <Input type="file" id="productPhotos" display="none" multiple name="photos" onChange={(e) => setProductPhotos(e.target.files)}>
                </Input>

                <label htmlFor="productPhotos">
                  <Box
                    as="span"
                    display="inline-block"
                    border="1px solid"
                    borderColor={theme.colors.primary}
                    borderRadius="md"
                    padding="5px"
                    cursor="pointer"
                    backgroundColor="transparent"
                    marginTop="20px"
                    marginRight="10px"
                    color={theme.colors.primary}
                    _hover={{background: theme.colors.primary, color: theme.colors.orange}}
                  >
                    Escolher Fotos
                  </Box>
                  {productPhotos && (
                    productPhotos.length
                  )} fotos selecionadas
                </label>
              </Box>
              <Box>
                <Input type="file" id="productFlyers" display="none" multiple={false} name="flyers" onChange={(e) => setProductFlyer(e.target.files[0])}>
                </Input>

                <label htmlFor="productFlyers">
                  <Box
                    as="span"
                    display="inline-block"
                    border="1px solid"
                    borderColor={theme.colors.primary}
                    borderRadius="md"
                    padding="5px"
                    cursor="pointer"
                    backgroundColor="transparent"
                    marginTop="20px"
                    marginRight="10px"
                    color={theme.colors.primary}
                    _hover={{background: theme.colors.primary, color: theme.colors.orange}}
                  >
                    Escolher Flyer
                  </Box>
                  {productFlyer && (
                    productFlyer.name
                  )}
                </label>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={onClose}
            >
              Fechar
            </Button>
            <Button
              colorScheme="green"
              onClick={(e) => handleEditProduct(e)}
            >
              Editar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
