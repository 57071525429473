import {
  Box,
  Input,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure, Tabs, TabList, TabPanels, Tab, TabPanel, Grid, GridItem
} from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import React, { useContext, useEffect, useState } from "react";
import { api } from "../../services/api";
import { FaSearch, FaFilter } from 'react-icons/fa';
import { CityContext } from '../../Context/CityContext';
import { FaRegTimesCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export function BeerFilters(props) {
  const location = useLocation();
  const [beerTypes, setBeerTypes] = useState([]);
  const [beerBrands, setBeerBrands] = useState([]);
  // const [products, setProducts] = useState([])
  const { cityParam } = useContext(CityContext);


  const brandOptions = ["Ambev", "Verace", "Sátira", "Backer", "CAPITÃO SENRA"]
  const beerTypeOptions = ["PILSEN", "IPA", "APA", "LAGER", "Witbier", "LowCarb", "German Pils", "Hop Lager", "Pale Ale", "Brown Ale"]

  async function handleGetProductBrand(e, brand) {
    e.preventDefault();
    const updatedBeerBrands = beerBrands.includes(brand)
      ? beerBrands.filter((b) => b !== brand)
      : [...beerBrands, brand];
    setBeerBrands(updatedBeerBrands);
    filterProducts(updatedBeerBrands, beerTypes);
  }

  async function handleGetProductTypes(e, productType) {
    e.preventDefault();
    const updatedBeerTypes = beerTypes.includes(productType)
      ? beerTypes.filter((type) => type !== productType)
      : [...beerTypes, productType];

    setBeerTypes(updatedBeerTypes);
    filterProducts(beerBrands, updatedBeerTypes);
  }

  async function filterProducts(brands, types) {
    let url = `/api/v1/products?city_id=${cityParam}`;
    if (brands.length > 0) {
      url = `${url}&beer_brands=${brands.join(',')}`;
    }
    if (types.length > 0) {
      url = `${url}${brands.length > 0 ? '&' : '&'}beer_types=${types.join(',')}`;
    }
    if (types.length === 0 && brands.length === 0) {
      const urlParams = new URLSearchParams(location.search);
      const filter = urlParams.get("filter");
      url = `${url}&filter=${filter}`;
    }
    const response = await api.get(url);
    props.setProducts(response.data.products);
  }


  return (
    <>
    <Accordion allowToggle defaultIndex={window.screen.width < 762 ? [] : [0]}>
      <AccordionItem borderColor={theme.colors.primary}>
        <AccordionButton
          fontWeight='bold'
          display={{base: 'block', md: 'none'}}
        >
          FILTRAR
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={2}>
          <Grid
            templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(10, 1fr)" }}
            gap={4}
            px={7}
          >
            {beerTypeOptions.map((beerType, index) => {
              return(
                <GridItem key={index}>
                  <Button
                    width={"100%"}
                    onClick={(e) => handleGetProductTypes(e, beerType)}
                    border='1px solid #D44120'
                    borderRadius='5px'
                    _hover={{
                      transform: "scale(1.02)",
                      color: 'white',
                      bg: '#D44120'
                    }}
                    style={{
                      backgroundColor: beerTypes.includes(beerType)
                        ? '#D44120'
                        : 'transparent',
                        color: beerTypes.includes(beerType)
                        ? 'white'
                        : '#D44120',
                    }}
                  >
                    {beerType}
                  </Button>
                </GridItem>
              )
            })}
          </Grid>
          <Grid
            templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(5, 1fr)" }}
            gap={4}
            pt={4}
            px={7}
          >
            {brandOptions.map((brandOption, index) => {
              return(
                <Button
                  key={index}
                  width={"100%"}
                  onClick={(e) => handleGetProductBrand(e, brandOption)}
                  border='1px solid #D44120'
                  borderRadius='5px'
                  _hover={{
                    transform: "scale(1.02)",
                    color: 'white',
                    bg: '#D44120'
                  }}
                  style={{
                    backgroundColor: beerBrands.includes(brandOption)
                      ? '#D44120'
                      : 'transparent',
                      color: beerBrands.includes(brandOption)
                      ? 'white'
                      : '#D44120',
                  }}
                >
                  {brandOption}
                </Button>
              )
            })}
          </Grid>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
    </>
  );
}
