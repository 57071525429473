import {
  Box
} from "@chakra-ui/react";
import React, { useContext, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { FaWhatsappSquare } from "react-icons/fa";

export function WhatsAppButton() {

  return (
    <Box position="fixed" top={{base: "90%", md: "90%"}} marginLeft={{base: "85%", md: "95%"}} zIndex="10000">
      <a href="https://wa.me/5531971301228" target="_blank" rel="noopener noreferrer">
        <FaWhatsappSquare fontSize={window.screen.width < 768 ? 60 : 60} color={"#26cc64"} weight="fill"/>
      </a>
    </Box>
  );
}
