import { Box, Text, Spinner, Grid, GridItem} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Footer } from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { ProductCard } from "../../components/ProductCard";
import { CityContext } from '../../Context/CityContext';
import { api } from "../../services/api";
import { theme } from "../../styles/theme";
import { BeerFilters } from "../../components/BeerFilters";
import { WineFilters } from "../../components/WineFilters";
import { KitFilters } from "../../components/KitFilters";
import { HardDrinkFilters } from "../../components/HardDrinkFilters";
import { OtherDrinksFilters } from "../../components/OtherDrinksFilters";
import { SubcategoryFilters } from "../../components/SubcategoryFilters";
import { useLocation, Link } from 'react-router-dom';

export function Products() {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [batches, setBatches] = useState([]);
  const [prices, setPrices] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productsPhotos, setProductsPhotos] = useState({});
  const { cityParam } = useContext(CityContext);
  const [loadingPage, setLoadingPage] = useState(true);
  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    fetchProducts();
  }, [cityParam, location.search]);

  async function fetchProducts() {
    const filter = urlParams.get("filter");
    let apiUrl = '/api/v1/products';

    if (cityParam !== null) {
      apiUrl += `?city_id=${cityParam}`;
    }

    if (filter) {
      if (cityParam) {
        apiUrl += `&filter=${filter}`;
      } else {
        apiUrl += `?filter=${filter}`;
      }
    }
    const response = await api.get(apiUrl);
    setProducts(response.data.products);
    setBatches(response.data.batches);
    setPrices(response.data.prices);
    setProductsPhotos(response.data.product_photos_url);
    setProductTypes(response.data.product_types);
    setLoadingPage(false);
  }

  const handleFilterToDisplay = () => {
    const filterParam = urlParams.get('filter');
    if (filterParam) {
      try {
        const filterArray = JSON.parse(filterParam);
        if (Array.isArray(filterArray)) {
          if (filterArray.includes("CERVEJA")) {
            return <BeerFilters setProducts={setProducts} />;
          } else if (filterArray.includes("Vinho")) {
            return <WineFilters setProducts={setProducts} />;
          } else if (filterArray.includes("DESTILADOS")) {
            return <HardDrinkFilters setProducts={setProducts} />;
          } else if (filterArray.includes("OUTRAS BEBIDAS")) {
            return <OtherDrinksFilters setProducts={setProducts} />;
          } else if (filterArray.includes("Maiores Descontos") || filterArray.includes("Mais Vendidos")) {
            return <SubcategoryFilters setProducts={setProducts} />;
          } else if (filterArray.includes("Kits")) {
            return <KitFilters setProducts={setProducts} />;
          }
        }
      } catch (error) {
        console.log('Not an array');
      }
    } else {
      return <SubcategoryFilters setProducts={setProducts} />;
    }
  };

  const handleTypeToDisplay = () => {
    const filterParam = urlParams.get('filter');
    if (filterParam) {
      try {
        const filterArray = JSON.parse(filterParam);
        if (Array.isArray(filterArray)) {
          if (filterArray.includes("CERVEJA")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Cervejas</Text>
            );
          } else if (filterArray.includes("Vinho")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Vinhos e Espumantes</Text>
            );
          } else if (filterArray.includes("DESTILADOS")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Destilados</Text>
            );
          } else if (filterArray.includes("OUTRAS BEBIDAS")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Bebidas Prontas</Text>
            );
          } else if (filterArray.includes("Maiores Descontos")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Maiores Descontos</Text>
            );
          } else if ( filterArray.includes("Mais Vendidos")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Mais Vendidos</Text>
            );
          } else if (filterArray.includes("Kits")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Kits</Text>
            );
          } else if (filterArray.includes("Acessórios")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Acessórios</Text>
            );
          } else if (filterArray.includes("Não alcoólicos")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Não Alcoólicos</Text>
            );
          } else if (filterArray.includes("Collab")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Collab</Text>
            );
          } else if (filterArray.includes("Eventos, Buffets e Casamentos")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Eventos, Buffets e Casamentos</Text>
            );
          } else if (filterArray.includes("Microcervejaria")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Microcervejarias</Text>
            );
          } else {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Destaques</Text>
            );
          }
        }
      } catch (error) {
        console.log('Not an array');
      }
    } else {
      return (
        <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.white500} textShadow="1px 1px 2px #D44120">Destaques</Text>
      );
    }
  };

  return (
    <Box
        background={theme.colors.white500}
      >
        <Navbar/>
        {handleFilterToDisplay()}
        <Box padding={{base: '5% 0%', md: '1% 0% 2% 0%'}}>
          <Box
            width={{base: "100%", md: "100%"}}
            borderRadius='15px'
            background={theme.colors.orange}
            padding={{base: '5px', md: "20px"}}
            margin={{base: "0px", md: ""}}
            boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px;"}
            height='auto'
            minHeight='80vh'
          >
            {(products.length > 0 && prices.length > 0) && (<>
              {handleTypeToDisplay()}
            </>)}
            {(products.length <= 0 && loadingPage) && (
              <Box display='flex' justifyContent='center' padding='50px'>
                <Spinner color={theme.colors.primary} margin='auto'/>
              </Box>
            )}
            {(products.length <= 0 && !loadingPage) && (<>
              <Text
                textAlign={"center"}
                fontSize={"24px"}
                color={theme.colors.primaryGreen}
                fontWeight={"bold"}
                margin={"20% auto"}
              >
                Nenhum produto encontrado.
              </Text>
            </>)}
            <Grid
              templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(6, 1fr)" }}
              gap={4}
            >
              {(products.length > 0 && prices.length > 0) && (
                products.map((product) => (
                  <GridItem key={product.id}>
                    <Box height="25rem">
                      <Link to={`/produtos/${productTypes?.find(e => e.id === product.product_type_id)?.name.replace(/(%20|\s+)/g, "+")}/${product.name.replace(/(%20|\s+)/g, "+")}`}>
                        <ProductCard
                          id={product.id}
                          name={product.name}
                          prices={prices?.filter(element => element.batch_id === batches?.find(element => element.product_id === product.id)?.id)}
                          brand={product.brand}
                          description={product.description}
                          marketPrice={product.market_price}
                          quantityPerBox={product.quantity_per_box}
                          size={product.size}
                          productTypeId={product.product_type_id}
                          productType={product.product_type}
                          suggestedPriceForSale={product.suggested_price_for_sale}
                          batch={batches?.find(element => element.product_id === product.id)}
                          productPhotoUrl={productsPhotos[product.id]}
                          productTypes={productTypes}
                        />
                      </Link>
                    </Box>
                  </GridItem>
                ))
              )}
            </Grid>
          </Box>
        </Box>
        <Footer />
      </Box>
  )
}
