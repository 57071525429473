import { useEffect, useState, useContext } from "react";
import { createContext } from "react";
import { api } from "../services/api";
import { AuthContext } from './AuthContext';

export const UserContext = createContext({});

export function UserContextProvider(props) {
  const { user, isAuthenticated } = useContext(AuthContext);
  const [userInfos, setUserInfos] = useState({})
  const [userCity, setUserCity] = useState({})
  const [userPhoto, setUserPhoto] = useState(null)

  async function fetchUser() {
    if (user) {
      const response = await api.get(`/api/v1/users/${user.id}`)
      setUserInfos(response.data.user)
      setUserCity(response.data.city)
      setUserPhoto(response.data.user_photo)
      localStorage.setItem('userPhoto', response.data.user_photo);
    }
  }

  useEffect(() => {
    isAuthenticated();
    fetchUser();
  }, [user])

  return (
    <UserContext.Provider
      value={{ userInfos, userCity, userPhoto }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
