import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function DeliveryChart({ orders }) {

  const deliveryOrders = orders.filter(order => order.paid).filter(order => order.delivery_method === "delivery");
  const collectionOrders = orders.filter(order => order.paid).filter(order => order.delivery_method === "collect");

  const optionsDelivery = {
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Tipo de Entrega',
      },
    },
  };

  const dataDeliveries = {
    labels: ['Entrega', 'Coleta'],
    datasets: [
      {
        label: '#nº pedidos ',
        data: [deliveryOrders.length, collectionOrders.length],
        backgroundColor: [
          'rgb(156,1,194)',
          'rgb(21,191,158)',

        ],
        borderColor: [
          'rgb(156,1,194)',
          'rgb(21,191,158)',
        ],
        borderWidth: 1,
      },
    ],
  };


  return (<>
    <div  style={{ width: '100%', height: '27rem', background: '#f2f2f2', borderRadius: '15px', padding: '20px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 15px', margin: 'auto' }}>
      <Pie data={dataDeliveries} options={optionsDelivery}  style={{ margin: 'auto'}}/>
    </div>
  </>)
}
