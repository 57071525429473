import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import { api } from "../../../services/api";
import swal  from 'sweetalert';
import { calculateDeliveryPeriod } from "../../../services/deliveryPeriodCalculation";


export function Logistics({
  orderId, updateOrderStatus, deliveryStatus
}) {
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [order, setOrder] = useState({});
  const [batch, setBatch] = useState({});
  const [product, setProduct] = useState({});
  const [collectionAddress, setCollectionAddress] = useState({});
  const [collectionAddressCity, setCollectionAddressCity] = useState({});
  const [collectionAddressState, setCollectionAddressState] = useState({});
  const [client, setClient] = useState({});
  const [payment, setPayment] = useState({});


  async function fetchOrder() {
    const response = await api.get(`/api/v1/batch_orders/${orderId}`)
    setOrder(response.data.batch_order)
    setBatch(response.data.batch)
    setProduct(response.data.product)
    setCollectionAddress(response.data.collection_address)
    setCollectionAddressCity(response.data.collection_address_city)
    setCollectionAddressState(response.data.collection_address_state)
    setClient(response.data.user)
    setPayment(response.data.payment)
  }


  useEffect(() => {
    fetchOrder();
  }, [deliveryStatus])


  async function handleDeliveryStatus(e, status) {
    e.preventDefault();
    const confirmed = window.confirm("Tem certeza que deseja cancelar o pedido?");

    if (!confirmed) {
      return; // Exit the function if the user clicks "Cancel"
    }
    const formData = new FormData()
    formData.append("batch_order[delivery_status]", status);
    if (status === 'delivered' || status === 'collected') {
      const currentDateTime = new Date().toISOString();
      formData.append("batch_order[recieved_at]", currentDateTime);
    }

    if (status === 'cancelled') {
      const currentDateTime = new Date().toISOString();
      formData.append("batch_order[cancelled_at]", currentDateTime);
      formData.append("batch_order[status]", status);

    }
    const response = await api.put(`/api/v1/batch_orders/${orderId}`, formData, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
        contentType: "multipart/form-data"
      },
    })
    if (response.status === 200) {
      swal({
        title: "Ohhh Yeahhh",
        text: "Status do delivery atualizado com sucesso!",
        icon: "success",
      });
      updateOrderStatus(response.data.id, response.data.delivery_status);
      onClose()
    } else {
      swal({
        title: "Ohhh Noo",
        text: "Algo deu errado!",
        icon: "error",
      });
      onClose()
    }
  }

  function formatPhoneNumber(phoneNumber) {
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.length === 11) {
      return cleaned.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
    } else {
      return phoneNumber;
    }
  }

  return (
    <>
      <Button onClick={onOpen}>Mais</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes pedido: #{orderId}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text fontWeight='500'>
                {order.quantity} - {batch.unity_of_measurement}{order.quantity > 1 ? <span>s</span> : <span></span>} de {product.name} ({order.quantity * product.quantity_per_box} unidades)
              </Text>
            </Box>
            <Box display='flex'>
              <Text fontWeight='500' marginEnd='3px'>Cliente: </Text>
              <Text>
                {client.full_name ? `${client.full_name}` : `${client.email}`}
              </Text>
            </Box>
            <Box display='flex'>
              <Text fontWeight='500' marginEnd='3px'>CPF: </Text>
              <Text>
                {client.document_number ? <span>{client.document_number.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</span> : <span>-</span> }
              </Text>
            </Box>
            {client.phone_number && (
              <Box display='flex'>
                <Text fontWeight='500' marginEnd='3px'>Celular: </Text>
                <Text>
                  {client.document_number ? <span>{formatPhoneNumber(client.phone_number)}</span> : <span>-</span> }
                </Text>
              </Box>
            )}
            <Box>
              {order.delivery_method === "delivery" && (<>
                <Box dislpay='flex'>
                  <Text fontWeight='500'>Endereço de entrega:</Text>
                  <Text> {order.delivery_address}</Text>
                </Box>
                <Text>
                  {calculateDeliveryPeriod(batch.id, product, order.delivery_method, order)}
                </Text>
              </>)}
            </Box>
            <Box>
              {order.delivery_method === "delivery-express" && (<>
                <Box dislpay='flex'>
                  <Text fontWeight='500'>Endereço de entrega:</Text>
                  <Text> {order.delivery_address}</Text>
                </Box>
                <Text>
                  {calculateDeliveryPeriod(batch.id, product, order.delivery_method, order)}
                </Text>
              </>)}
            </Box>
            <Box>
              {order.delivery_method === "collect" && (<>
                <Box dislpay='flex'>
                  <Text fontWeight='500'>Endereço de coleta:</Text>
                  <Text>{collectionAddress.address_street}, nº {collectionAddress.address_number}, {collectionAddress.address_complement} - {collectionAddress.address_neighborhood}</Text>
                  <Text>{collectionAddressCity.name} - {collectionAddressState.acronym} - CEP: {collectionAddress.address_postal_code}</Text>
                </Box>
                <Text>
                  {calculateDeliveryPeriod(batch.id, product, order.delivery_method, order)}
                </Text>
              </>)}
              {payment?.observations && (
                <Box dislpay='flex'>
                  <Text fontWeight='500'>Observações:</Text>
                  <Text>{payment.observations}</Text>
                </Box>
              )}
            </Box>

          </ModalBody>
          <ModalFooter>

            {(order.delivery_status !== 'cancelled')&& (
              <Button
                colorScheme="red"
                mr={3}
                onClick={(e) => handleDeliveryStatus(e, "cancelled")}
              >
                Cancelar
              </Button>
            )}
            {((order.delivery_method === 'delivery' || order.delivery_method === 'delivery-express') && order.delivery_status === 'pending')&& (
              <Button
                colorScheme="green"
                mr={3}
                onClick={(e) => handleDeliveryStatus(e, "out_for_delivery")}
              >
                Saiu pra entrega
              </Button>
            )}
            {((order.delivery_method === 'delivery' || order.delivery_method === 'delivery-express') &&  order.delivery_status === 'out_for_delivery') && (
              <Button
                colorScheme="green"
                mr={3}
                onClick={(e) => handleDeliveryStatus(e, "delivered")}
              >
                Marcar como entregue
              </Button>
            )}
            {(order.delivery_method === 'collect' &&  order.delivery_status === 'pending') && (
              <Button
                colorScheme="green"
                mr={3}
                onClick={(e) => handleDeliveryStatus(e, "collected")}
              >
                Marcar como coletado
              </Button>
            )}
            {order.delivery_status === 'collected' && (
              <Text color="green">
                COLETADO
              </Text>
            )}
            {order.delivery_status === 'delivered' && (
              <Text color="green">
                ENTREGUE
              </Text>
            )}
            {order.delivery_status === 'cancelled' && (
              <Text color="red">
                PEDIDO CANCELADO
              </Text>
            )}
            <Button
              colorScheme="red"
              mr={3}
              onClick={onClose}
              marginLeft='5px'
            >
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
