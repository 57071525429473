import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { useState, useReducer } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import { api } from "../../../services/api";
import swal  from 'sweetalert';

export function EditCategory({ categoryId, allSubcategories, onUpdateCategory }) {
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [category, setCategory] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      subcategoryId: "",
    }
  );
  const [subcategories, setSubcategories] = useState(allSubcategories)

  const handleSubcategoryChange = (e) => {
    const { name, value } = e.target;
    setCategory({ [name]: value });
  }

  async function handleEditSubcategory(e) {
    e.preventDefault();
    if (category.subcategoryId === "") {
      swal({
        title: "Opa",
        text: "escolha uma subcategoria!",
        icon: "info",
      });
    }
    const formData = new FormData()
    formData.append("category[name]", category.name);
    formData.append("category[subcategory_id]", category.subcategoryId);
    const response = await api.put(`/api/v1/categories/${categoryId}`, formData, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
        contentType: "multipart/form-data"
      },
    })
    if (response.status === 200) {
      onUpdateCategory(categoryId, response.data.name);
      swal({
        title: "Ohhh Yeahhh",
        text: "Dados atualizados com sucesso!",
        icon: "success",
      });
      onClose()
    } else {
      swal({
        title: "Ohhh Noo",
        text: "Algo deu errado!",
        icon: "error",
      });
    }
    onClose()
  }

  return (
    <>
      <Button onClick={onOpen}>Editar</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{category.name} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Box
                margin="20px 0"
              >
                <Text>Nome</Text>
                <Input
                  placeholder={`${category?.name}`}
                  onChange={handleSubcategoryChange}
                  name="name"
                />
              </Box>

              <Box
                margin="20px 0"
              >
                <Text>subcategoria</Text>
                <Select
                  placeholder='Selecione a subcategoria'
                  onChange={handleSubcategoryChange}
                  name="subcategoryId"
                >
                  {subcategories.map(subcategory => (
                    <option
                      key={subcategory.id}
                      value={subcategory.id}
                    >
                      {subcategory.name}
                    </option>
                  ))}
                </Select>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={onClose}
            >
              Fechar
            </Button>
            <Button
              colorScheme="green"
              onClick={(e) => handleEditSubcategory(e)}
            >
              Editar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
