import {
  Box,
  Input,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure, Tabs, TabList, TabPanels, Tab, TabPanel, Grid, GridItem
} from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import React, { useContext, useEffect, useState } from "react";
import { api } from "../../services/api";
import { FaSearch, FaFilter } from 'react-icons/fa';
import { CityContext } from '../../Context/CityContext';
import { FaRegTimesCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export function OtherDrinksFilters(props) {
  const location = useLocation();
  const [readyDrinkTypes, setReadyDrinkTypes] = useState([]);
  const { cityParam } = useContext(CityContext);

  const readyDrinkTypeOptions = ["Bebida Mista", "Lata Ready-to-drink"]

  async function handleGetProductTypes(e, productType) {
    e.preventDefault();
    const updatedReadyDrinkTypes = readyDrinkTypes.includes(productType)
      ? readyDrinkTypes.filter((type) => type !== productType)
      : [...readyDrinkTypes, productType];

    setReadyDrinkTypes(updatedReadyDrinkTypes);
    filterProducts(updatedReadyDrinkTypes);
  }

  async function filterProducts(types) {
    let url = `/api/v1/products?city_id=${cityParam}`;

    if (types.length > 0) {
      url = `${url}&other_drink_types=${types.join(',')}`;
    }
    if (types.length === 0 ) {
      const urlParams = new URLSearchParams(location.search);
      const filter = urlParams.get("filter");
      url = `${url}&filter=${filter}`;
    }
    const response = await api.get(url);
    props.setProducts(response.data.products);
  }


  return (
    <>
    <Accordion allowToggle defaultIndex={window.screen.width < 762 ? [] : [0]}>
      <AccordionItem borderColor={theme.colors.primary}>
        <AccordionButton
          fontWeight='bold'
          display={{base: 'block', md: 'none'}}
        >
          FILTRAR
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={2}>
          <Grid
            templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(2, 1fr)" }}
            gap={4}
            px={7}
          >
            {readyDrinkTypeOptions.map((readyDrinkType, index) => {
              return(
                <GridItem key={index}>
                  <Button
                    width={"100%"}
                    onClick={(e) => handleGetProductTypes(e, readyDrinkType)}
                    border='1px solid #D44120'
                    borderRadius='5px'
                    _hover={{
                      transform: "scale(1.02)",
                      color: 'white',
                      bg: '#D44120'
                    }}
                    style={{
                      backgroundColor: readyDrinkTypes.includes(readyDrinkType)
                        ? '#D44120'
                        : 'transparent',
                        color: readyDrinkTypes.includes(readyDrinkType)
                        ? 'white'
                        : '#D44120',
                    }}
                  >
                    {readyDrinkType}
                  </Button>
                </GridItem>
              )
            })}
          </Grid>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
    </>
  );
}
