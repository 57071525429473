import {
  Button,
  Image,
  Text,
  Box,
  Stat,
  StatHelpText,
  StatArrow,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { theme } from "../../styles/theme";
import { FaShareAlt } from 'react-icons/fa';
import swal  from 'sweetalert';

export function ProductCard({
  id,
  name,
  brand,
  marketPrice,
  prices,
  batch,
  productPhotoUrl,
  suggestedPriceForSale,
  quantityPerBox,
  size,
  productTypeId,
  productTypes
}) {

  const { id: productId } = useParams()
  const { min, max } = (prices || []).reduce((acc, cur) => {
    if (cur.price_per_unity < acc.min) {
      acc.min = cur.price_per_unity;
    }
    if (cur.price_per_unity > acc.max) {
      acc.max = cur.price_per_unity;
    }
    return acc;
  }, { min: Infinity, max: -Infinity }) || {};

  const shareProductLink = (e) => {
    e.preventDefault()
    navigator.clipboard.writeText(e.target.dataset.copyText).then((error) => {
      swal({
        title: "👍",
        text: "Link copiado!",
        icon: "success",
      });
    })
  }

  const minUnity = prices.reduce((min, current) => (current.unity < min ? current.unity : min), prices[0]?.unity);
  return (<>
    <Box
      direction={{ base: 'column', sm: 'column' }}
      background={theme.colors.white500}
      borderRadius={"15px"}
      border={"none"}
      height={{base: "100%", md: "96%"}}
      width={{ base: '100%', md: '100%' }}
      marginBottom={{ base: '20px', md: '0' }}
      display='flex'
      justifyContent='start'
      flexDirection='column'
      paddingBottom='60px'
      position='relative'
      transition="transform 0.2s"
      boxShadow={"rgba(0, 0, 0, 0.25) 0px 5px 15px;"}
      _hover={{
        transform: "scale(1.05)"
      }}
    >
      <Image
        objectFit='cover'
        width={{ base: 'auto', md: '' }}
        src={productPhotoUrl || require('../../assets/images/home-cover.webp')}
        alt='Boozen'
        borderRadius='15px'
        border={"none"}
        height='11rem'
        loading="lazy"
        boxShadow={"rgba(0, 0, 0, 0.25) 0px 5px 15px;"}
      />
      <Box
        padding="0px 15px 5px 15px"
      >
        <Button
          background={theme.colors.whiteSmoke}
          id="copy-paste"
          padding='0'
          margin='0'
          border='1px solid'
          borderRadius='50%'
          fontSize='12px'
          data-copy-text={`Confira essa oferta na Boozen - ${name} - ${brand}: http://boozen.com.br/produtos/${productTypes?.find(e => e.id === productTypeId)?.name.replace(/(%20|\s+)/g, "+")}/${name.replace(/(%20|\s+)/g, "+")}`}
          onClick={(e) => shareProductLink(e)}
          _hover={{ bg: `${theme.colors.primary}`, color: `${theme.colors.whiteSmoke}`, borderColor: `${theme.colors.primary}` }}
          position='absolute'
          right='1.2rem'
          top={{base: '10.3rem', md: '10rem'}}
          color={theme.colors.primary}
          width={{base: '1.2rem', md: '1.8rem'}}
          height={{base: '1.2rem', md: '1.8rem'}}
          minWidth='none'
        >
          <FaShareAlt></FaShareAlt>
        </Button>
        <Text fontWeight='bold' fontSize="13px" marginTop='10px'>{name}</Text>
        <Text fontWeight='350' fontSize="13px">{brand}</Text>
        <Box position='absolute' bottom='20px'>
          <Box
            display='fex'
            gap='5px'
            alignItems='center'
          >
            <Text
              fontSize={{base: '12px', md: '14px'}}
              fontWeight='350'
            >
              Preço no mercado:
            </Text>
            <Text fontWeight='350' fontSize={{base: '12px', md: '14px'}} textDecoration='line-through'>{marketPrice?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Text>
          </Box>
          <Box display={{base: '', md: 'flex'}} justifyContent='space-between' alignItems='center'>
            <Stat marginBottom='0px'>
              <StatHelpText  marginBottom='0px' display="flex" alignItems='center'>
                <StatArrow color="green" type='decrease' />
                <span>{batch && (batch.discount_per_centage)}%</span> <Text display={{base: 'none', md: 'block'}} marginLeft='4px'>desconto na</Text><Text marginLeft="5px" color={theme.colors.primary} fontWeight='bold' translate="no">BOO</Text> <Text translate="no" color={theme.colors.primaryGreen} fontWeight='bold'>ZEN</Text>
              </StatHelpText>
            </Stat>
          </Box>
          <Text color={theme.colors.secondaryGreen} fontWeight='bold' fontSize={{base: '13px', md: ''}}>
            De {(min).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} a {max.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
          </Text>
          <Text
            fontWeight='350'
            fontSize={{base: '11px', md: '13px'}}
          >
            Mínimo: {quantityPerBox * minUnity} {size ? <span>{size}</span> : <span></span>}{quantityPerBox > 1 && size ? <span>s</span> : <span></span>}
          </Text>
          {suggestedPriceForSale && (
            <Box display="flex" gap="5px">
              <Text textAlign={{base: 'start', md: 'start'}} fontWeight='350' fontSize={{base: '11px', md: '13px'}}>Sugestão de preço de revenda: R${suggestedPriceForSale.toFixed(2)}</Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  </>)
}
