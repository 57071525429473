import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export function OrdersByType({typesSold}) {

  const labels = typesSold.map(item => item.product_type_name);
  const dataValues = typesSold.map(item => item.sold_count);

  const data = {
    labels,
    datasets: [
      {
        label: '#quantidade vendida',
        data: dataValues,
        backgroundColor: 'rgba(13,110,253, 0.2)',
        borderColor: 'rgba(13,110,253, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Tipos de Bebida',
      },
    },
  };


  return (<>
    <div  style={{ width: '100%', height: '27rem', background: '#f2f2f2', borderRadius: '15px', padding: '20px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 15px', }}>
      <Radar data={data} options={options} style={{ margin: 'auto'}}/>
    </div>
  </>)
}
