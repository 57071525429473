import {
  Box,
  Button,
  Input,
  Select,
  Text
} from "@chakra-ui/react";
import { useState } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import { api } from "../../../services/api";
import { EditCategory } from "../EditCategory";
import { theme } from "../../../styles/theme";
import { FaPlus } from "react-icons/fa";
import swal  from 'sweetalert';

export function CategoryCard({allCategories, allSubcategories}) {
  const { user } = useAuth();
  const [categoryName, setCategoryName] = useState("")
  const [subcategoryId, setSubcategoryId] = useState("")
  const [categories, setCategories] = useState(allCategories)
  const [subcategories, setSubcategories] = useState(allSubcategories)
  const [toggleCategoryCreation, setToggleCategoryCreation] = useState(false);

  async function fetchCreateCategory(e) {
    e.preventDefault();
    if (categoryName === "" || subcategoryId === "") {
      swal({
        title: "Ops",
        text: "Nome e subcategoria não podem ficar em branco.",
        icon: "info",
      });
      return
    }
    const data = {
      name: categoryName,
      user_id: user.id,
      subcategory_id: subcategoryId
    }
    const response = await api.post("api/v1/categories", data, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
      }
    })
    if (response.status === 200)  {
      categories.push(response.data)
      setCategoryName("")
      setSubcategoryId("")

      swal({
        title: "Ok",
        text: "Categoria criada com sucesso!.",
        icon: "success",
      });
    } else {
      swal({
        title: "Opa",
        text: "Algo deu errado.",
        icon: "error",
      });
    }
  }

  const updateCategoryName = (categoryId, newName) => {
    const updatedCategories = categories.map((category) =>
      category.id === categoryId
        ? { ...category, name: newName }
        : category
    );
    setCategories(updatedCategories);
  };

  const handleToggleShowCreationOfCategory = () => {
    setToggleCategoryCreation(!toggleCategoryCreation);
  }

  return (
    <>
      <Button
        type="button"
        onClick={handleToggleShowCreationOfCategory}
        background="transprent"
        _hover={{ bg: "transparent", color: `${theme.colors.primary}` }}
      >
        <FaPlus></FaPlus> Cadastrar nova categoria
      </Button>
      {toggleCategoryCreation && (<>
        <Box
          margin="20px 0"
        >
          <Input
            placeholder="Nome da categoria"
            onChange={(e) => setCategoryName(e.target.value)}
            value={categoryName}
          />
        </Box>
        <Box>
          <Text>
            Selecione uma subcategoria
          </Text>
          <Select
            onChange={(e) => setSubcategoryId(e.target.value)}
            value={subcategoryId}
          >
            <option value="">Selecione</option>
            {subcategories?.map((subCategory, index) => {
              return (
                <option
                  key={index}
                  value={subCategory.id}
                >
                  {subCategory.name}
                </option>
              )
            })}
          </Select>
          <Box
            margin="20px 0"
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              onClick={(e) => fetchCreateCategory(e)}
            >
              Criar categoria
            </Button>
          </Box>
        </Box>
      </>)}
      <Text textAlign="center" marginBottom="20px" fontSize="28px" fontWeight="bolder">Categorias</Text>

      {categories?.map((category, index) => {
        return (
          <>
            <Box
              key={category.id}
              display={"flex"}
              justifyContent="space-between"
              alignItems="center"
              padding="10px 0"
            >
              <Text key={index}>
                {category.name}
              </Text>
              <EditCategory
                categoryId={category.id}
                allSubcategories={subcategories}
                onUpdateCategory={updateCategoryName}
              />
            </Box>
          </>
        )
      })}
    </>
  )
}
