import { useState } from "react";
import { createContext } from "react";
import { useAuth } from "../Hooks/useAuth";
import { api } from "../services/api";
import swal  from 'sweetalert';
import Swal from 'sweetalert2'

export const CartContext = createContext({});

export function CartContextProvider(props) {
  const { user } = useAuth();
  const [showCartPopUp, setShowCartPopUp] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState([]);


  function handleSetSelectedProduct(e, product, batch, finalPrice, quantity) {
    e.preventDefault();
    handleAddToCart(product, batch, finalPrice, quantity);
    // if (user == null) {
    //   window.location.href = "/sign_in";
    //   alert("Você precisa se cadastrar ou entrar antes!!!")
    // } else {
    //   handleCreateCart(e, product, batch, finalPrice, quantity);
    // }
  }

  function handleAddToCart(product, batch, finalPrice, quantity) {
    const cartData = localStorage.getItem('guestCart');
    let guestCart = [];
    try {
      if (cartData) {
        guestCart = JSON.parse(cartData) || [];
      }
    } catch (error) {
      console.error("Error parsing guestCart from localStorage:", error);
      guestCart = [];
    }
    // Add the new item to the cart
    guestCart.push({
      product_id: product.id,
      batch_id: batch.id,
      amount: Number(finalPrice),
      quantity: Number(quantity),
    });

    // Store the updated cart back in localStorage
    localStorage.setItem('guestCart', JSON.stringify(guestCart));

    // Show the cart popup
    setShowCartPopUp(true);

    // Update the cart items counter
    const itemsCounter = guestCart.length;
    localStorage.setItem('cartItems', itemsCounter.toString());

    // // If the user is not logged in, store the cart in localStorage
    // guestCart.push({
    //   product_id: product.id,
    //   batch_id: batch.id,
    //   amount: Number(finalPrice),
    //   quantity: Number(quantity),
    // });

    // localStorage.setItem('guestCart', JSON.stringify([]));

    // const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
    // setShowCartPopUp(true);
    // const itemsCounter = guestCart.length;
    // const updatedCounter = parseInt(itemsCounter, 10) + 1;
    // localStorage.setItem('cartItems', updatedCounter.toString());
    // if (user == null) {
    // } else {
    //   // If the user is logged in, proceed with creating the cart in the backend
    //   handleCreateCart(user, product, batch, finalPrice, quantity);
    // }
  }

  async function handleCreateCart(user, product, batch, finalPrice, quantity) {
    // e.preventDefault()
    const itemsCounter = localStorage.getItem('cartItems');
    const updatedCounter = parseInt(itemsCounter, 10) + 1;
    localStorage.setItem('cartItems', updatedCounter.toString());
    const cart = {
      user_id: user.id,
      batch_id: batch.id,
      amount: (finalPrice),
      quantity: quantity
    }
    const response = await api.post("/api/v1/cart_batches", cart);
    if (response.status === 200) {
      setShowCartPopUp(true);
      if (window && window.gtag) {
        window.gtag('event', 'add_to_cart', {
          currency: "BRL",
          value: finalPrice,
          'items': [{
            'item_name': product.name,
            'item_id': product.id,
            'price': finalPrice,
            'item_brand': product.brand,
            'quantity': quantity
          }]
        });
      }

      if (typeof window !== "undefined") {
        if (window.fbq != null) {
          window.fbq('trackCustom', 'handleCreateCart');
        }
      }
    }
  }

  // async function handleCreateCart(userId, productId, batchId, finalPrice, quantity) {
  //   const productResponse = await api.get(`/api/v1/products/${productId}`);

  //   if (productResponse.status !== 200) {
  //     console.error("Failed to fetch product or batch details");
  //     return;
  //   }

  //   const product = productResponse.data;

  //   const itemsCounter = localStorage.getItem('cartItems') || '0';
  //   const updatedCounter = parseInt(itemsCounter, 10) + 1;
  //   localStorage.setItem('cartItems', updatedCounter.toString());

  //   const cart = {
  //       user_id: userId,
  //       batch_id: batchId,
  //       amount: finalPrice,
  //       quantity: quantity
  //   };

  //   const response = await api.post("/api/v1/cart_batches", cart);
  //   if (response.status === 200) {
  //       setShowCartPopUp(true);
  //       if (window && window.gtag) {
  //           window.gtag('event', 'add_to_cart', {
  //             currency: "BRL",
  //             value: finalPrice,
  //             'items': [{
  //               'item_name': product.name,
  //               'item_id': productId,
  //               'price': finalPrice,
  //               'item_brand': product.brand,
  //               'quantity': quantity
  //             }]
  //           });
  //       }

  //       if (typeof window !== "undefined" && window.fbq != null) {
  //           window.fbq('trackCustom', 'handleCreateCart');
  //       }
  //   }
  // }

  return (
    <CartContext.Provider
      value={{ showCartPopUp, setShowCartPopUp, selectedProduct, handleSetSelectedProduct}}
    >
      {props.children}
    </CartContext.Provider>
  );
}
