import { Box, Button, HStack} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { api } from "../../services/api";
import { ProductCard } from "../../components/ProductCard";
import {  Link } from 'react-router-dom';

export function CarouselStore() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [products, setProducts] = useState([]);
  const [batches, setBatches] = useState([]);
  const [prices, setPrices] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productsPhotos, setProductsPhotos] = useState({});
  const [productsFlyer, setProductsFlyer] = useState({});
  const [touchStartX, setTouchStartX] = useState(null);
  const [isTouching, setIsTouching] = useState(false);
  const carouselRef = useRef(null);
  const currentDate = new Date();
  const calculateDaysDifference = (batch) => {
    const expirationDate = new Date(batch?.expiration_date);
    const timeDifference = expirationDate.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return daysDifference
  }

  async function fetchProducts() {
    const response = await api.get(`/`);
    setProducts(response.data.products);
    setBatches(response.data.batches);
    setPrices(response.data.prices);
    setProductsPhotos(response.data.product_photos_url);
    setProductsFlyer(response.data.product_flyers_url);
    setProductTypes(response.data.product_types);
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleTouchStart = (e) => {
    setIsTouching(true);
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!isTouching) return;
    const touchEndX = e.touches[0].clientX;
    const deltaX = touchEndX - touchStartX;
    const width = carouselRef.current.offsetWidth;
    if (deltaX > 50) {
      handlePrev();
    } else if (deltaX < -50) {
      handleNext();
    }
    setIsTouching(false);
  };

  const itemsPerPageDesktop = 6;
  const itemsPerPageMobile = 1;
  const minUnity = prices.reduce((min, current) => (current.unity < min ? current.unity : min), prices[0]?.unity);

  const handlePrev = () => {
    const newIndex = (currentIndex - (window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPageDesktop) + products.length) % products.length;
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    const newIndex = (currentIndex + (window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPageDesktop)) % products.length;
    setCurrentIndex(newIndex);
  };

  return (
    <Box w={{ base: '100%', md: '100%' }} h={{base: "auto", md: "auto" }} position="relative" overflow="hidden" margin={"0% 0% 2% 0%"} borderRadius={"40px"}>
      <HStack position="absolute" top="45%" left="0px" transform="translateY(-50%)" w="100%" justify="space-between" px=".5rem" zIndex="10"
        ref={carouselRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <Button onClick={handlePrev} background={"transparent"} _hover={{ bg: "transparent", color: "green" }}>
          <FaAngleLeft />
        </Button>
        <Button onClick={handleNext} background={"transparent"} _hover={{ bg: "transparent", color: "green" }}>
          <FaAngleRight />
        </Button>
      </HStack>
      <Box position="absolute" bottom="0rem" left="50%" transform="translateX(-50%)" zIndex="10">
        <HStack spacing="1rem">
          {Array.from({ length: Math.ceil(products.length / (window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPageDesktop)) }, (_, frameIndex) => (
            <Box
              key={frameIndex}
              w="10px"
              h="10px"
              borderRadius="50%"
              bgColor={frameIndex === Math.floor(currentIndex / (window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPageDesktop)) ? "#216a0f" : "gray.300"}
              cursor="pointer"
              onClick={() => setCurrentIndex(frameIndex * (window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPageDesktop))}
            />
          ))}
        </HStack>
      </Box>
      <Box display='flex' justifyContent='center' overflow='hidden' flexWrap='wrap' px={10} margin="auto 0%" gap={3} h={{base: "auto", md: "auto" }}>
        {products.slice(currentIndex, currentIndex + (window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPageDesktop)).map((product, index) => (
          <Box key={index} width={{base: "calc(100% - 10px)", md: "16%" }} margin="0px" height="25rem">
            <Link to={`/produtos/${productTypes?.find(e => e.id === product.product_type_id).name.replace(/(%20|\s+)/g, "+")}/${product.name.replace(/(%20|\s+)/g, "+")}`}>
              <ProductCard
                key={product.id}
                id={product.id}
                name={product.name}
                prices={prices.filter(element => element.batch_id === batches.find(element => element.product_id === product.id).id)}
                brand={product.brand}
                size={product.size}
                description={product.description}
                quantityPerBox={product.quantity_per_box}
                marketPrice={product.market_price}
                productType={product.product_type}
                batch={batches.find(element => element.product_id === product.id)}
                productPhotoUrl={productsPhotos[product.id]}
              />
            </Link>
          </Box>
        ))}
      </Box>
    </Box>

  );
}
