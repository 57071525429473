
import {
  Box,
  Input,
  Button,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import React, { useContext, useEffect, useState } from "react";
import { api } from "../../services/api";
import { FaSearch, FaFilter, FaTimes } from 'react-icons/fa';
import { CityContext } from '../../Context/CityContext';
import { Link } from 'react-router-dom';


export function Search(props) {
  const [filter, setFilter] = useState("");
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [productTypes, setProductTypes] = useState([]);


  const { cityParam } = useContext(CityContext);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('trackCustom', 'Search');
      }
    }
  }, []);

  async function handleProductSearch(e)  {
    setSearching(true)
    handlefetchProductTypes()
    const url = `/search?query=${e.target.value}`
    const response = await api.get(url)
    setSearchResults(response.data.products)
  }

  async function handlefetchProductTypes()  {
    const url = `/api/v1/product_types`
    const response = await api.get(url)
    setProductTypes(response.data)
  }

  const handleClearSearch = (e) => {
    e.preventDefault();
    document.getElementById('search-input').value = '';
    setSearchResults([]);
    setSearching(false);
    const linkPath = e.currentTarget.getAttribute('href');
    if (linkPath) {
      window.location.href = linkPath;
    }
  }

  return (<>
    <Box pt='20px' position='relative' display='flex' px={{base: "0px", md: "2rem"}} justifyContent={{base: "center", md: "end"}}>
      <Input
        placeholder="Pesquisar"
        background='transparent'
        size="md"
        id='search-input'
        borderRadius={"5px"}
        width={{base: "95%", md: "30%"}}
        marginTop={{base: "10%", md: "0%"}}
        height={"31px"}
        onChange={(e) => handleProductSearch(e)}
        color={theme.colors.primary}
        border='1px solid #D44120'
        _hover={{
          borderColor: theme.colors.primary,
        }}
        _focus={{
          outline: 'none',
          boxShadow: 'none',
        }}
      >
      </Input>
      {searching && (
        <Button
          position='absolute'
          bottom='-4px'
          right='30px'
          bg='transparent'
          onClick={(e) => handleClearSearch(e)}
          zIndex='1'
          fontWeight='light'
          color='gray'
          _hover={{
            color: theme.colors.primary,
            bg: 'transparent'
          }}
        >
          <FaTimes></FaTimes>
        </Button>
      )}
    </Box>
    <Box display='flex' position='relative' opacity='0.95' marginStart={{base: '0%', md: '67.5%'}} zIndex='1000' pb='1rem' bg={theme.colors.white500} justifyContent={{base: "center", md: "end"}} height='auto' px={{base: ".5rem", md: "2rem"}} width={{base: "100%", md: "32.5%"}} borderRadius='0px 0px 15px 15px'>
      <List width={{base: "100%", md: "100%"}} marginTop='2%'>
        {searchResults?.length > 0 && (
          searchResults.map((product, index) => (
            <Link
              opacity='1'
              to={`/produtos/${productTypes?.find(e => e.id === product.product_type_id)?.name.replace(/(%20|\s+)/g, "+")}/${product.name.replace(/(%20|\s+)/g, "+")}`}
              key={index}
              href={`/produtos/${productTypes?.find(e => e.id === product.product_type_id)?.name.replace(/(%20|\s+)/g, "+")}/${product.name.replace(/(%20|\s+)/g, "+")}`}
              onClick={handleClearSearch}
              _hover={{
                color: theme.colors.primary,
              }}
            >
              <ListItem opacity='1' border='1px solid #D44120'  borderRadius='5px' padding='1%' my='5px' color='#D44120'
                _hover={{
                  bg: theme.colors.primary,
                  color: 'white'
                }}
              >{product.name}</ListItem>
            </Link>
          ))
        )}
        {searchResults?.length === 0 && searching &&(
          <ListItem opacity='1' border='1px solid #D44120'  borderRadius='5px' padding='1%' my='5px' color='#D44120'
            _hover={{
              bg: theme.colors.primary,
              color: 'white'
            }}
          >Nenhum item encontrado</ListItem>
        )}
      </List>
    </Box>
  </>)
}
