import { Box, Container, Divider, Text, Link, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { api } from "../../../services/api";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function DeliveryStatusChart({orders}) {


  const optionsOrders = {
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Status de Entrega',
      },
    },
  };

  const labels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  const paidOrders = orders.filter(order => order.paid);
  // const notPaidOrders = allOrders.filter(order => !order.paid);

  const deliveryStatuses = ['pending', 'out_for_delivery', 'delivered'];
  const countsByStatusAndMonth = {};

  deliveryStatuses.forEach(status => {
    countsByStatusAndMonth[status] = labels.map(month => 0);
  });

  paidOrders.forEach(order => {
    const updatedAtDate = new Date(order.updated_at);
    const monthName = updatedAtDate.toLocaleString('pt-BR', { month: 'long' });
    const monthCapitalize = monthName.charAt(0).toUpperCase() + monthName.slice(1);
    const monthIndex = labels.findIndex(month => month === monthCapitalize);
    if (monthIndex !== -1 && deliveryStatuses.includes(order.delivery_status)) {
      countsByStatusAndMonth[order.delivery_status][monthIndex]++;
    }
  });


  const translateDeliveryStatus = (status) => {
    const languageMap = {
      "pending": "Pendente",
      "out_for_delivery": "Em Entrega",
      "delivered" : "Entregue",

    };
    return languageMap[status]
  }

  const datasets = deliveryStatuses.map((status, index) => ({
    label: `Pedidos ${translateDeliveryStatus(status)}`,
    data: countsByStatusAndMonth[status],
    borderColor: `rgb(${index * 80}, 100, 100)`,
    backgroundColor: `rgb(${index * 80}, 100, 100)`,
  }));

  const dataOrders = {
    labels,
    datasets,
  };


  return (<>
    <div  style={{ width: '100%', height: '27rem', background: '#f2f2f2', borderRadius: '15px', padding: '20px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 15px', }}>
      <Bar options={optionsOrders} data={dataOrders} style={{ margin: 'auto'}}/>
    </div>
  </>)
}
